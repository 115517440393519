import "./UpdateActivityInSlotPopup.scss";
import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import {
  IActivity,
  IActivityInput,
  ICoach,
  ICourt,
  IPermission,
  IUpdateActivityInSlotInput,
} from "../../../types/api";
import { toast } from "react-toastify";
import { TActivityColor } from "../../../types/internal";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import {
  cancelActivity,
  updateActivityInSlot,
} from "../../../api/activities/activities";
import { formatToIsoDate } from "../../../shared/dateUtils";
import { capitalizeWords, formatDateToSwedish } from "../../../shared/utility";

interface IUpdateActivityInSlotPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: (activity: IActivity) => void;
  activity?: IActivity | null;
  court: ICourt;
  permissions: IPermission[];
  coachesList: ICoach[];
  datetime?: string;
  timeAndCourtString: string;
  filterDate: Date;
}
interface SelectionMap {
  [key: string]: boolean;
}

function UpdateActivityInSlotPopup(props: IUpdateActivityInSlotPopupProps) {
  const [cancelActivityLoading, setCancelActivityLoading] = useState(false);
  const [
    showHandleConfirmCancelActivityPopup,
    setShowHandleConfirmCancelActivityPopup,
  ] = useState(false);
  const { weekday, dateAndMonth } = formatDateToSwedish(props.filterDate);
  const { court, permissions } = props;
  const [selectedCourts, setSelectedCourts] = useState<{
    [key: string]: boolean;
  }>({});
  const selectedCourtsLength = Object.values(selectedCourts).filter(
    (value) => value
  ).length;
  const [selectedCoaches, setSelectedCoaches] = useState<{
    [key: string]: boolean;
  }>({});

  const [selectedPermissions, setSelectedPermissions] = useState<{
    [key: string]: boolean;
  }>({});
  const selectedPermissionsLength = Object.values(selectedPermissions).filter(
    (value) => value
  ).length;

  const rankingList = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const amountOfPeoplesList = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
  ];
  const [ranking_min, setRanking_min] = useState(0);
  const [ranking_max, setRanking_max] = useState(0);

  const [selectedRankings, setSelectedRankings] = useState<{
    [key: number]: boolean;
  }>({});
  const [isVisisble, setIsVisible] = useState(true);
  const [rankingOption, setRankingOption] = useState("all");
  const [coachOption, setCoachOption] = useState("Nej");
  const [permissionOption, setPermissionOption] = useState("all");
  const [activityName, setActivityName] = useState("");
  const [participants, setParticipants] = useState(0);
  const [createdAtString, setCreatedAtString] = useState("");
  const [price, setPrice] = useState(0);
  const [hidden, setHidden] = useState(false);

  const [comment, setComment] = useState("");
  const [historyComments, setHistoryComments] = useState<string[]>([]);
  const [activityColor, setActivityColor] =
    useState<TActivityColor>("activity-color-1");

  useEffect(() => {
    if (permissionOption === "none") {
      setSelectedPermissions({});
      setParticipants(0);
    }
  }, [permissionOption, participants]);

  useEffect(() => {
    if (props.activity) {
      setActivityName(props.activity.name);
      setParticipants(props.activity.participants_limit);
      const commentHistory = props.activity.comment.split("\n").slice(1);
      setHistoryComments(commentHistory);
      const comment = props.activity.comment.split("\n")[0];
      setComment(comment);
      setPrice(props.activity.price);
      setHidden(props.activity.hidden);
      setActivityColor(props.activity.color as TActivityColor);
      setSelectedCourts(
        props.activity.court_id.reduce((acc, courtId) => {
          acc[courtId] = true;
          return acc;
        }, {} as { [key: string]: boolean })
      );
      setSelectedPermissions(
        props.activity.members_with_access.reduce((acc, permissionId) => {
          acc[permissionId] = true;
          return acc;
        }, {} as { [key: string]: boolean })
      );
      setRanking_min(props.activity.ranking_min);
      setRanking_max(props.activity.ranking_max);
      if (
        props.activity.created_at !== undefined &&
        props.activity.created_at !== null
      ) {
        const created_at = props.activity.created_at;
        setCreatedAtString(
          created_at.slice(0, 10) + " " + created_at.slice(11, 19)
        );
      } else {
        setCreatedAtString("-");
      }
    }
  }, [props.activity]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "minRanking":
        setRanking_min(value);
        break;
      case "maxRanking":
        setRanking_max(value);
        break;
      case "visibility":
        setIsVisible(!isVisisble);
        break;

      case "activityPrice":
        setPrice(value);
        break;
      case "participants":
        setParticipants(value);
        break;
      case "comment":
        setComment(value);
        break;
      case "activityPrice":
        setPrice(value);
        break;
      default:
        break;
    }
  };

  const handleUpdateActivityInSlot = async () => {
    if (!props.activity || !props.datetime) return;
    let permissionsIdList: any[] = [];
    if (permissionOption === "all") {
      permissionsIdList = permissions.map(
        (permission) => permission.permission_id
      );
    } else if (permissionOption === "none") {
      permissionsIdList = [];
    } else {
      permissionsIdList = Object.entries(selectedPermissions)
        .filter(([_, isSelected]) => isSelected)
        .map(([permissionId, _]) => permissionId);
    }
    let coachesIdList = [""];
    if (coachOption === "Ja") {
      coachesIdList = Object.entries(selectedCoaches)
        .filter(([_, isSelected]) => isSelected)
        .map(([coachId, _]) => coachId);
    }
    const rankingMin =
      rankingOption === "all" ? -1 : ranking_min === -1 ? 1 : ranking_min;
    const rankingMax = rankingOption === "all" ? 15 : ranking_max;

    const datetimeString =
      formatToIsoDate(props.filterDate).split("T")[0] + "T" + props.datetime;
    const input_data: IUpdateActivityInSlotInput = {
      participants_limit: participants,
      price: price,
      hidden: !hidden,
      ranking_min: rankingMin,
      ranking_max: rankingMax,
      comment: comment,
      coach_id: coachesIdList,
      members_with_access: permissionsIdList,
    };
    try {
      const response = await updateActivityInSlot(
        props.court.court_id,
        datetimeString,
        input_data
      );

      if (response && !("message" in response)) {
        toast.success("Aktivitet uppdaterad");

        props.onConfirm(response);
        // props.onClose();
      }
    } catch (error: any) {
      toast.error(error.errors[0].message);
    }
  };

  const toggleCoachSelection = (coachId: string) => {
    setSelectedCoaches((prev) => ({
      ...prev,
      [coachId]: !prev[coachId],
    }));
  };

  const togglePermissionSelection = (permissionId: string) => {
    setSelectedPermissions((prev) => ({
      ...prev,
      [permissionId]: !prev[permissionId],
    }));
  };

  const handlePropTemplate = () => {
    if (!props.activity) {
      return null;
    }
    setActivityName(props.activity.name || "");
    setParticipants(props.activity.participants_limit || 0);
    setComment(props.activity.comment || "");
    setIsVisible(!props.activity.hidden);
    if (props.activity.members_with_access.length) {
      if (
        props.activity.members_with_access.length === props.permissions.length
      ) {
        setPermissionOption("all");
        return;
      }

      const permissionIds = props.activity.members_with_access;
      const selectedPermissions: SelectionMap = {};
      permissionIds.forEach((permissionId) => {
        selectedPermissions[permissionId] = true;
      });
      setPermissionOption("specific");
      setSelectedPermissions(selectedPermissions);
    } else {
      setPermissionOption("none");
    }
    if (props.activity.coach_id) {
      const coachIds = props.activity.coach_id;
      const selectedCoaches: SelectionMap = {};
      coachIds.forEach((coachId) => {
        selectedCoaches[coachId] = true;
      });
      setCoachOption("ja");
      setSelectedCoaches(selectedCoaches);
    }
    if (
      props.activity.ranking_min !== -1 ||
      props.activity.ranking_max !== 15
    ) {
      setRankingOption("specific");
      setRanking_min(props.activity.ranking_min);
      setRanking_max(props.activity.ranking_max);
    }
  };

  useEffect(() => {
    if (props.activity) {
      handlePropTemplate();
    }
  }, [props.activity, props.showPopup]);
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={<span className="text-m">Uppdatera aktivitets slot</span>}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleUpdateActivityInSlot();
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <span className="text-m">Namn på aktivitet</span>
                <span className={`text-s activity-name `}>
                  {activityName.split("%-%")[0]} ({hidden ? "Dold" : "Synlig"})
                  <div className={`activity-name-blob ${activityColor}`}></div>
                </span>
              </div>

              <div className="general-popup-flex-between">
                <span className="text-m">Datum</span>
                <span className="text-s">
                  {weekday}, {dateAndMonth}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Tid</span>
                <span className="text-s">{props.timeAndCourtString}</span>
              </div>

              <div className="general-popup-flex-between">
                <span className="text-m">Medlemstyper</span>
                <span className="text-s">
                  {permissionOption === "all"
                    ? "Alla medlemstyper"
                    : selectedPermissionsLength === 0
                    ? "Inga medlemstyper"
                    : permissions
                        .filter((permission) => {
                          return selectedPermissions[permission.permission_id];
                        })
                        .map((permission, index) => {
                          if (index === 0) {
                            return permission.member_type;
                          }
                          return ", " + permission.member_type;
                        })}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Ranking</span>
                <span className="text-s">
                  {rankingOption === "all" && ranking_min !== 1
                    ? "Inga ranking specifikationer"
                    : "Min: " + ranking_min / 2 + " Max: " + ranking_max / 2}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Antal platser</span>
                <span className="text-s"> {participants}</span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Pris</span>
                <span className="text-s"> {price} kr</span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Kommentar</span>
                <span className="text-s"> {comment}</span>
              </div>
              <div className="general-popup-flex-between align-top">
                <span className="text-m no-wrap">Historiska händelser</span>
                <span className="text-s general-popup-flex-between flex-down">
                  {historyComments.map((comment, index) => (
                    <span key={index}>{comment}</span>
                  ))}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Skapad:</span>
                <span className="text-s"> {createdAtString}</span>
              </div>
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="activityPrice" className="text-s">
                Pris på aktivitet
              </label>
              <input
                type="number"
                name="activityPrice"
                id="activityPrice"
                className="text-m"
                value={price}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="classes" className="text-s">
                Typ av medlem
              </label>
              <div className="general-popup-flex-left">
                <select
                  id="classes"
                  className="activity-select long text-m"
                  value={permissionOption}
                  onChange={(e) => setPermissionOption(e.target.value)}
                >
                  <option value="none">Inga medlemmar</option>
                  <option value="all">Alla medlemstyper</option>
                  <option value="specific">Specifika medlemstyper</option>
                </select>
              </div>

              {permissionOption === "specific" &&
                permissions.map((permission, index) => (
                  <div key={index} className="general-popup-flex-between">
                    <span className="text-m">{permission.member_type}</span>
                    <label className="toggle text-m">
                      <input
                        type="checkbox"
                        checked={
                          selectedPermissions[permission.permission_id] || false
                        }
                        onChange={() =>
                          togglePermissionSelection(permission.permission_id)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="classes" className="text-s">
                Välj ranking
              </label>
              <div className="general-popup-flex-between">
                <span className="text-m secondary-text">Ranking</span>
                <select
                  id="classes"
                  className="activity-select text-m"
                  value={rankingOption}
                  onChange={(e) => setRankingOption(e.target.value)}
                >
                  <option value="all">Alla rankingnivåer</option>
                  <option value="specific">Specifika rankingnivåer</option>
                </select>
              </div>

              {rankingOption === "specific" && (
                <>
                  <label htmlFor="minRanking" className="text-m">
                    Minimum ranking
                  </label>
                  <select
                    name="minRanking"
                    value={ranking_min}
                    onChange={handleInputChange}
                    className="text-m"
                  >
                    {rankingList.map((rank, index) => (
                      <option key={index} value={rank}>
                        {rank / 2}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="maxRanking" className="text-m">
                    Maximum ranking
                  </label>
                  <select
                    name="maxRanking"
                    value={ranking_max}
                    onChange={handleInputChange}
                    className="text-m"
                  >
                    {rankingList.map((rank, index) => (
                      <option key={index} value={rank}>
                        {rank / 2}
                      </option>
                    ))}
                  </select>
                </>
              )}
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="participants" className="text-s">
                Välj antal deltagare
              </label>
              <div className="general-popup-flex-between">
                <span className="text-m secondary-text">Deltagare</span>
                <select
                  id="participants"
                  className="activity-select  text-m"
                  value={participants}
                  onChange={(e) => setParticipants(Number(e.target.value))}
                >
                  <option disabled>Vält antal deltagare</option>
                  {amountOfPeoplesList.map((amount, index) => (
                    <option key={index} value={amount}>
                      {amount}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="visibility" className="text-s">
                Ska aktiviteten vara synlig för alla medlemmar?
              </label>
              <div className="general-popup-flex-between">
                <span className="text-m secondary-text">Synlighet</span>
                <label className="toggle text-m">
                  <input
                    type="checkbox"
                    name="visibility"
                    checked={isVisisble}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="coach" className="text-s">
                Välj tränare för aktiviteten
              </label>

              <select
                id="coach"
                className="activity-select  text-m"
                value={coachOption}
                onChange={(e) => setCoachOption(e.target.value)}
              >
                <option value="Nej">Nej</option>
                <option value="Ja">Ja</option>
              </select>

              {coachOption === "Ja" &&
                props.coachesList.map((coach, index) => (
                  <div key={index} className="general-popup-flex-between">
                    <span className="text-m">
                      {capitalizeWords(coach.first_name) +
                        " " +
                        capitalizeWords(coach.last_name)}
                    </span>
                    <label className="toggle text-m">
                      <input
                        type="checkbox"
                        checked={selectedCoaches[coach.coach_id] || false}
                        onChange={() => toggleCoachSelection(coach.coach_id)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="comment" className="text-s">
                Kommentera aktivitet
              </label>
              <input
                type="text"
                name="comment"
                id="comment"
                className="text-m"
                value={comment}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="general-popup-bottom-buttons-wrapper">
            <button className="general-popup-button" type="submit">
              Uppdatera
            </button>
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default UpdateActivityInSlotPopup;
