//--- Old regex
// export const phoneRegex =
//   "^\\+46\\s*7[02369][-\\s]?\\d{2}[-\\s]?\\d{3}[-\\s]?\\d{2}$";

export const phoneRegex = "^+?d{1,3}[- ]?(?d{1,4})?[- ]?d{1,4}[- ]?d{1,9}$";
export const socialSecurityRegex = "^\\d{2}\\d{2}\\d{2}-\\d{4}$";

export const emailRegex = "/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/";
export const emailCCRegex =
  "^(s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}s*;)*s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}s*$";
export const firstNameRegex = "/^[a-z,.'-]+$/i";
export const lastNameRegex = "/^[a-z,.'-]+$/i";
