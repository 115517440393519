import { useEffect, useState } from "react";
import "./AdminHandleActivities.scss";
import { IActivity, ICourt, IPermission } from "../../types/api";
import { combinedQuery } from "../../api/combinedQueries/combinedQueries";
import Spinner from "../../components/UI/Spinner/Spinner";
import { CognitoUser } from "../../types/cognito";
import { Auth } from "aws-amplify";
import EditActivityPopup from "../../containers/Activities/EditActivityPopup/EditActivityPopup";
import { deleteActivityTemplate } from "../../api/activities/activities";
import HandleConfirmationPopup from "../../containers/HandleConfirmation/HandleConfirmationPopup";
import { toast } from "react-toastify";

interface IAdminHandleActivitiesProps {}

function AdminHandleActivities(props: IAdminHandleActivitiesProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [
    showHandleDeleteActivityTemplatePopup,
    setShowHandleDeleteActivityTemplatePopup,
  ] = useState<boolean>(false);
  const [activityToEdit, setActivityToEdit] = useState<IActivity>();
  const [activityTemplates, setActivityTemplates] = useState<IActivity[]>([]);
  const [activityTemplateToDelete, setActivityTemplateToDelete] =
    useState<IActivity>();
  const [courts, setCourts] = useState<ICourt[]>([]);
  const [showEditAcitivityPopup, setShowEditActivityPopup] =
    useState<boolean>(false);
  const [currentActivityType, setCurrentActivityType] = useState<
    "ACTIVITIES" | "ACTIVITY_TEMPLATES"
  >("ACTIVITIES");
  const [filteredActivities, setFilteredActivities] = useState<IActivity[]>([]);
  const [sortColumn, setSortColumn] = useState<string>("datetime_start");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [searchString, setSearchString] = useState<string>("");
  const sortedActivities = [...activities].sort((a, b) => {
    let comparison = 0;

    if (sortColumn === "name") {
      comparison = a.name.localeCompare(b.name);
    } else if (sortColumn === "datetime_start") {
      const dateA = new Date(a.datetime_start).getTime();
      const dateB = new Date(b.datetime_start).getTime();
      comparison = dateA - dateB;
    }

    return sortOrder === "asc" ? comparison : -comparison;
  });

  const [permissions, setPermissions] = useState<IPermission[]>([]);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        setUser(userData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  const fetchChosenItems = async ({
    fetchUpcomingActivities = false,
    fetchCourts = false,
    fetchPermissions = false,
    fetchActivities = false,
  }) => {
    if (!user) return;
    setLoading(true);

    try {
      const response = await combinedQuery({
        requestor: "admin",
        fetchUpcomingActivities: fetchUpcomingActivities,
        fetchCourts: fetchCourts,
        fetchPermissions: fetchPermissions,
        fetchActivities: fetchActivities,
      });
      if (
        response.upcomingActivities &&
        !("message" in response.upcomingActivities)
      ) {
        setActivities(response.upcomingActivities);
      }
      if (response.courts && !("message" in response.courts)) {
        setCourts(response.courts);
      }
      if (response.permissions && !("message" in response.permissions)) {
        setPermissions(response.permissions);
      }
      if (response.activities && !("message" in response.activities)) {
        setActivityTemplates(response.activities);
      }

      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };
  const handleSearchActivities = () => {
    const filtered = activities.filter((activity) =>
      activity.name
        .split("%-%")[0]
        .toLowerCase()
        .includes(searchString.toLowerCase())
    );
    setFilteredActivities(filtered);
  };
  const handleToggleActivityType = (
    type: "ACTIVITIES" | "ACTIVITY_TEMPLATES"
  ) => {
    setCurrentActivityType(type);
    setSearchString("");
  };

  const handleDeleteAcvitityTemplate = (name: string) => async () => {
    try {
      const response = await deleteActivityTemplate(name);
      if (!("message" in response)) {
        toast.success("Aktivitetsmallen raderades");
        setActivityTemplateToDelete(undefined);
        fetchChosenItems({
          fetchActivities: true,
        });
      }
    } catch (error) {
      console.error("Error deleting activity template:", error);
    }
  };
  useEffect(() => {
    handleSearchActivities();
  }, [searchString]);
  useEffect(() => {
    fetchChosenItems({
      fetchUpcomingActivities: true,
      fetchCourts: true,
      fetchPermissions: true,
      fetchActivities: true,
    });
  }, [user]);
  useEffect(() => {
    if (activityTemplateToDelete) {
      setShowHandleDeleteActivityTemplatePopup(true);
    }
  }, [activityTemplateToDelete]);
  const openEditActivity = (activity: IActivity) => {
    setShowEditActivityPopup(true);
    setActivityToEdit(activity);
  };
  if (loading) return <Spinner />;

  const activitiesToLoop =
    searchString.length > 0 ? filteredActivities : sortedActivities;
  return (
    <div className="admin-activities-page-container">
      <div className="admin-activities-layout">
        <div className="admin-activities-declarations">
          <h1 className="text-l primary-text">Aktiviteter</h1>
        </div>
        <div className="handle-activities-actions-wrapper">
          <div className="admin-handle-activity-toggle-wrapper text-m">
            <button
              className={`toggle-button ${
                currentActivityType === "ACTIVITIES" ? "active" : ""
              }`}
              onClick={() => handleToggleActivityType("ACTIVITIES")}
            >
              Aktiviteter
            </button>
            <button
              className={`toggle-button ${
                currentActivityType === "ACTIVITY_TEMPLATES" ? "active" : ""
              }`}
              onClick={() => handleToggleActivityType("ACTIVITY_TEMPLATES")}
            >
              Mallar
            </button>
          </div>
          <div className="activities-search-wrapper">
            <div className="search-input-wrapper">
              <input
                className="activities-search-input text-m"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                placeholder="Sök "
              ></input>
              <i className="fa-regular fa-magnifying-glass search-icon"></i>
            </div>
          </div>
        </div>
        {currentActivityType === "ACTIVITY_TEMPLATES" && (
          <table className="activities-table">
            <thead>
              <tr>
                <th
                  className="text-m secondary-text pointer"
                  onClick={() => handleSort("name")}
                >
                  Namn{" "}
                  {sortColumn === "name" && (sortOrder === "asc" ? "▲" : "▼")}
                </th>
                <th className="text-m secondary-text">Banor</th>
                <th className="text-m secondary-text">Synlighet</th>
                <th className="text-m secondary-text">Färg</th>
              </tr>
            </thead>
            <tbody>
              {activityTemplates.map((activity, index) => {
                return (
                  <tr key={index}>
                    <td className="text-m primary-text" onClick={() => null}>
                      {activity.name.split("%-%")[0]}
                    </td>

                    <td className="text-m primary-text" onClick={() => null}>
                      {activity.court_id.map((court) => {
                        const courtName = courts.find(
                          (courtItem) => courtItem.court_id === court
                        )?.name;

                        return courtName + " ";
                      })}
                    </td>
                    <td className="text-m primary-text" onClick={() => null}>
                      {activity.hidden ? "Dold" : "Synlig"}
                    </td>
                    <td className="text-m primary-text" onClick={() => null}>
                      <div
                        className={`activity-color-blob ${activity.color}`}
                      ></div>
                      <div
                        onClick={() => setActivityTemplateToDelete(activity)}
                      >
                        <i className="fa-regular fa-trash"></i>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {currentActivityType === "ACTIVITIES" && (
          <table className="activities-table">
            <thead>
              <tr>
                <th
                  className="text-m secondary-text pointer"
                  onClick={() => handleSort("name")}
                >
                  Namn{" "}
                  {sortColumn === "name" && (sortOrder === "asc" ? "▲" : "▼")}
                </th>
                <th
                  className="text-m secondary-text pointer"
                  onClick={() => handleSort("datetime_start")}
                >
                  Start{" "}
                  {sortColumn === "datetime_start" &&
                    (sortOrder === "asc" ? "▲" : "▼")}
                </th>
                <th className="text-m secondary-text">Slut</th>
                <th className="text-m secondary-text">Banor</th>
                <th className="text-m secondary-text">Synlighet</th>
                <th className="text-m secondary-text">Färg</th>
              </tr>
            </thead>
            <tbody>
              {activitiesToLoop.map((activity, index) => {
                const startDate =
                  activity.datetime_start.slice(8, 10) +
                  " / " +
                  activity.datetime_start.slice(5, 7) +
                  " - " +
                  activity.datetime_start.slice(11, 16);

                const endDate =
                  activity.datetime_end.slice(8, 10) +
                  " / " +
                  activity.datetime_end.slice(5, 7) +
                  " - " +
                  activity.datetime_end.slice(11, 16);

                return (
                  <tr key={index}>
                    <td className="text-m primary-text" onClick={() => null}>
                      {activity.name.split("%-%")[0]}
                    </td>
                    <td className="text-m primary-text" onClick={() => null}>
                      {startDate}
                    </td>
                    <td className="text-m primary-text" onClick={() => null}>
                      {endDate}
                    </td>
                    <td className="text-m primary-text" onClick={() => null}>
                      {activity.court_id.map((court) => {
                        const courtName = courts.find(
                          (courtItem) => courtItem.court_id === court
                        )?.name;

                        return courtName + " ";
                      })}
                    </td>
                    <td className="text-m primary-text" onClick={() => null}>
                      {activity.hidden ? "Dold" : "Synlig"}
                    </td>
                    <td className="text-m primary-text" onClick={() => null}>
                      <div
                        className={`activity-color-blob ${activity.color}`}
                      ></div>
                      <div
                        className="edit-component"
                        onClick={() => openEditActivity(activity)}
                      >
                        <div className="edit-dot"></div>
                        <div className="edit-dot"></div>
                        <div className="edit-dot"></div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <HandleConfirmationPopup
        showPopup={showHandleDeleteActivityTemplatePopup}
        onClose={() => setShowHandleDeleteActivityTemplatePopup(false)}
        onConfirm={() => {
          activityTemplateToDelete &&
            handleDeleteAcvitityTemplate(activityTemplateToDelete.name)();
          setShowHandleDeleteActivityTemplatePopup(false);
        }}
        questionText="Är du säker på att du vill radera aktivitetsmallen?"
        confirmText="Ja"
      />

      <EditActivityPopup
        showPopup={showEditAcitivityPopup}
        onClose={() => setShowEditActivityPopup(false)}
        activity={activityToEdit}
        onConfirm={() => fetchChosenItems({ fetchUpcomingActivities: true })}
        courts={courts}
        permissions={permissions}
        coachesList={[]}
      />
    </div>
  );
}

export default AdminHandleActivities;
