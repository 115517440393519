import { toast } from "react-toastify";
import {
  AdminReportActivityNoshow,
  adminCheckInActivityBooking,
  bookActivity,
  cancelOneActivitySlot,
  getActivityByNameAndTime,
  removeOneCourtFromActivity,
  unbookActivity,
} from "../../../api/activities/activities";
import Popup from "../../../hoc/Popup/Popup";
import {
  IActivity,
  ICoach,
  ICourt,
  IIndividualWithStatus,
  IPermission,
  ISummaryItem,
  IUser,
} from "../../../types/api";
import { capitalizeWords, formatDateToSwedish } from "../../../shared/utility";
import { useEffect, useState } from "react";
import { getUserByMemberId } from "../../../api/users/users";
import "./BookHandleActivityPopup.scss";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import { StatusEnum } from "../../../shared/enums";
import { formatDate } from "../../../shared/dateUtils";
import { nukeSlot } from "../../../api/nuke/nuke";
import UpdateActivityInSlotPopup from "../UpdateActivityInSlotPopup/UpdateActivityInSlotPopup";

interface IBookActivityPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  court: ICourt;
  slot?: ISummaryItem;
  user?: IUser;
  filterDate: Date;
  timeAndCourtString: string;
  courts: ICourt[];
  coaches: ICoach[];
  permissions: IPermission[];
}

function BookActivityPopup({
  showPopup,
  court,
  slot,
  user,
  filterDate,
  timeAndCourtString,
  permissions,
  courts,
  coaches,
  onClose,
}: IBookActivityPopupProps) {
  const [showCancelOneActivitySlotPopup, setShowCancelOneActivitySlotPopup] =
    useState(false);
  const [
    showCancelCourtFromActivityPopup,
    setShowCancelCourtFromActivityPopup,
  ] = useState(false);
  const [showUpdateActivityInSlotPopup, setShowUpdateActivityInSlotPopup] =
    useState(false);
  const [showNukeActivityFromSlotPopup, setShowNukeActivityFromSlotPopup] =
    useState(false);
  const [showMarkAsNoShowPopup, setShowMarkAsNoShowPopup] = useState(false);
  const [userToMarkAsNoShow, setUserToMarkAsNoShow] =
    useState<IIndividualWithStatus | null>(null);
  const [activityToCompare, setActivityToCompare] = useState<IActivity | null>(
    null
  );
  const [activityForSplit, setActivityForSplit] = useState<IActivity | null>(
    null
  );
  const [participantsData, setParticipantsData] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { weekday, dateAndMonth } = formatDateToSwedish(filterDate);

  // Helper functions
  const startAndEndAreSameDay = (start_date: string, end_date: string) =>
    start_date.split("T")[0] === end_date.split("T")[0];

  const fetchActivityForSplit = async () => {
    if (!slot?.activity) return;
    try {
      const response = await getActivityByNameAndTime(
        slot.activity.name,
        slot.activity.datetime_start
      );
      if (response && !("message" in response)) {
        setActivityForSplit(response);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  const handleFetchParticipantsData = async () => {
    if (!slot?.activity) return;
    const participants =
      activityToCompare?.participants || slot.activity.participants;
    try {
      const response = await Promise.all(
        participants.map(
          async (participant) => await getUserByMemberId(participant.member_id)
        )
      );
      setParticipantsData(response);
    } catch (error) {
      toast.error("Failed to fetch participants");
    }
  };

  useEffect(() => {
    if (showPopup) {
      handleFetchParticipantsData();
      fetchActivityForSplit();
    }
  }, [slot, activityToCompare, showPopup]);

  // Event Handlers
  const handleClose = () => {
    setActivityToCompare(null);
    setActivityForSplit(null);
    onClose();
  };

  const handleCancelOneActivitySlot = async () => {
    if (!slot) return;
    const dateTimeToRemove = `${slot.activity.datetime_start.split("T")[0]}T${
      slot.start_time
    }`;

    try {
      const response = await cancelOneActivitySlot(
        slot.activity,
        dateTimeToRemove
      );
      if (response && !("message" in response)) {
        setActivityToCompare(response);
        toast.success("Tid borttagen");
        handleClose();
      }
    } catch (error: any) {
      toast.error("An error occurred while canceling the slot.");
    }
  };

  const handleCancelCourtFromActivity = async () => {
    if (!slot) return;
    const court_id = court.court_id;

    try {
      const response = await removeOneCourtFromActivity(
        slot.activity,
        court_id
      );
      if (response && !("message" in response)) {
        setActivityToCompare(response);
        toast.success("Bana borttagen");
        handleClose();
      }
    } catch (error: any) {
      toast.error("An error occurred while canceling the court.");
    }
  };

  const handleNukeActivityFromSlot = async () => {
    if (!slot) return;
    const dateTimeToRemove = `${slot.activity.datetime_start.split("T")[0]}T${
      slot.start_time
    }`;

    try {
      const response = await nukeSlot(slot.activity, dateTimeToRemove);
      if (response && !("message" in response)) {
        setActivityToCompare(response);
        toast.success("Aktivitet borttagen");
        handleClose();
      }
    } catch (error: any) {
      toast.error("An error occurred while canceling the slot.");
    }
  };
  const handleBookActivity = async () => {
    if (!user || !slot) return;
    const member_id = user.member_id;
    const datetime_start = `${formatDate(filterDate, "yyyy-MM-dd")}T${
      slot.start_time
    }`;
    const court_id = court.court_id;
    setIsLoading(true);
    try {
      const response = await bookActivity(
        member_id,
        court_id,
        datetime_start,
        "admin"
      );
      if (response && !("message" in response)) {
        setActivityToCompare(response);
        toast.success("Bokare tillagd till aktivitet");
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      toast.error("An error occurred while booking the activity.");
    }
  };

  const handleUnbookActivity = async (member_id: string) => {
    if (!slot) return;
    const datetime_start = `${formatDate(filterDate, "yyyy-MM-dd")}T${
      slot.start_time
    }`;
    const court_id = court.court_id;

    try {
      const response = await unbookActivity(
        court_id,
        datetime_start,
        member_id,
        "admin"
      );
      if (response && !("message" in response)) {
        setActivityToCompare(response);
        toast.success("Bokare borttagen från aktivitet");
        setParticipantsData((prev) =>
          prev.filter((participant) => participant.member_id !== member_id)
        );
      }
    } catch (error: any) {
      toast.error("An error occurred while unbooking the participant.");
    }
  };

  const handleMarkAsNoShow = async () => {
    if (!slot || !userToMarkAsNoShow) return;
    setIsLoading(true);
    const datetime_start = `${formatDate(filterDate, "yyyy-MM-dd")}T${
      slot.start_time
    }`;
    const court_id = court.court_id;
    const member_id = userToMarkAsNoShow.member_id;

    try {
      const response = await AdminReportActivityNoshow(
        member_id,
        datetime_start,
        court_id
      );
      if (response && !("message" in response)) {
        setActivityToCompare(response.activity);
        toast.success("Bokaren angiven som 'No show'");
      }
    } catch (error: any) {
      toast.error("An error occurred while marking as 'No show'.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckin = async (member_id: string, status: StatusEnum) => {
    if (!slot) return;
    const datetime_start = `${formatDate(filterDate, "yyyy-MM-dd")}T${
      slot.start_time
    }`;
    const court_id = court.court_id;

    try {
      const response = await adminCheckInActivityBooking(
        member_id,
        datetime_start,
        court_id,
        status
      );
      if (response && !("message" in response)) {
        setActivityToCompare(response.activity);
        toast.success("Bokaren incheckad");
      }
    } catch (error: any) {
      toast.error("An error occurred while checking in.");
    }
  };

  const activityToLoop = activityToCompare || slot?.activity;
  const userIsParticipant = activityToLoop?.participants?.some(
    (participant) => participant.member_id === user?.member_id
  );
  console.log(activityToLoop?.members_with_access.length, permissions.length);
  const coach =
    activityToLoop?.coach_id &&
    coaches.find((coach) => coach.coach_id === activityToLoop.coach_id[0]);
  return (
    <Popup
      showPopup={showPopup}
      onClose={handleClose}
      leftTopElement={
        <span className="text-m">
          Hantera aktivitet: {slot?.activity?.name.split("%-%")[0]} för{" "}
          {weekday} den {dateAndMonth}, {timeAndCourtString}
        </span>
      }
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form less-gap"
          onSubmit={(e) => e.preventDefault()}
        >
          {/* Activity Details */}
          <div className="general-popup-form-group mb-20">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Datum</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>
                    {weekday}, {dateAndMonth}
                  </span>
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Tid + Bana</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>{timeAndCourtString}</span>
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Ranking</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>
                    {activityToLoop && activityToLoop.ranking_min !== -1
                      ? activityToLoop.ranking_min / 2 +
                        " - " +
                        activityToLoop.ranking_max / 2
                      : "Inga ranking specifikationer"}
                  </span>
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Medlemstyper</span>
                <span className="text-s">
                  {activityToLoop &&
                  activityToLoop?.members_with_access.length ===
                    permissions.length
                    ? "Alla medlemstyper"
                    : activityToLoop &&
                      activityToLoop?.members_with_access.length > 0
                    ? permissions
                        .filter((permission) =>
                          activityToLoop?.members_with_access.includes(
                            permission.permission_id
                          )
                        )
                        .map((permission, index) => (
                          <span key={index}>
                            {index > 0 && ", "}
                            {permission.member_type}
                          </span>
                        ))
                    : "Inga medlemstyper"}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Deltagare</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>
                    {activityToLoop?.participants?.length || 0} /{" "}
                    {activityToLoop?.participants_limit}
                  </span>
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Pris:</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>{activityToLoop?.price} kr</span>
                </div>
              </div>
              {activityToLoop?.comment && (
                <div className="general-popup-flex-between flex-down">
                  <span className="text-m primary-text">Kommentar:</span>
                  <div className="text-m primary-text text-svg-wrapper">
                    <span>{activityToLoop.comment}</span>
                  </div>
                </div>
              )}
              {coach && (
                <div className="general-popup-flex-between">
                  <span className="text-m primary-text">Tränare:</span>
                  <div className="text-m primary-text text-svg-wrapper">
                    <span>
                      {capitalizeWords(coach.first_name) +
                        " " +
                        capitalizeWords(coach.last_name) +
                        " (" +
                        coach.coach_id +
                        ")"}
                    </span>
                  </div>
                </div>
              )}
              {user && (
                <div className="general-popup-flex-between">
                  <span className="text-m primary-text">Medlem</span>
                  <div className="text-m primary-text text-svg-wrapper">
                    <span>
                      {capitalizeWords(`${user.first_name} ${user.last_name}`)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Participants Section */}
          {slot?.activity && (
            <div className="general-popup-form-group mb-20">
              <div className="general-popup-form-field text-m">
                <span>Aktivitets info</span>
                <div className="general-popup-flex-between align-top">
                  <span>Deltagare: </span>
                  <div className="activity-participants-wrapper">
                    {activityToLoop?.participants?.map((participant, index) => {
                      const participantData = participantsData.find(
                        (user) => user.member_id === participant.member_id
                      );
                      if (!participantData) return null;
                      return (
                        <div
                          key={index}
                          className="activity-participant-wrapper"
                        >
                          <span>
                            {capitalizeWords(
                              `${participantData.first_name} ${participantData.last_name}`
                            )}{" "}
                            ({participantData.member_id})
                          </span>
                          <div className="checkin-text-wrapper">
                            {participant.status === "CHECKED_IN" ? (
                              <div className="checked-in text-m">Incheckad</div>
                            ) : participant.status === "NO_SHOW" ? (
                              <div className="text-danger text-m">
                                Bokaren är angiven som "No show"
                              </div>
                            ) : (
                              <div className="checkin-actions-wrapper">
                                <a
                                  href="#"
                                  className="pointer"
                                  onClick={() =>
                                    handleCheckin(
                                      participant.member_id,
                                      StatusEnum.CHECKED_IN
                                    )
                                  }
                                >
                                  Checka in
                                </a>
                                <a
                                  href="#"
                                  className="danger text-m pointer"
                                  onClick={() => {
                                    setShowMarkAsNoShowPopup(true);
                                    setUserToMarkAsNoShow(participant);
                                  }}
                                >
                                  No show
                                </a>
                              </div>
                            )}
                          </div>
                          <a
                            href="#"
                            className="danger"
                            onClick={() =>
                              handleUnbookActivity(participant.member_id)
                            }
                          >
                            Ta bort från aktivitet
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="general-popup-danger-buttons-wrapper">
            <button
              onClick={() => setShowUpdateActivityInSlotPopup(true)}
              className="text-m"
            >
              Uppdatera aktivitets slot
            </button>
          </div>
          {user && !userIsParticipant && (
            <div className="general-popup-bottom-buttons-wrapper">
              <button
                onClick={handleBookActivity}
                className="text-m"
                disabled={isLoading}
              >
                Lägg till
                {" " + capitalizeWords(`${user.first_name} ${user.last_name}`)}
              </button>
            </div>
          )}

          <div className="general-popup-danger-buttons-wrapper">
            {/* <button
              onClick={() => setShowCancelOneActivitySlotPopup(true)}
              className="text-m danger-button"
              disabled={
                !!(
                  activityForSplit &&
                  (activityForSplit.court_id.length !== 1 ||
                    !startAndEndAreSameDay(
                      activityForSplit.datetime_start,
                      activityForSplit.datetime_end
                    ))
                )
              }
            >
              Ta bort tid från aktivitet
            </button>
           */}
            {/* <button
              onClick={() => setShowNukeActivityFromSlotPopup(true)}
              className="text-m danger-button"
              disabled={
                !!(
                  activityForSplit &&
                  activityForSplit.court_id.length === 1 &&
                  startAndEndAreSameDay(
                    activityForSplit.datetime_start,
                    activityForSplit.datetime_end
                  )
                )
              }
            >
              Ta bort aktivitet från slot
            </button> */}

            {/* <button
              onClick={() => setShowCancelCourtFromActivityPopup(true)}
              className="text-m danger-button"
              disabled={
                !!(
                  activityForSplit &&
                  (activityForSplit.court_id.length === 1 ||
                    !startAndEndAreSameDay(
                      activityForSplit.datetime_start,
                      activityForSplit.datetime_end
                    ))
                )
              }
            >
              Ta bort bana
              {(slot?.activity &&
                courts.find(
                  (courtItem) =>
                    courtItem.court_id === slot?.activity.court_id[0]
                )?.name) ||
                "okänd"}{" "}
              från aktivitet
            </button> */}
          </div>
        </form>
      </div>
      <UpdateActivityInSlotPopup
        showPopup={showUpdateActivityInSlotPopup}
        onClose={() => setShowUpdateActivityInSlotPopup(false)}
        onConfirm={(activity: IActivity) => {
          setActivityToCompare(activity);
          setShowUpdateActivityInSlotPopup(false);
        }}
        activity={activityToLoop}
        court={court}
        coachesList={coaches}
        permissions={permissions}
        datetime={slot?.start_time}
        timeAndCourtString={timeAndCourtString}
        filterDate={filterDate}
      />

      {/* Confirmation Popups */}
      <HandleConfirmationPopup
        showPopup={showCancelOneActivitySlotPopup}
        onClose={() => setShowCancelOneActivitySlotPopup(false)}
        onConfirm={() => {
          handleCancelOneActivitySlot();
          setShowCancelOneActivitySlotPopup(false);
        }}
        confirmText="Ja"
        questionText="Är du säker på att vill ta bort denna tid av aktviteten?"
        denyText="Avbryt"
      />
      <HandleConfirmationPopup
        showPopup={showCancelCourtFromActivityPopup}
        onClose={() => setShowCancelCourtFromActivityPopup(false)}
        onConfirm={() => {
          handleCancelCourtFromActivity();
          setShowCancelCourtFromActivityPopup(false);
        }}
        confirmText="Ja"
        questionText="Är du säker på att vill ta bort denna bana från aktiviteten?"
        denyText="Avbryt"
      />
      <HandleConfirmationPopup
        showPopup={showMarkAsNoShowPopup}
        onClose={() => setShowMarkAsNoShowPopup(false)}
        onConfirm={() => {
          handleMarkAsNoShow();
          setShowMarkAsNoShowPopup(false);
        }}
        confirmText="Ja"
        questionText="Är du säker på att vill markera användaren som 'No show'?"
        denyText="Avbryt"
        loading={isLoading}
      />
      <HandleConfirmationPopup
        showPopup={showNukeActivityFromSlotPopup}
        onClose={() => setShowNukeActivityFromSlotPopup(false)}
        onConfirm={() => {
          handleNukeActivityFromSlot();
          setShowNukeActivityFromSlotPopup(false);
        }}
        confirmText="Ja"
        questionText="Detta kommer att rensa aktiviteten från denna slot och kan inte ångras. Är du säker?"
        denyText="Avbryt"
      />
    </Popup>
  );
}

export default BookActivityPopup;
