import "./CreateActivityPopup.scss";
import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import {
  IActivity,
  IActivityInput,
  ICoach,
  ICourt,
  IPermission,
} from "../../../types/api";
import { toast } from "react-toastify";
import { TActivityColor } from "../../../types/internal";
import WeekDaySelector from "../../../hoc/DatePicker/Datepicker";
import { capitalizeWords } from "../../../shared/utility";
import { createActivity } from "../../../api/activities/activities";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import { formatDate, formatToIsoDate } from "../../../shared/dateUtils";
import useTimeBatches from "../../../shared/hooks/useTimeBatches";
import TimeInputBatch from "../../../hoc/TimeBatch/TimeBatch";

interface ICreateActivityPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  courts: ICourt[];
  permissions: IPermission[];
  coachesList: ICoach[];
  activities: IActivity[];
  selectedSlotsProp: {
    court_id: string;
    start_time: string;
    end_time: string;
  }[];
  filterDate: Date;
}

interface SelectionMap {
  [key: string]: boolean;
}

function CreateActivityPopup(props: ICreateActivityPopupProps) {
  const [createActivityLoading, setCreateActivityLoading] = useState(false);
  const [isReoccurringActivity, setIsReoccurringActivity] =
    useState<boolean>(false);
  const [showConfirmCreateActivityPopup, setShowConfirmCreateActivityPopup] =
    useState(false);
  const {
    courts,
    permissions,
    coachesList,
    onClose,
    onConfirm,
    activities,
    showPopup,
  } = props;

  const [initialBatches, setInitialBatches] = useState<any>([]);

  const {
    timeBatches: intervals,
    addTimeBatch: addNewBatch,
    removeTimeBatch: removeBatch,
    setBatches: setBatches,
    updateStartTime,
    updateEndTime,
    updateCheckedDays,
    updatePriceChoice,
  } = useTimeBatches(initialBatches);

  useEffect(() => {
    if (isReoccurringActivity && intervals.length === 0) {
      addNewBatch(false, false, new Date(), new Date());
    }
  }, [isReoccurringActivity]);

  const [activityToLoad, setActivityToLoad] = useState<IActivity>();
  const [selectedCourts, setSelectedCourts] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedCoaches, setSelectedCoaches] = useState<{
    [key: string]: boolean;
  }>({});

  const [selectedPermissions, setSelectedPermissions] = useState<{
    [key: string]: boolean;
  }>({});
  const rankingList = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const amountOfPeoplesList = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
  ];

  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [isVisisble, setIsVisible] = useState(false);
  const [rankingOption, setRankingOption] = useState("all");
  const [courtOption, setCourtOption] = useState("specific");
  const [coachOption, setCoachOption] = useState("Nej");
  const [permissionOption, setPermissionOption] = useState("none");
  const [activityName, setActivityName] = useState("");
  const [activityPrice, setActivityPrice] = useState(0);
  const [participants, setParticipants] = useState(10);
  const [startTime, setStartTime] = useState("07:00:00");
  const [endTime, setEndTime] = useState("23:00:00");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [minRanking, setMinRanking] = useState(0);
  const [maxRanking, setMaxRanking] = useState(15);
  const [comment, setComment] = useState("");
  const [activityColor, setActivityColor] =
    useState<TActivityColor>("activity-color-1");

  useEffect(() => {
    if (permissionOption === "none") {
      setSelectedPermissions({});
      setParticipants(0);
    }
  }, [permissionOption, participants]);
  const clearData = () => {
    setActivityToLoad(undefined);
    setActivityName("");
    setActivityPrice(0);
    setStartDate(new Date());
    setEndDate(new Date());
    setStartTime("07:00:00");
    setEndTime("23:00:00");
    setParticipants(10);
    setComment("");
    setIsVisible(false);
    setSaveAsTemplate(false);
    setCourtOption("all");
    setPermissionOption("none");
    setCoachOption("Nej");
    setRankingOption("all");
    setMinRanking(1);
    setMaxRanking(15);
    setActivityColor("activity-color-1");
    setSelectedCoaches({});
    setSelectedCourts({});
    setSelectedPermissions({});
  };

  const handleClose = () => {
    clearData();
    onClose();
  };
  useEffect(() => {
    if (props.selectedSlotsProp.length > 0) {
      const startTimes = props.selectedSlotsProp.map(
        (slot) => new Date(`1970-01-01T${slot.start_time}`)
      );
      const endTimes = props.selectedSlotsProp.map(
        (slot) => new Date(`1970-01-01T${slot.end_time}`)
      );

      const earliestStartTime = new Date(
        Math.min(...startTimes.map((time) => time.getTime()))
      );
      const latestEndTime = new Date(
        Math.max(...endTimes.map((time) => time.getTime()))
      );

      setStartTime(earliestStartTime.toTimeString().slice(0, 8));
      setEndTime(latestEndTime.toTimeString().slice(0, 8));
      setStartDate(props.filterDate);
      setEndDate(props.filterDate);
      setCourtOption("specific");
      const selectedCourts: SelectionMap = {};
      props.selectedSlotsProp.forEach((slot) => {
        selectedCourts[slot.court_id] = true;
      });
      setSelectedCourts(selectedCourts);
    }
  }, [props.selectedSlotsProp]);
  // useEffect(() => {
  //   if (startDate < new Date()) {
  //     setStartDate(new Date());
  //   }
  //   if (endDate < new Date()) {
  //     setEndDate(new Date());
  //   }
  // }, [startDate, endDate]);
  const handleTimeChange = (
    timeType: "start" | "end",
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const timeValue = e.target.value;
    if (timeType === "start") {
      setStartTime(timeValue);
    } else {
      setEndTime(timeValue);
    }
  };

  const handleTimeBlur = (
    timeType: "start" | "end",
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    let time = e.target.value;
    let parts = time.split(":");

    if (parts.length === 2) {
      time += ":00";
    } else if (parts.length === 1 && time.includes(":")) {
      time += "00:00";
    } else if (parts.length === 1) {
      time = time.padStart(2, "0") + ":00:00";
    }

    if (timeType === "start") {
      setStartTime(time);
    } else {
      setEndTime(time);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "activityName":
        setActivityName(value);
        break;
      case "minRanking":
        setMinRanking(value);
        break;
      case "maxRanking":
        setMaxRanking(value);
        break;
      case "visibility":
        setIsVisible(!isVisisble);
        break;
      case "template":
        setSaveAsTemplate(!saveAsTemplate);
        break;
      case "participants":
        setParticipants(value);
        break;
      case "comment":
        setComment(value);
        break;
      case "activityPrice":
        setActivityPrice(value);
        break;
      default:
        break;
    }
  };

  const toggleCourtSelection = (courtId: string) => {
    setSelectedCourts((prev) => ({
      ...prev,
      [courtId]: !prev[courtId],
    }));
  };

  const toggleCoachSelection = (coachId: string) => {
    setSelectedCoaches((prev) => ({
      ...prev,
      [coachId]: !prev[coachId],
    }));
  };

  const togglePermissionSelection = (permissionId: string) => {
    setSelectedPermissions((prev) => ({
      ...prev,
      [permissionId]: !prev[permissionId],
    }));
  };
  const handleColorChange = (color: TActivityColor) => {
    setActivityColor(color);
  };
  const handleCreateActivity = async () => {
    let courtIdList = [];

    if (courtOption === "all") {
      courtIdList = courts.map((court) => court.court_id);
    } else {
      courtIdList = Object.entries(selectedCourts)
        .filter(([_, isSelected]) => isSelected)
        .map(([courtId, _]) => courtId);
    }
    let permissionsIdList: any[] = [];
    if (permissionOption === "all") {
      permissionsIdList = permissions.map(
        (permission) => permission.permission_id
      );
    } else if (permissionOption === "none") {
      permissionsIdList = [];
    } else {
      permissionsIdList = Object.entries(selectedPermissions)
        .filter(([_, isSelected]) => isSelected)
        .map(([permissionId, _]) => permissionId);
    }
    let coachesIdList = [""];
    if (coachOption === "Ja") {
      coachesIdList = Object.entries(selectedCoaches)
        .filter(([_, isSelected]) => isSelected)
        .map(([coachId, _]) => coachId);
    }

    const rankingMin = rankingOption === "all" ? -1 : minRanking;
    const rankingMax = rankingOption === "all" ? 15 : maxRanking;

    const baseActivityData: Omit<
      IActivityInput,
      "datetime_start" | "datetime_end"
    > = {
      name: activityName,
      court_id: courtIdList,
      members_with_access: permissionsIdList,
      ranking_min: rankingMin,
      ranking_max: rankingMax,
      participants_limit: participants,
      participants: [],
      comment: comment,
      color: activityColor,
      hidden: !isVisisble,
      coach_id: coachesIdList,
      price: activityPrice,
    };
    const activityToCreate: IActivityInput = {
      name: activityName,
      datetime_start: formatDate(startDate, "yyyy-MM-dd") + "T" + startTime,
      datetime_end: formatDate(endDate, "yyyy-MM-dd") + "T" + endTime,
      court_id: courtIdList,
      members_with_access: permissionsIdList,
      ranking_min: rankingMin,
      ranking_max: rankingMax,
      participants_limit: participants,
      participants: [],
      comment: comment,
      color: activityColor,
      hidden: !isVisisble,
      coach_id: coachesIdList,
      price: activityPrice,
    };
    if (activityToCreate.datetime_start > activityToCreate.datetime_end) {
      toast.error("Starttid måste vara före sluttid");
      setShowConfirmCreateActivityPopup(false);
      return;
    }

    if (!isReoccurringActivity) {
      // single instance activity
      try {
        setCreateActivityLoading(true);
        const response = await createActivity(
          {
            ...baseActivityData,
            datetime_start:
              formatDate(startDate, "yyyy-MM-dd") + "T" + startTime,
            datetime_end: formatDate(endDate, "yyyy-MM-dd") + "T" + endTime,
          },
          saveAsTemplate
        );

        if (response && !("message" in response)) {
          toast.success("Aktivitet skapad");
          onConfirm();
          handleClose();
        }
      } catch (error: any) {
        toast.error(error);
      } finally {
        setCreateActivityLoading(false);
      }
    } else {
      // recurring activity with multiple instances
      const dayMap = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 0,
      };
      const activityInstances: IActivityInput[] = [];

      for (const batch of intervals) {
        const {
          startTime: batchStartTime,
          endTime: batchEndTime,
          checkedDays,
        } = batch;

        const dayMap: { [key in keyof typeof checkedDays]: number } = {
          monday: 1,
          tuesday: 2,
          wednesday: 3,
          thursday: 4,
          friday: 5,
          saturday: 6,
          sunday: 0,
        };

        let current = new Date(startDate);

        while (current <= endDate) {
          const dayName = Object.keys(dayMap).find(
            (day) => dayMap[day as keyof typeof dayMap] === current.getDay()
          );

          if (dayName && checkedDays[dayName]) {
            const activityInstance: IActivityInput = {
              ...baseActivityData,
              datetime_start:
                formatDate(current, "yyyy-MM-dd") + "T" + batchStartTime,
              datetime_end:
                formatDate(current, "yyyy-MM-dd") + "T" + batchEndTime,
            };
            activityInstances.push(activityInstance);
          }
          current.setDate(current.getDate() + 1); // move to the next day
        }
      }

      try {
        setCreateActivityLoading(true);
        const responses = await Promise.allSettled(
          activityInstances.map((instance) => {
            return createActivity(instance, saveAsTemplate);
          })
        );

        const successfulDates: string[] = [];

        responses.forEach((response, index) => {
          if (
            response.status === "fulfilled" &&
            !("message" in response.value)
          ) {
            const { datetime_start, datetime_end } = activityInstances[index];
            successfulDates.push(`${datetime_start} - ${datetime_end}`);
          }
        });

        if (successfulDates.length > 0) {
          toast.success(
            `Följande aktiviteter har skapats: ${successfulDates.join(", ")}`
          );
          onConfirm();
          handleClose();
        }
      } catch (error: any) {
        toast.error(error);
      } finally {
        setCreateActivityLoading(false);
      }
    }
  };

  const handlePropTemplate = () => {
    if (!activityToLoad) {
      return null;
    }
    setActivityName(activityToLoad.name || "");
    setParticipants(activityToLoad.participants_limit || 0);
    setComment(activityToLoad.comment || "");
    setActivityColor(
      (activityToLoad.color as TActivityColor) ||
        ("activity-color-1" as TActivityColor)
    );
    setIsVisible(!activityToLoad.hidden);
    setSaveAsTemplate(false);
    if (activityToLoad.court_id) {
      const courtIds = activityToLoad.court_id;
      const selectedCourts: SelectionMap = {};
      courtIds.forEach((courtId) => {
        selectedCourts[courtId] = true;
      });
      setCourtOption("specific");
      setSelectedCourts(selectedCourts);
    }
    if (activityToLoad.members_with_access) {
      const permissionIds = activityToLoad.members_with_access;
      const selectedPermissions: SelectionMap = {};
      permissionIds.forEach((permissionId) => {
        selectedPermissions[permissionId] = true;
      });
      setPermissionOption("specific");
      setSelectedPermissions(selectedPermissions);
    }
    if (activityToLoad.coach_id) {
      const coachIds = activityToLoad.coach_id;
      const selectedCoaches: SelectionMap = {};
      coachIds.forEach((coachId) => {
        selectedCoaches[coachId] = true;
      });
      setCoachOption("ja");
      setSelectedCoaches(selectedCoaches);
    }
    if (
      activityToLoad.ranking_min !== -1 ||
      activityToLoad.ranking_max !== 15
    ) {
      setRankingOption("specific");
      setMinRanking(activityToLoad.ranking_min);
      setMaxRanking(activityToLoad.ranking_max);
    }
  };

  useEffect(() => {
    if (activityToLoad) {
      handlePropTemplate();
    }
  }, [activityToLoad]);

  return (
    <Popup
      showPopup={showPopup}
      onClose={handleClose}
      leftTopElement={<span className="text-m">Skapa aktivitet</span>}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            setShowConfirmCreateActivityPopup(true);
          }}
        >
          {activities && (
            <div className="load-activity-wrapper text-m">
              <select
                value={activityToLoad?.name || "ingen-mall"}
                onChange={(e) => {
                  if (e.target.value === "ingen-mall") {
                    clearData();
                  } else {
                    setActivityToLoad(
                      activities.find(
                        (activity) => activity.name === e.target.value
                      )
                    );
                  }
                }}
              >
                <option value="ingen-mall">Välj mall</option>
                {activities.map((activity, index) => {
                  return (
                    <option key={index} value={activity.name}>
                      {activity.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <span className="text-m">Namn på aktivitet</span>
                <span className={`text-s activity-name `}>
                  {activityName}
                  <div className={`activity-name-blob ${activityColor}`}></div>
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Bana</span>
                <span className="text-s">
                  {courtOption === "all"
                    ? "Alla banor"
                    : courts
                        .filter((court) => {
                          return selectedCourts[court.court_id];
                        })
                        .map((court, index) => {
                          if (index === 0) {
                            return court.name;
                          }
                          return ", " + court.name;
                        })}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Datum</span>
                <span className="text-s">
                  {formatToIsoDate(startDate).slice(8, 10)} /{" "}
                  {formatToIsoDate(startDate).slice(5, 7)} -{" "}
                  {formatToIsoDate(endDate).slice(8, 10)} /{" "}
                  {formatToIsoDate(endDate).slice(5, 7)}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Tid</span>
                <span className="text-s">
                  {startTime.slice(0, 5)} - {endTime.slice(0, 5)}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Medlemstyper</span>
                <span className="text-s">
                  {permissionOption === "all"
                    ? "Alla medlemstyper"
                    : permissions
                        .filter((permission) => {
                          return selectedPermissions[permission.permission_id];
                        })
                        .map((permission, index) => {
                          if (index === 0) {
                            return permission.member_type;
                          }
                          return ", " + permission.member_type;
                        })}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Ranking</span>
                <span className="text-s">
                  {rankingOption === "all"
                    ? "Alla rankingnivåer"
                    : minRanking / 2 + " - " + maxRanking / 2}
                </span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Antal platser</span>
                <span className="text-s"> {participants}</span>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m">Kommentar</span>
                <span className="text-s"> {comment}</span>
              </div>
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="activityName" className="text-s">
                Namn på aktivitet
              </label>
              <input
                type="text"
                required
                name="activityName"
                id="activityName"
                className="text-m"
                value={activityName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="activityPrice" className="text-s">
                Pris på aktivitet
              </label>
              <input
                type="number"
                name="activityPrice"
                id="activityPrice"
                className="text-m"
                value={activityPrice}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="template" className="text-s">
                Ska denna aktivitet sparas som en mall?
              </label>
              <div className="general-popup-flex-between">
                <span className="text-m secondary-text">Mall</span>
                <label className="toggle text-m">
                  <input
                    type="checkbox"
                    name="template"
                    checked={saveAsTemplate}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="classes" className="text-s">
                Banor
              </label>
              <div className="general-popup-flex-left">
                <select
                  id="classes"
                  className="activity-select long text-m"
                  value={courtOption}
                  onChange={(e) => setCourtOption(e.target.value)}
                >
                  <option value="all">Alla banor</option>
                  <option value="specific">Specifika banor</option>
                </select>
              </div>

              {courtOption === "specific" &&
                courts.map((court, index) => (
                  <div key={index} className="general-popup-flex-between">
                    <span className="text-m">{court.name}</span>
                    <label className="toggle text-m">
                      <input
                        type="checkbox"
                        checked={selectedCourts[court.court_id] || false}
                        onChange={() => toggleCourtSelection(court.court_id)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <span className="text-m">Är aktiviteten återkommande?</span>
                <label className="toggle text-m">
                  <input
                    type="checkbox"
                    checked={isReoccurringActivity}
                    onChange={(e) => setIsReoccurringActivity(e.target.checked)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          {isReoccurringActivity && (
            <div className="general-popup-form-group">
              <div className="general-popup-form-field">
                <label className="text-m">Tider:</label>
                {intervals.map((batch, index) => (
                  <TimeInputBatch
                    key={batch.id}
                    id={batch.id}
                    startTime={batch.startTime || "07:00:00"}
                    endTime={batch.endTime || "23:00:00"}
                    checkedDays={batch.checkedDays}
                    defaultChecked={false}
                    isLast={false}
                    isFirst={index === 0}
                    isException={false}
                    onAdd={() =>
                      addNewBatch(false, false, new Date(), new Date())
                    }
                    onRemove={() => removeBatch(batch.id)}
                    onStartTimeChange={(newStartTime) =>
                      updateStartTime(batch.id, newStartTime)
                    }
                    onEndTimeChange={(newEndTime) =>
                      updateEndTime(batch.id, newEndTime)
                    }
                    onDayChange={(dayString) => {
                      updateCheckedDays(batch.id, dayString);
                    }}
                    priceChoice={batch.priceChoice}
                    onPriceChoiceChange={(newPriceChoice) =>
                      updatePriceChoice(batch.id, newPriceChoice)
                    }
                  />
                ))}
                <label htmlFor="memberType" className="text-s">
                  Start
                </label>
                <div className="activity-times-wrapper">
                  <WeekDaySelector
                    onChange={(e) => setStartDate(e)}
                    value={startDate}
                    includeWeekdays={false}
                    textM
                  />
                </div>
                <label htmlFor="memberType" className="text-s">
                  Slut
                </label>
                <div className="activity-times-wrapper">
                  <WeekDaySelector
                    onChange={(e) => setEndDate(e)}
                    value={endDate}
                    includeWeekdays={false}
                    textM
                  />
                </div>
              </div>
            </div>
          )}
          {isReoccurringActivity === false && (
            <div className="general-popup-form-group">
              <div className="general-popup-form-field">
                <label htmlFor="memberType" className="text-s">
                  Start
                </label>
                <div className="activity-times-wrapper">
                  <WeekDaySelector
                    onChange={(e) => setStartDate(e)}
                    value={startDate}
                    includeWeekdays={false}
                    textM
                  />
                  <input
                    type="time"
                    id="startTime"
                    value={startTime}
                    className="text-m"
                    onBlur={(e) => handleTimeBlur("start", e)}
                    onChange={(e) => handleTimeChange("start", e)}
                  />
                </div>
                <label htmlFor="memberType" className="text-s">
                  Slut
                </label>
                <div className="activity-times-wrapper">
                  <WeekDaySelector
                    onChange={(e) => setEndDate(e)}
                    value={endDate}
                    includeWeekdays={false}
                    textM
                  />
                  <input
                    type="time"
                    id="endTime"
                    value={endTime}
                    className="text-m"
                    onBlur={(e) => handleTimeBlur("end", e)}
                    onChange={(e) => handleTimeChange("end", e)}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="classes" className="text-s">
                Typ av medlem
              </label>
              <div className="general-popup-flex-left">
                <select
                  id="classes"
                  className="activity-select long text-m"
                  value={permissionOption}
                  onChange={(e) => setPermissionOption(e.target.value)}
                >
                  <option value="none">Inga medlemmar</option>
                  <option value="all">Alla medlemstyper</option>
                  <option value="specific">Specifika medlemstyper</option>
                </select>
              </div>

              {permissionOption === "specific" &&
                permissions.map((permission, index) => (
                  <div key={index} className="general-popup-flex-between">
                    <span className="text-m">{permission.member_type}</span>
                    <label className="toggle text-m">
                      <input
                        type="checkbox"
                        checked={
                          selectedPermissions[permission.permission_id] || false
                        }
                        onChange={() =>
                          togglePermissionSelection(permission.permission_id)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="classes" className="text-s">
                Välj ranking
              </label>
              <div className="general-popup-flex-between">
                <span className="text-m secondary-text">Ranking</span>
                <select
                  id="classes"
                  className="activity-select text-m"
                  value={rankingOption}
                  onChange={(e) => setRankingOption(e.target.value)}
                >
                  <option value="all">Alla rankingnivåer</option>
                  <option value="specific">Specifika rankingnivåer</option>
                </select>
              </div>

              {rankingOption === "specific" && (
                <>
                  <label htmlFor="minRanking" className="text-m">
                    Minimum ranking
                  </label>
                  <select
                    name="minRanking"
                    value={minRanking}
                    onChange={handleInputChange}
                    className="text-m"
                  >
                    {rankingList.map((rank, index) => (
                      <option key={index} value={rank}>
                        {rank / 2}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="maxRanking" className="text-m">
                    Maximum ranking
                  </label>
                  <select
                    name="maxRanking"
                    value={maxRanking}
                    onChange={handleInputChange}
                    className="text-m"
                  >
                    {rankingList.map((rank, index) => (
                      <option key={index} value={rank}>
                        {rank / 2}
                      </option>
                    ))}
                  </select>
                </>
              )}
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="participants" className="text-s">
                Välj antal deltagare
              </label>
              <div className="general-popup-flex-between">
                <span className="text-m secondary-text">Deltagare</span>
                <select
                  id="participants"
                  className="activity-select  text-m"
                  value={participants}
                  onChange={(e) => setParticipants(Number(e.target.value))}
                >
                  <option disabled>Vält antal deltagare</option>
                  {amountOfPeoplesList.map((amount, index) => (
                    <option key={index} value={amount}>
                      {amount}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="classes" className="text-s">
                Välj färg för aktivitet
              </label>
              <div className="activity-colors-wrapper">
                <div className="activity-color">
                  <input
                    type="radio"
                    name="activity-color"
                    checked={activityColor === "activity-color-1"}
                    onChange={() => handleColorChange("activity-color-1")}
                  />
                  <div className="color activity-color-1"></div>
                </div>
                <div className="activity-color">
                  <input
                    type="radio"
                    name="activity-color"
                    checked={activityColor === "activity-color-2"}
                    onChange={() => handleColorChange("activity-color-2")}
                  />
                  <div className="color activity-color-2"></div>
                </div>

                <div className="activity-color">
                  <input
                    type="radio"
                    name="activity-color "
                    checked={activityColor === "activity-color-3"}
                    onChange={() => handleColorChange("activity-color-3")}
                  />
                  <div className="color activity-color-3"></div>
                </div>
                <div className="activity-color">
                  <input
                    type="radio"
                    name="activity-color"
                    checked={activityColor === "activity-color-4"}
                    onChange={() => handleColorChange("activity-color-4")}
                  />
                  <div className="color activity-color-4"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="visibility" className="text-s">
                Ska aktiviteten vara synlig för alla medlemmar?
              </label>
              <div className="general-popup-flex-between">
                <span className="text-m secondary-text">Synlighet</span>
                <label className="toggle text-m">
                  <input
                    type="checkbox"
                    name="visibility"
                    checked={isVisisble}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="coach" className="text-s">
                Välj tränare för aktiviteten
              </label>

              <select
                id="coach"
                className="activity-select  text-m"
                value={coachOption}
                onChange={(e) => setCoachOption(e.target.value)}
              >
                <option value="Nej">Nej</option>
                <option value="Ja">Ja</option>
              </select>

              {coachOption === "Ja" &&
                coachesList.map((coach, index) => (
                  <div key={index} className="general-popup-flex-between">
                    <span className="text-m">
                      {capitalizeWords(coach.first_name) +
                        " " +
                        capitalizeWords(coach.last_name)}
                    </span>
                    <label className="toggle text-m">
                      <input
                        type="checkbox"
                        checked={selectedCoaches[coach.coach_id] || false}
                        onChange={() => toggleCoachSelection(coach.coach_id)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="comment" className="text-s">
                Kommentera aktivitet
              </label>
              <input
                type="text"
                name="comment"
                id="comment"
                className="text-m"
                value={comment}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="general-popup-bottom-buttons-wrapper flex-end">
            <button type="submit" className="text-m">
              Skapa aktivitet
            </button>
          </div>
        </form>
      </div>

      <HandleConfirmationPopup
        showPopup={showConfirmCreateActivityPopup}
        onClose={() => setShowConfirmCreateActivityPopup(false)}
        onConfirm={handleCreateActivity}
        questionText="Att skapa en aktivitet skriver över allt på dom tiderna som är valda. Processen kan ta en stund. Är du säker på att du vill fortsätta?"
        confirmText="Ja"
        loading={createActivityLoading}
      />
    </Popup>
  );
}

export default CreateActivityPopup;
