import "./CreateDefaultHoursPopup.scss";
import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { toast } from "react-toastify";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import { IWeekdayHoursFacility } from "../../../types/facility";
import { createDefaultHours } from "../../../api/facility/facilityOpeningTimes";

interface ICreateDefaultHoursPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  courtType: "INDOOR" | "OUTDOOR" | "GYM" | "REHAB";
}

function CreateDefaultHoursPopup(props: ICreateDefaultHoursPopupProps) {
  const { showPopup, onClose, onConfirm, courtType } = props;
  const [showHandleConfirmationPopup, setShowHandleConfirmationPopup] =
    useState(false);
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };
  const handleClose = () => {
    setDefaultHourData(initialDefaultData);
    setStartTime("07:00:00");
    setEndTime("23:00:00");
    onClose();
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [startTime, setStartTime] = useState("07:00:00");
  const [endTime, setEndTime] = useState("23:00:00");
  const initialDefaultData: IWeekdayHoursFacility = {
    weekday: "monday",
    opening_hours: "07:00:00",
    closing_hours: "23:00:00",
    court_type: courtType,
  };

  const [defaultHourData, setDefaultHourData] =
    useState<IWeekdayHoursFacility>(initialDefaultData);

  const weekdays = {
    Måndag: "monday",
    Tisdag: "tuesday",
    Onsdag: "wednesday",
    Torsdag: "thursday",
    Fredag: "friday",
    Lördag: "saturday",
    Söndag: "sunday",
  };
  const handleTimeChange = (
    timeType: "start" | "end",
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const timeValue = e.target.value;
    if (timeType === "start") {
      setStartTime(timeValue);
    } else {
      setEndTime(timeValue);
    }
  };

  const handleTimeBlur = (
    timeType: "start" | "end",
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    let time = e.target.value;
    let parts = time.split(":");

    if (parts.length === 2) {
      time += ":00";
    } else if (parts.length === 1 && time.includes(":")) {
      time += "00:00";
    } else if (parts.length === 1) {
      time = time.padStart(2, "0") + ":00:00";
    }

    if (timeType === "start") {
      setStartTime(time);
    } else {
      setEndTime(time);
    }
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setDefaultHourData({
      ...defaultHourData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateDefaultHours = async () => {
    setLoading(true);
    defaultHourData.opening_hours = startTime;
    defaultHourData.closing_hours = endTime;
    try {
      const response = await createDefaultHours(defaultHourData);
      if (!("code" in response)) {
        toast.success("Grundinställning skapad");
        handleConfirm();
      }
    } catch (error: any) {
      toast.error(`${error.errors?.[0].message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showPopup) {
      setDefaultHourData(initialDefaultData);
    }
  }, [showPopup]);
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={handleClose}
      leftTopElement={<span className="text-m">Skapa grundinställning</span>}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            setShowHandleConfirmationPopup(true);
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <label htmlFor="weekday" className="text-s">
                  Veckodag
                </label>
                <select
                  className="text-m"
                  name="weekday"
                  id="weekday"
                  value={defaultHourData.weekday}
                  onChange={(e) => handleInputChange(e)}
                >
                  {Object.entries(weekdays).map(
                    ([swedishName, englishValue]) => (
                      <option value={englishValue} key={englishValue}>
                        {swedishName}
                      </option>
                    )
                  )}
                </select>
              </div>
              <div className="general-popup-flex-between">
                <label htmlFor="courtType" className="text-s">
                  Typ av bana
                </label>
                <select
                  className="text-m"
                  name="court_type"
                  id="courtType"
                  value={defaultHourData.court_type}
                  disabled
                >
                  <option value="INDOOR">Inomhus</option>
                  <option value="OUTDOOR">Utomhus</option>
                  <option value="REHAB">Rehab</option>
                </select>
              </div>
              <div className="general-popup-flex-between">
                <label htmlFor="memberType" className="text-s">
                  Start
                </label>
                <input
                  type="time"
                  id="startTime"
                  value={startTime}
                  className="text-m"
                  onBlur={(e) => handleTimeBlur("start", e)}
                  onChange={(e) => handleTimeChange("start", e)}
                />
              </div>

              <div className="general-popup-flex-between">
                <label htmlFor="memberType" className="text-s">
                  Slut
                </label>
                <input
                  type="time"
                  id="endTime"
                  value={endTime}
                  className="text-m"
                  onBlur={(e) => handleTimeBlur("end", e)}
                  onChange={(e) => handleTimeChange("end", e)}
                />
              </div>
            </div>
          </div>

          <div className="general-popup-bottom-buttons-wrapper flex-end">
            <button type="submit" className="text-m">
              Skapa grundinställning
            </button>
          </div>
        </form>
      </div>

      <HandleConfirmationPopup
        showPopup={showHandleConfirmationPopup}
        onClose={() => setShowHandleConfirmationPopup(false)}
        onConfirm={() => {
          handleCreateDefaultHours();
          setShowHandleConfirmationPopup(false);
        }}
        confirmText="Ja"
        questionText="Är du säker på att du vill skapa upp denna grundinställning?"
        denyText="Avbryt"
      />
    </Popup>
  );
}

export default CreateDefaultHoursPopup;
