import "./EditDefaultHoursPopup.scss";
import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { toast } from "react-toastify";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import { IWeekdayHoursFacility } from "../../../types/facility";
import { updateDefaultHours } from "../../../api/facility/facilityOpeningTimes";

interface IEditDefaultHoursPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  currentDefaultHours?: IWeekdayHoursFacility;
}

function EditDefaultHoursPopup(props: IEditDefaultHoursPopupProps) {
  const { showPopup, onClose, onConfirm, currentDefaultHours } = props;
  const [showHandleConfirmationPopup, setShowHandleConfirmationPopup] =
    useState(false);
  const [showDoubleConfirmationPopup, setShowDoubleConfirmationPopup] =
    useState(false);
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };
  const handleClose = () => {
    setDefaultHourData(initialDefaultData);
    setStartTime("07:00:00");
    setEndTime("23:00:00");
    onClose();
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [startTime, setStartTime] = useState("07:00:00");
  const [endTime, setEndTime] = useState("23:00:00");
  const initialDefaultData: IWeekdayHoursFacility = {
    weekday: "monday",
    opening_hours: "07:00:00",
    closing_hours: "23:00:00",
    court_type: "INDOOR",
  };

  const [defaultHourData, setDefaultHourData] =
    useState<IWeekdayHoursFacility>(initialDefaultData);

  const weekdays = {
    Måndag: "monday",
    Tisdag: "tuesday",
    Onsdag: "wednesday",
    Torsdag: "thursday",
    Fredag: "friday",
    Lördag: "saturday",
    Söndag: "sunday",
  };
  const courtTypes = ["INDOOR", "OUTDOOR"];
  const swedishCourtTypes = courtTypes.map((type) => {
    return {
      swedish: type === "INDOOR" ? "Inomhus" : "Utomhus",
      english: type,
    };
  });

  const reverseWeekdays = Object.entries(weekdays).reduce(
    (acc, [swedish, english]) => {
      acc[english] = swedish;
      return acc;
    },
    {} as Record<string, string>
  );

  // Get the Swedish weekday name from defaultHourData.weekday
  const swedishWeekday = reverseWeekdays[defaultHourData.weekday];
  const handleTimeChange = (
    timeType: "start" | "end",
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const timeValue = e.target.value;
    if (timeType === "start") {
      setStartTime(timeValue);
    } else {
      setEndTime(timeValue);
    }
  };

  const handleTimeBlur = (
    timeType: "start" | "end",
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    let time = e.target.value;
    let parts = time.split(":");

    if (parts.length === 2) {
      time += ":00";
    } else if (parts.length === 1 && time.includes(":")) {
      time += "00:00";
    } else if (parts.length === 1) {
      time = time.padStart(2, "0") + ":00:00";
    }

    if (timeType === "start") {
      setStartTime(time);
    } else {
      setEndTime(time);
    }
  };

  const handleUpdateDefaultHours = async () => {
    setLoading(true);
    defaultHourData.opening_hours = startTime;
    defaultHourData.closing_hours = endTime;
    try {
      const response = await updateDefaultHours(defaultHourData);
      if (!("code" in response)) {
        toast.success("Grundinställning uppdaterad");
        handleConfirm();
      }
    } catch (error: any) {
      toast.error(`${error.errors?.[0].message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showPopup && currentDefaultHours) {
      setDefaultHourData(currentDefaultHours);
      setStartTime(currentDefaultHours.opening_hours);
      setEndTime(currentDefaultHours.closing_hours);
    }
  }, [showPopup]);

  const isDisabled =
    startTime === defaultHourData.opening_hours &&
    endTime === defaultHourData.closing_hours;

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={handleClose}
      leftTopElement={
        <span className="text-m">
          Redigera grundinställning för {swedishWeekday},{" "}
          {
            swedishCourtTypes.find(
              (type) => type.english === defaultHourData.court_type
            )?.swedish
          }
        </span>
      }
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            setShowHandleConfirmationPopup(true);
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <label htmlFor="memberType" className="text-s">
                  Start
                </label>
                <input
                  type="time"
                  id="startTime"
                  value={startTime}
                  className="text-m"
                  onBlur={(e) => handleTimeBlur("start", e)}
                  onChange={(e) => handleTimeChange("start", e)}
                />
              </div>

              <div className="general-popup-flex-between">
                <label htmlFor="memberType" className="text-s">
                  Slut
                </label>
                <input
                  type="time"
                  id="endTime"
                  value={endTime}
                  className="text-m"
                  onBlur={(e) => handleTimeBlur("end", e)}
                  onChange={(e) => handleTimeChange("end", e)}
                />
              </div>
            </div>
          </div>

          <div className="general-popup-bottom-buttons-wrapper flex-end">
            <button type="submit" className="text-m" disabled={isDisabled}>
              Uppdatera grundinställning
            </button>
          </div>
        </form>
      </div>
      <HandleConfirmationPopup
        showPopup={showHandleConfirmationPopup}
        onClose={() => setShowHandleConfirmationPopup(false)}
        onConfirm={() => {
          setShowHandleConfirmationPopup(false);
          setShowDoubleConfirmationPopup(true);
        }}
        confirmText="Ja"
        questionText="Är du säker på att du vill uppdatera denna grundinställning?"
        denyText="Avbryt"
      />
      <HandleConfirmationPopup
        showPopup={showDoubleConfirmationPopup}
        onClose={() => setShowDoubleConfirmationPopup(false)}
        onConfirm={() => {
          handleUpdateDefaultHours();
          setShowDoubleConfirmationPopup(false);
        }}
        confirmText="Ja"
        questionText="Om bokningar ligger utanför uppdaterade öppettider kommer dessa inte avbokas. Vill du genomföra uppdateringen ändå?"
        denyText="Avbryt"
      />
    </Popup>
  );
}

export default EditDefaultHoursPopup;
