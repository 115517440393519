import { formatDate } from "../../shared/dateUtils";
import { ICoachAvailabilityInput, ICoachInput } from "../../types/api";

export const createCoachQueryFn = (coachToCreate: ICoachInput) => {
  const query = `
    createCoach(input: $input) {
        ... on Coach {
         coach_id
        }
        ... on Error {
        message
        code
        }
    }
  `;
  const inputs = `$input: CoachInput!`;
  const variables = { input: coachToCreate };

  return {
    query,
    inputs,
    variables,
  };
};

export const deleteCoachQueryFn = (coach_id: string) => {
  const query = `
        deleteCoach(coach_id: $coach_id) {
            ... on Coach {
                coach_id
            }
            ... on Error {
                message
                code
            }
        }
    `;
  const inputs = `$coach_id: String!`;
  const variables = { coach_id: coach_id };

  return {
    query,
    inputs,
    variables,
  };
};

export const listCoachesQueryFn = (requestor: "admin" | "user") => {
  let query = "";
  if (requestor === "admin") {
    query = `
        listCoaches {
        ... on Coach {
            coach_id
            first_name
            last_name
            email
            description
            coach_price
        }
        ... on Error {
            message
            code
        }
        }
    `;
  } else {
    query = `
      listCoaches {
        ... on Coach {
            coach_id
            first_name
            last_name
            description 
            coach_price
        }
        ... on Error {
            message
            code
        }
      }
    `;
  }

  return {
    query,
  };
};

export const getCoachByIDQueryFn = (coach_id: string) => {
  const query = `
        getCoachByID(coach_id: $coach_id) {
            ... on Coach {
                coach_id
                first_name
                last_name
                email
                description
                coach_price
            }
            ... on Error {
                message
                code
            }
        }
    `;
  const inputs = `$coach_id: String!`;
  const variables = { coach_id: coach_id };
  return {
    query,
    inputs,
    variables,
  };
};

export const makeCoachAvailableQueryFn = (
  availabilityToUpdate: ICoachAvailabilityInput
) => {
  const query = `
        makeCoachAvailable(input: $input) {
            ... on CoachAvailability {
                coach_id
                date
                intervals {
                    start_time
                    end_time
                    price
                }
            }
            ... on Error {
                message
                code
            }
        }
    `;
  const inputs = `$input: CoachAvailabilityInput!`;
  const variables = { input: availabilityToUpdate };
  return {
    query,
    inputs,
    variables,
  };
};

export const getCoachAvailibilityWeeklyQueryFn = (
  coach_id: string,
  date: string
) => {
  const dateObject = new Date(date);
  const dayOfWeek = dateObject.getDay();
  const adjustedDate = new Date(dateObject);
  if (dayOfWeek !== 1) {
    adjustedDate.setDate(
      dateObject.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
    );
  }

  const beginningOfWeek = formatDate(adjustedDate, "yyyy-MM-dd");
  const query = `
        getCoachAvailabilityWeekly(coach_id: $coach_id, date: $date) {
            ... on CoachAvailability {
                __typename
                coach_id
                date
                intervals {
                    start_time
                    end_time
                    price
                    booking {
                      booking_id
	                    datetime_start
                      member_id
                      member_status
                      member_name
                      court_id
                      co_players {
                        member_id
                        status
                      }
                      guests {
                        member_id
                        status
                      }
                      coach_id
                      booking_type
                      status
                      created_at
                      created_by
                      updated_at
                      updated_by
                      final_price {
                        event
                        price
                      }
                      slot_index
                    }
                }
            }
            ... on Error {
                code
                message
            }
        }
    `;
  const inputs = `$coach_id: String!, $date: String!`;
  const variables = { coach_id: coach_id, date: beginningOfWeek };
  return {
    query,
    inputs,
    variables,
  };
};

export const listCoachesAvailabilityQueryFn = (date: string) => {
  const dateString = date.slice(0, 10);
  const query = `
        listCoachesAvailability(date: $date) {
             ... on CoachAvailability {
            __typename
            coach_id
            date
            intervals {
                activity
                end_time
                booking {
                booking_id
                booking_type
                co_players {
                    member_id
                    status
                }
                coach_id
                court_id
                created_at
                created_by
                final_price {
                    event
                    price
                }
                datetime_start
                guests {
                    member_id
                    status
                }
                member_id
                member_name
                member_status
                slot_index
                status
                updated_at
                updated_by
                }
                price
                start_time
            }
            }
            ... on Error {
            code
            message
            }
        }
    `;
  const inputs = `$date: String!`;
  const variables = { date: dateString };
  return {
    inputs,
    variables,
    query,
  };
};

export const updateCoachPriceQueryFn = (coach_id: string, price: number) => {
  const query = `
    updateCoachPrice(coach_id: $coach_id, price: $price) {
      ... on Coach {
        coach_id
        coach_price
      }
      ... on Error {
        message
        code
      }
    }
  `;
  const inputs = `$coach_id: String!, $price: Int!`;
  const variables = { coach_id, price };
  return {
    query,
    inputs,
    variables,
  };
};
