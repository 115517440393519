import {
  ISeasonalHoursFacility,
  IWeekdayHoursFacility,
  ISpecialHoursFacility,
} from "../../types/facility";

// export const getFacilityOpeningTimesQueryFn = () => {
//   const query = `getFacility {
//     ... on Facility {
//       __typename
//       default_hours {
//         opening_hours
//         closing_hours
//         weekday
//       }
//       facilityID
//       seasonal_hours {
//         alias
//         end_date
//         start_date
//         weekday_hours {
//           closing_hours
//           weekday
//           opening_hours
//         }
//       }
//       special_days {
//         alias
//         closing_hours
//         date
//         opening_hours
//       }
//     }
//     ... on Error {
//       code
//       message
//     }
//   }`;

//   return {
//     query,
//   };
// };

export const getFacilityOpeningHoursQueryFn = (court_type: string) => {
  const query = `getFacilityOpeningHours(court_type: $court_type) {
        ... on FacilityOpeningHours {
            default_hours {
                weekday
                opening_hours
                closing_hours
            }
            seasonal_hours {
                start_date
                end_date
                alias
                closed
                weekday_hours {
                    weekday
                    opening_hours
                    closing_hours
              
                }
            }
            special_days {
                date
                opening_hours
                closing_hours
                alias
                closed
            }
        }
        ... on Error {
            message
            code
        }
    }`;
  const inputs = `$court_type: String!`;
  const variables = { court_type };
  return {
    query,
    inputs,
    variables,
  };
};

export const createDefaultHoursQueryFn = (input: IWeekdayHoursFacility) => {
  const closing_hours = input.closing_hours;
  const court_type = input.court_type;
  const opening_hours = input.opening_hours;
  const weekday = input.weekday;
  const query = `
    createDefaultHours(input: {closing_hours: $closing_hours, court_type: $court_type, opening_hours: $opening_hours, weekday: $weekday}) {
      ... on OpeningHoursConfirmation {
        __typename
        message
      }
      ... on Error {
        code
        message
      }
    }
  `;
  const inputs = `$closing_hours: String!, $court_type: String!, $opening_hours: String!, $weekday: String!`;
  const variables = { closing_hours, court_type, opening_hours, weekday };
  return {
    query,
    inputs,
    variables,
  };
};

export const updateDefaultHoursQueryFn = (input: IWeekdayHoursFacility) => {
  const closing_hours = input.closing_hours;
  const court_type = input.court_type;
  const opening_hours = input.opening_hours;
  const weekday = input.weekday;
  const query = `
    updateDefaultHours(input: {closing_hours: $closing_hours, court_type: $court_type, opening_hours: $opening_hours, weekday: $weekday}) {
      ... on OpeningHoursConfirmation {
        __typename
        message
      }
      ... on Error {
        code
        message
      }
    }
  `;
  const inputs = `$closing_hours: String!, $court_type: String!, $opening_hours: String!, $weekday: String!`;
  const variables = { closing_hours, court_type, opening_hours, weekday };
  return {
    query,
    inputs,
    variables,
  };
};

export const createSeasonalHoursQueryFn = (input: ISeasonalHoursFacility) => {
  const alias = input.alias;
  const closed = input.closed;
  const court_type = input.court_type;
  const end_date = input.end_date;
  const start_date = input.start_date;
  const weekday_hours = input.weekday_hours;

  const query = `
    createSeasonalHours(input: {alias: $alias, closed: $closed, court_type: $court_type, end_date: $end_date, start_date: $start_date, weekday_hours: $weekday_hours}) {
      ... on OpeningHoursConfirmation {
        __typename
        message
      }
      ... on Error {
        code
        message
      }
  }
  `;
  const inputs = `
  $alias: String!
  $court_type: String!
  $end_date: String!
  $start_date: String!
  $weekday_hours: [DefaultHoursTypeInput]!
  $closed: Boolean!
`;
  const variables = {
    alias,
    closed,
    court_type,
    end_date,
    start_date,
    weekday_hours,
  };
  return {
    query,
    inputs,
    variables,
  };
};

export const updateSeasonalHoursQueryFn = (input: ISeasonalHoursFacility) => {
  const alias = input.alias;
  const closed = input.closed;
  const court_type = input.court_type;
  const end_date = input.end_date;
  const start_date = input.start_date;
  const weekday_hours = input.weekday_hours;

  const query = `
    updateSeasonalHours(input: {alias: $alias, closed: $closed, court_type: $court_type, end_date: $end_date, start_date: $start_date, weekday_hours: $weekday_hours}) {
      ... on OpeningHoursConfirmation {
        __typename
        message
      }
      ... on Error {
        code
        message
      }
  }
  `;
  const inputs = `
  $alias: String!
  $court_type: String!
  $end_date: String!
  $start_date: String!
  $weekday_hours: [DefaultHoursTypeInput]!
  $closed: Boolean!
`;
  const variables = {
    alias,
    closed,
    court_type,
    end_date,
    start_date,
    weekday_hours,
  };
  return {
    query,
    inputs,
    variables,
  };
};

export const createSpecialHoursQueryFn = (input: ISpecialHoursFacility) => {
  const alias = input.alias;
  const closed = input.closed;
  const closing_hours = input.closing_hours;
  const date = input.date.split("T")[0];
  const opening_hours = input.opening_hours;
  const court_type = input.court_type;
  const query = `
    createSpecialHours(input: {alias: $alias, closed: $closed, closing_hours: $closing_hours, date: $date, opening_hours: $opening_hours, court_type: $court_type}) {
      ... on OpeningHoursConfirmation {
        __typename
        message
      }
      ... on Error {
        code
        message
      }
    }
  `;
  const inputs = `
  $alias: String!
  $closed: Boolean!
  $closing_hours: String!
  $date: String!
  $opening_hours: String!
  $court_type: String!
`;
  const variables = {
    alias,
    closed,
    closing_hours,
    date,
    opening_hours,
    court_type,
  };
  return {
    query,
    inputs,
    variables,
  };
};


export const updateSpecialHoursQueryFn = (input: ISpecialHoursFacility) => {
  const alias = input.alias;
  const closed = input.closed;
  const closing_hours = input.closing_hours;
  const date = input.date.split("T")[0];
  const opening_hours = input.opening_hours;
  const court_type = input.court_type;
  const query = `
    updateSpecialHours(input: {alias: $alias, closed: $closed, closing_hours: $closing_hours, date: $date, opening_hours: $opening_hours, court_type: $court_type}) {
      ... on OpeningHoursConfirmation {
        __typename
        message
      }
      ... on Error {
        code
        message
      }
    }
  `;
  const inputs = `
  $alias: String!
  $closed: Boolean!
  $closing_hours: String!
  $date: String!
  $opening_hours: String!
  $court_type: String!
`;
  const variables = {
    alias,
    closed,
    closing_hours,
    date,
    opening_hours,
    court_type,
  };
  return {
    query,
    inputs,
    variables,
  };
}


export const deleteSeasonalHoursQueryFn = (alias: string, court_type:string) => {
  const query = `
    deleteSeasonalHours(alias: $alias, court_type: $court_type) {
      ... on OpeningHoursConfirmation {
        __typename
        message
      }
      ... on Error {
        code
        message
      }
    }
  `;
  const inputs = `$alias: String!, $court_type: String!`;
  const variables = { alias, court_type };
  return {
    query,
    inputs,
    variables,
  };
}

export const deleteSpecialHoursQueryFn = (alias: string, court_type:string) => {
  const query = `
    deleteSpecialHours(alias: $alias, court_type: $court_type) {
      ... on OpeningHoursConfirmation {
        __typename
        message
      }
      ... on Error {
        code
        message
      }
    }
  `;
  const inputs = `$alias: String!, $court_type: String!`;
  const variables = { alias, court_type };
  return {
    query,
    inputs,
    variables,
  };
}