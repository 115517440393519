import "./EditCourtPopup.scss";
import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { ICourt, ICourtToEdit, IPrices } from "../../../types/api";
import { toast } from "react-toastify";
import TimeInputBatch from "../../../hoc/TimeBatch/TimeBatch";
import { v4 as uuidv4 } from "uuid";
import useTimeBatches from "../../../shared/hooks/useTimeBatches";
import { deleteCourt, editCourt } from "../../../api/courts/courts";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";

interface IEditCourtPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  court?: ICourt;
  selectedDay?: string;
  prices?: IPrices[];
}

function EditCourtPopup(props: IEditCourtPopupProps) {
  const { court, selectedDay, prices, showPopup, onClose, onConfirm } = props;
  const [showHandleDeleteCourtPopup, setShowHandleDeleteCourtPopup] =
    useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showCancelConfirmationPopup, setShowCancelConfirmationPopup] =
    useState(false);
  const weekdays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const handleClose = () => {
    onClose();
    setShowCancelConfirmationPopup(false);
  };
  const setupIntervalsFromCourt = (court: ICourt) => {
    const batchMap = new Map();
    court.availability.forEach((availability) => {
      availability.interval.forEach((interval) => {
        const key = `${interval.start_time}-${interval.end_time}-${interval.price}`;
        if (batchMap.has(key)) {
          const existingBatch = batchMap.get(key);
          existingBatch.checkedDays[availability.weekday] = true;
        } else {
          batchMap.set(key, {
            id: uuidv4(),
            startTime: interval.start_time,
            endTime: interval.end_time,
            checkedDays: {
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
              sunday: false,
              [availability.weekday]: true,
            },
            defaultChecked: false,
            isException: false,
            priceChoice: interval.price,
          });
        }
      });
    });

    return Array.from(batchMap.values());
  };

  const [initialBatches, setInitialBatches] = useState<any>([]);

  const {
    timeBatches: intervals,
    addTimeBatch: addNewBatch,
    removeTimeBatch: removeBatch,
    setBatches: setBatches,
    updateStartTime,
    updateEndTime,
    updateCheckedDays,
    updatePriceChoice,
  } = useTimeBatches(initialBatches);

  useEffect(() => {
    if (court) {
      const batches = setupIntervalsFromCourt(court);
      setInitialBatches(batches);
      setBatches(batches);
    }
  }, [court]);

  if (!court) return null;

  const doIntervalsOverlap = (court: ICourtToEdit) => {
    const dayWiseIntervals: {
      [key: string]: { startTime: string; endTime: string }[];
    } = {};

    court.input_data.availability.forEach((availability) => {
      availability.interval.forEach((interval) => {
        availability.weekday.split(",").forEach((day) => {
          if (!dayWiseIntervals[day]) {
            dayWiseIntervals[day] = [];
          }
          dayWiseIntervals[day].push({
            startTime: interval.start_time,
            endTime: interval.end_time,
          });
        });
      });
    });

    for (const day in dayWiseIntervals) {
      const intervals = dayWiseIntervals[day];
      const sortedIntervals = intervals.sort((a, b) =>
        a.startTime.localeCompare(b.startTime)
      );

      for (let i = 0; i < sortedIntervals.length - 1; i++) {
        if (sortedIntervals[i].endTime > sortedIntervals[i + 1].startTime) {
          return true;
        }
      }
    }

    return false;
  };

  const handleDeleteCourt = async () => {
    try {
      const response = await deleteCourt(court.court_id);
      onClose();
      onConfirm();
    } catch (error: any) {
      toast.error(`Error deleting court : ${error}`);
    }
  };
  const handleEditCourt = async () => {
    const courtToEdit = {
      court_id: court.court_id,
      input_data: {
        court_id: court.court_id,
        name: court.name,
        court_type: court.court_type,
        court_api: court.court_api,
        bookable_for_lessons: court.bookable_for_lessons,
        maintanance: court.maintanance,
        availability: weekdays
          .map((weekday, index) => {
            const dayIntervals = intervals
              .filter((batch) => batch.checkedDays[weekdays[index]])
              .map((batch) => ({
                start_time: batch.startTime,
                end_time: batch.endTime,
                price: batch.priceChoice || "blue",
              }));
            return {
              interval: dayIntervals,
              weekday: weekday,
            };
          })
          .filter((day) => day.interval.length > 0),
      },
    };

    try {
      if (doIntervalsOverlap(courtToEdit)) {
        toast.error("Intervals overlap");
        return;
      }
      const editCourtResponse = await editCourt(courtToEdit);
      setShowConfirmationPopup(false);
      handleClose();
      onConfirm();
    } catch (error: any) {
      toast.error(`Error editing court : ${error}`);
    }
  };

  if (!prices) return null;
  return (
    <Popup
      showPopup={showPopup}
      onClose={() => setShowCancelConfirmationPopup(true)}
      leftTopElement={
        <span className="text-m">Redigera bana {court.name}</span>
      }
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            setShowConfirmationPopup(true);
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="memberType" className="text-s">
                Boka tid {court.court_type === "INDOOR" ? "inomhus" : "utomhus"}
              </label>
              {intervals.map((batch, index) => (
                <TimeInputBatch
                  key={batch.id}
                  id={batch.id}
                  startTime={batch.startTime || "07:00:00"}
                  endTime={batch.endTime || "23:00:00"}
                  checkedDays={batch.checkedDays}
                  defaultChecked={false}
                  isLast={index === intervals.length - 1}
                  isFirst={index === 0}
                  isException={false}
                  onAdd={() =>
                    addNewBatch(
                      false,
                      false,
                      new Date(),
                      new Date(),
                      prices,
                      "blue"
                    )
                  }
                  onRemove={() => removeBatch(batch.id)}
                  onStartTimeChange={(newStartTime) =>
                    updateStartTime(batch.id, newStartTime)
                  }
                  onEndTimeChange={(newEndTime) =>
                    updateEndTime(batch.id, newEndTime)
                  }
                  onDayChange={(dayString) => {
                    updateCheckedDays(batch.id, dayString);
                  }}
                  priceChoices={prices}
                  priceChoice={batch.priceChoice}
                  onPriceChoiceChange={(newPriceChoice) =>
                    updatePriceChoice(batch.id, newPriceChoice)
                  }
                />
              ))}
            </div>
          </div>
          <div className="general-popup-bottom-buttons-wrapper ">
            <button
              className="deleteButton"
              type="button"
              onClick={() => setShowHandleDeleteCourtPopup(true)}
            >
              Radera bana permanent
            </button>
            <button type="submit" className="text-m">
              Uppdatera uppgifter
            </button>
          </div>
        </form>
      </div>
      <HandleConfirmationPopup
        showPopup={showHandleDeleteCourtPopup}
        onClose={() => setShowHandleDeleteCourtPopup(false)}
        onConfirm={() => handleDeleteCourt()}
        questionText={`Är du säker på att du vill radera ${court.name}?`}
        confirmText="Radera bana"
        denyText="Nej"
      />
      <HandleConfirmationPopup
        showPopup={showCancelConfirmationPopup}
        onClose={() => setShowCancelConfirmationPopup(false)}
        onConfirm={() => handleClose()}
        questionText="Är du säker på att du vill avbryta?"
        confirmText="Ja"
        denyText="Nej"
      />
      <HandleConfirmationPopup
        showPopup={showConfirmationPopup}
        onClose={() => setShowConfirmationPopup(false)}
        onConfirm={handleEditCourt}
        questionText="Att uppdatera en bana kommer att påverka alla bokningar som redan är gjorda. Är du säker på att du vill fortsätta?"
        confirmText="Ja"
        denyText="Nej"
      />
    </Popup>
  );
}

export default EditCourtPopup;
