import { StatusEnum } from "../../shared/enums";
import { IBooking, IConfirmBookingSpec } from "../../types/api";

export const getActiveBookingsAsCoplayerQueryFn = (member_id: string) => {
  const query = `
    getActiveBookingsAsCoPlayer(member_id: $member_id) {
      ... on CoPlayerBooking {
        date 
        member_id 
        booker 
        status
        court_id
        booker_name
      }
      ... on Error {
        code
        message
      }
    }
`;

  const inputs = `$member_id: String!`;
  const variables = { member_id: member_id };
  return {
    query,
    inputs,
    variables,
  };
};

export const getActiveBookingsByMemberQueryFn = (member_id: string) => {
  const query = `
    getActiveBookingsByMember(member_id: $member_id) {
      ... on Booking {
        court_id
        booking_id
        booking_type
        coach_id
        created_at
        created_by
        datetime_start
        final_price{
            event
            price
        }
        guests {
          member_id
          status
        }
        member_id
        member_status
        co_players {
          member_id
          status
        }
        member_name
        slot_index
        status
        comment
      }
      ... on Error {
        code
        message
      }
    }
`;
  const inputs = `$member_id: String!`;
  const variables = { member_id: member_id };
  return {
    query,
    inputs,
    variables,
  };
};

export const AdminCheckInBookingQueryFn = (
  booking_id: string,
  date: string,
  member_id: string,
  type: string,
  status: StatusEnum
) => {
  const query = `
    AdminCheckInBooking(booking_id: $booking_id, date: $date, member_id: $member_id, type: $type, status: $status) {
          ... on Booking {
            court_id
            updated_at
            booking_id
            booking_type
            co_players {
              member_id
              status
            }
            coach_id
            created_at
            created_by
            datetime_start
            final_price {
              event
              price
            }
            guests {
              member_id
              status
            }
            member_id
            member_name
            member_status
            slot_index
            status
            updated_by
            comment
          }
          ... on Error {
            code
            message
          }
        }
      `;
  const inputs = `$booking_id: String!, $date: String!, $member_id: String!, $type: String!, $status: StatusEnum!`;
  const variables = {
    booking_id: booking_id,
    date: date,
    member_id: member_id,
    type: type,
    status: status,
  };
  return {
    inputs,
    variables,
    query,
  };
};

export const AdminReportNoShowQueryFn = (booking_id: string, date: string) => {
  const query = `
        AdminReportNoshow(booking_id: $booking_id, date: $date) {
          ... on Booking {
            court_id
            updated_at
            booking_id
            booking_type
            co_players {
              member_id
              status
            }
            coach_id
            created_at
            created_by
            datetime_start
            final_price {
              event
              price
            }
            guests {
              member_id
              status
            }
            member_id
            member_name
            member_status
            slot_index
            status
            updated_by
            comment
          }
          ... on Error {
            code
            message
          }
        }
      `;
  const inputs = `$booking_id: String!, $date: String!`;
  const variables = {
    booking_id: booking_id,
    date: date,
  };
  return {
    inputs,
    variables,
    query,
  };
};

export const getBookingsByMemberQueryFn = (member_id: string) => {
  const query = `
    getBookingsByMember(member_id: $member_id){
    ... on Booking {
      court_id
      updated_at
      updated_by
      status
      member_status
      slot_index
      member_name
      member_id
      guests {
        member_id
        status
      }
      final_price {
        event
        price
      }
      created_by
      created_at
      coach_id
      booking_type
      booking_id
      co_players {
        member_id
        status
      }
      datetime_start
      comment
    }
    ... on Error {
      code
      message
    }
  }
`;
  const inputs = `$member_id: String!`;
  const variables = { member_id: member_id };
  return {
    query,
    inputs,
    variables,
  };
};

export const getCoPlayerBookingsByMemberQueryFn = (member_id: string) => {
  const query = `
    getCoPlayerBookingsByMember(member_id: $member_id){
      ... on CoPlayerBooking {
        date 
        member_id 
        booker 
        status
        court_id
        booker_name
      }
      ... on Error {
        code
        message
      }
  }
`;
  const inputs = `$member_id: String!`;
  const variables = { member_id: member_id };
  return {
    query,
    inputs,
    variables,
  };
};

export const updateBookingQueryFn = (
  input: IBooking,
  removed_players: string[],
  added_players: string[],
  requestor: "admin" | "user",
  removed_coach_id?: string,
  added_coach_id?: string
) => {
  let admin = false;
  if (requestor === "admin") {
    admin = true;
  }
  const query = `
    updateBooking(input: $input, removed_players: $removed_players, added_players: $added_players, removed_coach_id: $removed_coach_id, added_coach_id: $added_coach_id, admin: $admin) {
      ... on Booking {
        court_id
        updated_at
        updated_by
        status
        member_status
        slot_index
        member_name
        member_id
        guests {
          member_id
          status
        }
        final_price {
          event
          price
        }
        created_by
        created_at
        coach_id
        booking_type
        booking_id
        co_players {
          member_id
          status
        }
        datetime_start
        comment
      }
      ... on Error {
        code
        message
      }
    }
  `;
  const inputs = `$input: UpdateBookingInput!, $removed_players: [String!], $added_players: [String!], $removed_coach_id: String!, $added_coach_id: String!, $admin: Boolean!`;
  const variables = {
    input: input,
    removed_players: removed_players,
    added_players: added_players,
    removed_coach_id: removed_coach_id,
    added_coach_id: added_coach_id,
    admin: admin,
  };
  return {
    query,
    inputs,
    variables,
  };
};

export const updateBookingCommentQueryFn = (
  comment: string,
  court_id: string,
  date: string,
  slot_index: number
) => {
  const query = `
    updateBookingComment(comment: $comment, court_id: $court_id, date: $date, slot_index: $slot_index) {
      ... on Booking {
        court_id
        updated_at
        updated_by
        status
        member_status
        slot_index
        member_name
        member_id
        guests {
          member_id
          status
        }
        final_price {
          event
          price
        }
        created_by
        created_at
        coach_id
        booking_type
        booking_id
        co_players {
          member_id
          status
        }
        datetime_start
        comment
      }
      ... on Error {  
        code
        message
      }
}
        `;
  const inputs = `$comment: String!, $court_id: String!, $date: String!, $slot_index: Int!`;
  const variables = {
    comment: comment,
    court_id: court_id,
    date: date,
    slot_index: slot_index,
  };
  return {
    query,
    inputs,
    variables,
  };
};

export const moveBookingQueryFn = (
  new_booking: IConfirmBookingSpec,
  old_datetime_start: string,
  old_court_id: string
) => {
  const query = `
    moveBooking(new_booking: $new_booking, old_datetime_start: $old_datetime_start, old_court_id: $old_court_id) {
      ... on Booking {
        court_id
        updated_at
        updated_by
        status
        member_status
        slot_index
        member_name
        member_id
        guests {
          member_id
          status
        }
        final_price {
          event
          price
        }
        created_by
        created_at
        coach_id
        booking_type
        booking_id
        co_players {
          member_id
          status
        }
        datetime_start
        comment
      }
      ... on Error {
        code
        message
      }
    }
  `;
  const inputs = `$new_booking: ConfirmBooking!, $old_datetime_start: String!, $old_court_id: String!`;
  const variables = {
    new_booking: new_booking,
    old_datetime_start: old_datetime_start,
    old_court_id: old_court_id,
  };
  return {
    query,
    inputs,
    variables,
  };
};


