import Popup from "../../../hoc/Popup/Popup";
import {
  capitalizeWords,
  formatDateToSwedish,
  formatPersonalNumber,
  formatPhoneNumber,
} from "../../../shared/utility";
import {
  IBooking,
  ICancelBookingInputType,
  ICoach,
  ICourt,
  ICreateGuest,
  IEventPrice,
  IGuest,
  IPermission,
  IPrices,
  ISummaryItem,
  IUser,
} from "../../../types/api";
import "./AdminHandleBookingPopup.scss";
import { useEffect, useState } from "react";
import { getUserByMemberId } from "../../../api/users/users";
import {
  AdminCheckInBooking,
  AdminReportNoShow,
  cancelBooking,
  updateBooking,
  updateBookingComment,
} from "../../../api/bookings/bookings";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import { Id, toast } from "react-toastify";
import {
  createGuest,
  getGuestByMemberId,
  searchGuests,
} from "../../../api/guests/guests";
import {
  emailRegex,
  firstNameRegex,
  lastNameRegex,
  phoneRegex,
  socialSecurityRegex,
} from "../../../shared/regex";
import EditCoPlayersPopup from "../../Bookings/EditCoPlayersPopup/EditCoPlayersPopup";
import { BookingEnum, StatusEnum } from "../../../shared/enums";
import { getCoachByID } from "../../../api/coaches/coaches";
import { formatDate } from "../../../shared/dateUtils";

interface IAdminHandleBookingPopupProps {
  onClose: () => void;
  showPopup: boolean;
  slot: ISummaryItem;
  courts: ICourt[];
  onConfirm: () => void;
  coaches: ICoach[];
  isSemiNoShow?: boolean;
  filterDate: Date;
  onRefetchComment: () => void;
  prices: IPrices[];
  permissions: IPermission[];
}

const statusTexts: Partial<{ [key in StatusEnum]: string }> = {
  [StatusEnum.CHECKED_IN]: "Markerar som incheckad...",
  [StatusEnum.CONFIRMED]: "Markerar som icke incheckad...",
  [StatusEnum.PAID]: "Markerar som betald...",
};

function AdminHandleBookingPopup(props: IAdminHandleBookingPopupProps) {
  const { slot, showPopup } = props;
  const [cancelDueToRain, setCancelDueToRain] = useState<boolean>(false);
  const [addGuestData, setAddGuestData] = useState<ICreateGuest>({
    first_name: "",
    last_name: "",
    social_security_number: "",
    phone_number: "",
    email: "",
  });

  const [comment, setComment] = useState<string>("");
  const [showEditCoPlayersPopup, setShowEditCoPlayersPopup] =
    useState<boolean>(false);
  const [showMarkAsNoShowPopup, setShowMarkAsNoShowPopup] =
    useState<boolean>(false);
  const [showDeleteBookingPopup, setShowDeleteBookingPopup] =
    useState<boolean>(false);
  const [mainBookerData, setMainBookerData] = useState<IUser>();
  const [coPlayersData, setCoPlayersData] = useState<IUser[]>();
  const [guestsData, setGuestsData] = useState<IGuest[]>();
  const [coachData, setCoachData] = useState<ICoach>();
  const [changedGuestFlag, setChangedGuestFlag] = useState<boolean>(false);
  const [checkedInBookingCompare, setCheckedInBookingCompare] =
    useState<IBooking>();

  const [loading, setLoading] = useState<boolean>(false);
  const [guestSelection, setGuestSelection] = useState<"existing" | "new">(
    "existing"
  );
  const [editCommentToggle, setEditCommentToggle] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");
  const [searchedGuests, setSearchedGuests] = useState<IGuest[]>([]);

  const handleClose = () => {
    setCheckedInBookingCompare(undefined);
    setShowMarkAsNoShowPopup(false);
    setEditCommentToggle(false);
    setGuestsData([]);
    setSearchString("");
    props.onClose();
  };
  const handleStatusChange = async (
    member_id: string,
    type: "guest" | "co_player" | "member",
    status: StatusEnum,
    statusText: string
  ) => {
    if (!member_id) return;
    const booking_id = slot.booking[0].booking_id;
    const date = booking.datetime_start;

    let loadingToast = toast.loading(statusText);
    setLoading(true);
    try {
      const response = await AdminCheckInBooking(
        booking_id,
        date,
        member_id,
        type,
        status
      );
      if (response && "message" in response === false) {
        toast.success("Status uppdaterad");
        setCheckedInBookingCompare(response);
        if (type === "guest") {
          setChangedGuestFlag(!changedGuestFlag);
        }
        props.onConfirm();
        toast.dismiss(loadingToast);
        setLoading(false);
      }
    } catch (error) {
      toast.dismiss(loadingToast);
      setLoading(false);
    }
  };

  const handleCreateGuest = async () => {
    if (
      addGuestData.first_name.length < 2 ||
      addGuestData.last_name.length < 2 ||
      addGuestData.social_security_number.length < 11
    ) {
      toast.error("Fyll i alla fält korrekt");
      return;
    }
    try {
      const response = await createGuest(addGuestData);
      if (response && "message" in response === false) {
        toast.success("Gäst skapad");
        setAddGuestData({
          first_name: "",
          last_name: "",
          social_security_number: "",
          phone_number: "",
          email: "",
        });
        handleStatusChange(
          response.member_id,
          "guest",
          StatusEnum.CHECKED_IN,
          statusTexts[StatusEnum.CHECKED_IN] as string
        );
      }
    } catch (error: any) {
      toast.error(error);
    }
  };
  const handleMarkAsNoShow = async () => {
    setLoading(true);
    const booking_id = slot.booking[0].booking_id;
    const date = slot.booking[0].datetime_start;
    try {
      const response = await AdminReportNoShow(booking_id, date);
      if (response && "message" in response === false) {
        setCheckedInBookingCompare(response);
        props.onConfirm();
        handleClose();
      }
    } catch (error: any) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleCommentBooking = async () => {
    try {
      const response = await updateBookingComment(
        comment,
        bookingToLoop.court_id,
        formatDate(props.filterDate, "yyyy-MM-dd"),
        bookingToLoop.slot_index
      );
      if (response && "message" in response === false) {
        props.onRefetchComment();
        setCheckedInBookingCompare(response);
        setEditCommentToggle(false);
        toast.success("Kommentar sparad");
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  const handleAddGuestKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const handleGuestInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key:
      | "first_name"
      | "last_name"
      | "social_security_number"
      | "phone_number"
      | "email"
  ) => {
    let value = e.target.value;
    if (key === "social_security_number") {
      value = formatPersonalNumber(value);
    }
    if (key === "phone_number") {
      value = formatPhoneNumber(value);
    }
    setAddGuestData({ ...addGuestData, [key]: value });
  };

  const handleToggleGuestSelection = (
    e: any,
    selection: "existing" | "new"
  ) => {
    e.preventDefault();
    setGuestSelection(selection);
  };

  useEffect(() => {
    if (showPopup) {
      handleFetchGuestsData();
    }
  }, [changedGuestFlag]);

  const handleCancelBooking = async (booking: IBooking) => {
    const coachToCancle = booking.coach_id.length > 0 ? booking.coach_id : [];
    const bookingToCancel: ICancelBookingInputType = {
      court_id: booking.court_id,
      datetime_start: booking.datetime_start,
      booking_id: booking.booking_id,
      slot_index: booking.slot_index,
      coach_id: coachToCancle,
    };
    let loadToast = "" as Id;
    try {
      loadToast = toast.loading("Avbokar bokning...");
      const cancelBookingResponse = await cancelBooking(
        bookingToCancel,
        "admin",
        false,
        cancelDueToRain
      );
      if (
        cancelBookingResponse &&
        "message" in cancelBookingResponse === false
      ) {
        toast.success("Bokning avbokad");
        toast.dismiss(loadToast);
        props.onConfirm();
        handleClose();
        setShowDeleteBookingPopup(false);
      }
      toast.dismiss(loadToast);
    } catch (error: any) {
      toast.dismiss(loadToast);
      toast.error("Error cancelling booking" + error);
      setShowDeleteBookingPopup(false);
    }
  };
  const handleFetchMainBookerData = async () => {
    const mainBooker = slot.booking[0].member_id;
    const response = await getUserByMemberId(mainBooker);
    setMainBookerData(response);
  };
  const handleFetchGuestsData = async () => {
    const guestList: IGuest[] = [];

    const guests = bookingToLoop.guests;

    const response = await Promise.all(
      guests.map(async (guest) => {
        if (guest.member_id !== "guest") {
          return await getGuestByMemberId(guest.member_id);
        }
      })
    );
    response.forEach((res) => {
      if (res) {
        guestList.push(res);
      }
    });
    setGuestsData(guestList);
  };

  const handleFetchCoPlayersData = async () => {
    const coPlayers = slot.booking[0].co_players;
    const response = await Promise.all(
      coPlayers.map(async (coPlayer) => {
        return await getUserByMemberId(coPlayer.member_id);
      })
    );
    setCoPlayersData(response);
  };

  const handleFetchCoachData = async () => {
    const coach = slot.booking[0].coach_id[0];
    const response = await getCoachByID(coach);

    setCoachData(response);
  };

  const fetchSearchGuests = async (search: string) => {
    try {
      const response = await searchGuests(search);
      if (response.length === 0) {
        toast.warning("Inga användare hittades");
      }
      setSearchedGuests(response);
    } catch (error: any) {
      toast.error(error);
    }
  };
  useEffect(() => {
    if (searchString.length < 3) {
      setSearchedGuests([]);
      return;
    }
    handleSearchGuests();
  }, [searchString]);

  const handleSearchGuests = () => {
    fetchSearchGuests(searchString);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearchGuests();
    }
  };
  useEffect(() => {
    if (showPopup === false) {
      setCheckedInBookingCompare(undefined);
    }
    if (showPopup === true) {
      if (
        booking &&
        booking.booking_id !== checkedInBookingCompare?.booking_id
      ) {
        setComment(booking.comment ? booking.comment : "");
        setCheckedInBookingCompare(booking);
      }
    }
    if (slot) {
      handleFetchMainBookerData();
      handleFetchCoPlayersData();
      handleFetchGuestsData();
      if (slot.booking[0].coach_id.length > 0) {
        handleFetchCoachData();
      }
    }
  }, [showPopup]);

  if (!slot || !slot.booking || slot.booking.length === 0) {
    return null;
  }
  const booking = slot.booking[0] as IBooking;
  const startDate = new Date(booking?.datetime_start || "");
  const { weekday, dateAndMonth, hour } = formatDateToSwedish(
    new Date(startDate)
  );

  function accumulatePrices(finalPrices: IEventPrice[]): string {
    const total = finalPrices.reduce((sum, item) => sum + item.price, 0);
    return total.toString();
  }
  const dateString = `${weekday} ${dateAndMonth}`;
  const courtName = props.courts.find(
    (c) => c.court_id === booking?.court_id
  )?.name;
  const timeCourtString = `${slot.start_time.slice(
    0,
    5
  )} - ${slot.end_time.slice(0, 5)} ${courtName}`;
  const userString = `${
    capitalizeWords(mainBookerData?.first_name || "Loading") +
    " " +
    capitalizeWords(mainBookerData?.last_name || "Loading") +
    " (" +
    mainBookerData?.member_id +
    ")"
  }`;
  const bookingToLoop = checkedInBookingCompare || booking;
  const coachPrize =
    bookingToLoop.coach_id.length > 0
      ? bookingToLoop.final_price.find((event) =>
          event.event.includes("coach_price")
        )?.price
      : 0;

  const courtType = props.courts.find(
    (court) => court.court_id === booking.court_id
  )?.court_type;
  return (
    <Popup
      onClose={handleClose}
      showPopup={props.showPopup}
      leftTopElement={
        <span className="text-m">
          Hantera bokning{" "}
          {booking?.booking_type === BookingEnum.DROP_IN && "(Drop in)"}
        </span>
      }
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form less-gap"
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateGuest();
          }}
        >
          <div className="general-popup-form-group mb-20">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Datum</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>{dateString}</span>
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m primary-text ">Tid + Bana</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>{timeCourtString}</span>
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Huvudbokare</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span> {userString}</span>
                  <div className={`checkin-text-wrapper`}>
                    {(() => {
                      if (mainBookerData) {
                        const memberStatus = bookingToLoop.member_status;
                        const priceForMainBooker =
                          bookingToLoop.final_price.find((event) =>
                            event.event.includes(mainBookerData.member_id)
                          );
                        const priceString = priceForMainBooker?.price || "0";
                        const hasPrice = priceString !== "0";

                        if (memberStatus === "CHECKED_IN" && hasPrice) {
                          return (
                            <>
                              <span className="price-for-member">
                                {priceString} kr
                              </span>
                              <span
                                className="checked-in pointer"
                                onClick={() => {
                                  if (loading) return;
                                  handleStatusChange(
                                    mainBookerData.member_id,
                                    "member",
                                    StatusEnum.CONFIRMED,
                                    statusTexts[StatusEnum.CONFIRMED] as string
                                  );
                                }}
                              >
                                Incheckad
                              </span>
                              <a
                                className="pointer"
                                onClick={() => {
                                  if (loading) return;
                                  handleStatusChange(
                                    mainBookerData.member_id,
                                    "member",
                                    StatusEnum.PAID,
                                    statusTexts[StatusEnum.PAID] as string
                                  );
                                }}
                              >
                                Betald
                              </a>
                            </>
                          );
                        } else if (memberStatus === "CHECKED_IN") {
                          return (
                            <>
                              <span className="price-for-member">
                                {priceString} kr
                              </span>
                              <span
                                className="checked-in pointer"
                                onClick={() => {
                                  if (loading) return;
                                  handleStatusChange(
                                    mainBookerData.member_id,
                                    "member",
                                    StatusEnum.CONFIRMED,
                                    statusTexts[StatusEnum.CONFIRMED] as string
                                  );
                                }}
                              >
                                Incheckad
                              </span>
                            </>
                          );
                        } else if (memberStatus === "PAID") {
                          return (
                            <>
                              <span className="price-for-member">
                                {priceString} kr
                              </span>
                              <span
                                className="checked-in pointer"
                                onClick={() => {
                                  if (loading) return;
                                  handleStatusChange(
                                    mainBookerData.member_id,
                                    "member",
                                    StatusEnum.CONFIRMED,
                                    statusTexts[StatusEnum.CONFIRMED] as string
                                  );
                                }}
                              >
                                Incheckad
                              </span>
                              {hasPrice && (
                                <span
                                  className="checked-in pointer"
                                  onClick={() => {
                                    if (loading) return;
                                    handleStatusChange(
                                      mainBookerData.member_id,
                                      "member",
                                      StatusEnum.CHECKED_IN,
                                      statusTexts[
                                        StatusEnum.CHECKED_IN
                                      ] as string
                                    );
                                  }}
                                >
                                  Betald
                                </span>
                              )}
                            </>
                          );
                        } else {
                          return (
                            <>
                              <span className="price-for-member">
                                {priceString} kr
                              </span>
                              <a
                                className="pointer"
                                onClick={() => {
                                  if (loading) return;
                                  handleStatusChange(
                                    mainBookerData.member_id,
                                    "member",
                                    StatusEnum.CHECKED_IN,
                                    statusTexts[StatusEnum.CHECKED_IN] as string
                                  );
                                }}
                              >
                                Checka in
                              </a>
                            </>
                          );
                        }
                      } else {
                        return null;
                      }
                    })()}
                  </div>
                </div>
              </div>
              <div className="general-popup-flex-between align-top">
                <span className="text-m primary-text">Medspelare</span>
                <span className="text-m primary-text text-svg-wrapper">
                  <div className="chosen-players">
                    {coPlayersData &&
                      coPlayersData.map((player, index) => {
                        const { first_name, last_name } = player;
                        const playerString = `${capitalizeWords(
                          first_name
                        )} ${capitalizeWords(last_name)} (${player.member_id})`;
                        const priceForPlayer = bookingToLoop.final_price.find(
                          (event) => {
                            return event.event.includes(player.member_id);
                          }
                        );
                        const priceString = priceForPlayer?.price || "0";
                        const hasPrice = priceString !== "0";
                        return (
                          <div key={index} className="chosen-player">
                            {playerString}
                            {(() => {
                              const coPlayer = bookingToLoop.co_players.find(
                                (coPlayer) =>
                                  coPlayer.member_id === player.member_id
                              );

                              if (coPlayer) {
                                if (
                                  coPlayer.status === "CHECKED_IN" &&
                                  hasPrice
                                ) {
                                  return (
                                    <>
                                      <span className="price-for-member">
                                        {priceString} kr
                                      </span>
                                      <span
                                        className="checked-in pointer"
                                        onClick={() => {
                                          if (loading) return;
                                          handleStatusChange(
                                            player.member_id,
                                            "co_player",
                                            StatusEnum.CONFIRMED,
                                            statusTexts[
                                              StatusEnum.CONFIRMED
                                            ] as string
                                          );
                                        }}
                                      >
                                        Incheckad
                                      </span>
                                      <a
                                        className="pointer"
                                        onClick={() => {
                                          if (loading) return;
                                          handleStatusChange(
                                            player.member_id,
                                            "co_player",
                                            StatusEnum.PAID,
                                            statusTexts[
                                              StatusEnum.PAID
                                            ] as string
                                          );
                                        }}
                                      >
                                        Betald
                                      </a>
                                    </>
                                  );
                                } else if (coPlayer.status === "CHECKED_IN") {
                                  return (
                                    <>
                                      <span className="price-for-member">
                                        {priceString} kr
                                      </span>
                                      <span
                                        className="checked-in pointer"
                                        onClick={() => {
                                          if (loading) return;
                                          handleStatusChange(
                                            player.member_id,
                                            "co_player",
                                            StatusEnum.CONFIRMED,
                                            statusTexts[
                                              StatusEnum.CONFIRMED
                                            ] as string
                                          );
                                        }}
                                      >
                                        Incheckad
                                      </span>
                                    </>
                                  );
                                } else if (coPlayer.status === "PAID") {
                                  return (
                                    <>
                                      <span className="price-for-member">
                                        {priceString} kr
                                      </span>
                                      <span
                                        className="checked-in pointer"
                                        onClick={() => {
                                          if (loading) return;
                                          handleStatusChange(
                                            player.member_id,
                                            "co_player",
                                            StatusEnum.CONFIRMED,
                                            statusTexts[
                                              StatusEnum.CONFIRMED
                                            ] as string
                                          );
                                        }}
                                      >
                                        Incheckad
                                      </span>
                                      <span
                                        className="checked-in pointer"
                                        onClick={() => {
                                          if (loading) return;
                                          handleStatusChange(
                                            player.member_id,
                                            "co_player",
                                            StatusEnum.CHECKED_IN,
                                            statusTexts[
                                              StatusEnum.CHECKED_IN
                                            ] as string
                                          );
                                        }}
                                      >
                                        Betald
                                      </span>
                                    </>
                                  );
                                } else if (
                                  coPlayer.status === "CONFIRMED" &&
                                  !hasPrice
                                ) {
                                  return (
                                    <>
                                      <span className="price-for-member">
                                        {priceString} kr
                                      </span>
                                      <a
                                        className="pointer"
                                        onClick={() => {
                                          if (loading) return;
                                          handleStatusChange(
                                            player.member_id,
                                            "co_player",
                                            StatusEnum.PAID,
                                            statusTexts[
                                              StatusEnum.PAID
                                            ] as string
                                          );
                                        }}
                                      >
                                        Checka in
                                      </a>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <span className="price-for-member">
                                        {priceString} kr
                                      </span>
                                      <a
                                        className="pointer"
                                        onClick={() => {
                                          if (loading) return;
                                          handleStatusChange(
                                            player.member_id,
                                            "co_player",
                                            StatusEnum.CHECKED_IN,
                                            statusTexts[
                                              StatusEnum.CHECKED_IN
                                            ] as string
                                          );
                                        }}
                                      >
                                        Checka in
                                      </a>
                                    </>
                                  );
                                }
                              } else {
                                return null; // or handle the case when coPlayer is not found
                              }
                            })()}
                          </div>
                        );
                      })}
                  </div>
                </span>
              </div>
              {bookingToLoop.guests.length > 0 && (
                <div className="general-popup-flex-between align-top">
                  <span className="text-m primary-text">Gäster</span>
                  <span className="text-m primary-text text-svg-wrapper">
                    <div className="chosen-players">
                      {bookingToLoop.guests.map((guest, index) => {
                        console.log(bookingToLoop);
                        const priceForPlayer = bookingToLoop.final_price.find(
                          (event) => event.event.includes("guest")
                        );
                        const priceString = priceForPlayer?.price || "0";
                        const hasPrice = priceString !== "0";

                        let guestToShow = guestsData?.find((g) =>
                          g.member_id !== "guest"
                            ? g.member_id === guest.member_id
                            : false
                        );

                        return (
                          <div key={index} className="chosen-player">
                            {guestToShow
                              ? capitalizeWords(guestToShow.first_name) +
                                " " +
                                capitalizeWords(guestToShow.last_name) +
                                " (" +
                                guestToShow.member_id +
                                ")"
                              : `Gäst ${index + 1}`}
                            {(() => {
                              const guestPlayer = bookingToLoop.guests.find(
                                (g) => g.member_id === guest.member_id
                              );

                              if (
                                guestPlayer &&
                                guestPlayer.member_id !== "guest"
                              ) {
                                if (
                                  guestPlayer.status === "CHECKED_IN" &&
                                  hasPrice
                                ) {
                                  return (
                                    <>
                                      <span className="price-for-member">
                                        {priceString} kr
                                      </span>
                                      <span
                                        className="checked-in pointer"
                                        onClick={() => {
                                          if (loading) return;
                                          handleStatusChange(
                                            guest.member_id,
                                            "guest",
                                            StatusEnum.CONFIRMED,
                                            statusTexts[
                                              StatusEnum.CONFIRMED
                                            ] as string
                                          );
                                        }}
                                      >
                                        Incheckad
                                      </span>
                                      <a
                                        className="pointer"
                                        onClick={() => {
                                          if (loading) return;
                                          handleStatusChange(
                                            guest.member_id,
                                            "guest",
                                            StatusEnum.PAID,
                                            statusTexts[
                                              StatusEnum.PAID
                                            ] as string
                                          );
                                        }}
                                      >
                                        Betald
                                      </a>
                                    </>
                                  );
                                } else if (
                                  guestPlayer.status === "CHECKED_IN"
                                ) {
                                  return (
                                    <>
                                      <span className="price-for-member">
                                        {priceString} kr
                                      </span>
                                      <span
                                        className="checked-in pointer"
                                        onClick={() => {
                                          if (loading) return;
                                          handleStatusChange(
                                            guest.member_id,
                                            "guest",
                                            StatusEnum.CONFIRMED,
                                            statusTexts[
                                              StatusEnum.CONFIRMED
                                            ] as string
                                          );
                                        }}
                                      >
                                        Incheckad
                                      </span>
                                    </>
                                  );
                                } else if (guestPlayer.status === "PAID") {
                                  return (
                                    <>
                                      <span className="price-for-member">
                                        {priceString} kr
                                      </span>
                                      <span
                                        className="checked-in pointer"
                                        onClick={() => {
                                          if (loading) return;
                                          handleStatusChange(
                                            guest.member_id,
                                            "guest",
                                            StatusEnum.CONFIRMED,
                                            statusTexts[
                                              StatusEnum.CONFIRMED
                                            ] as string
                                          );
                                        }}
                                      >
                                        Incheckad
                                      </span>
                                      <span
                                        className="checked-in pointer"
                                        onClick={() => {
                                          if (loading) return;
                                          handleStatusChange(
                                            guest.member_id,
                                            "guest",
                                            StatusEnum.CHECKED_IN,
                                            statusTexts[
                                              StatusEnum.CHECKED_IN
                                            ] as string
                                          );
                                        }}
                                      >
                                        Betald
                                      </span>
                                    </>
                                  );
                                } else if (
                                  guestPlayer.status === "CONFIRMED" &&
                                  !hasPrice
                                ) {
                                  return (
                                    <>
                                      <span className="price-for-member">
                                        {priceString} kr
                                      </span>
                                      <a
                                        className="pointer"
                                        onClick={() => {
                                          if (loading) return;
                                          handleStatusChange(
                                            guest.member_id,
                                            "guest",
                                            StatusEnum.PAID,
                                            statusTexts[
                                              StatusEnum.PAID
                                            ] as string
                                          );
                                        }}
                                      >
                                        Checka in
                                      </a>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <span className="price-for-member">
                                        {priceString} kr
                                      </span>
                                      <a
                                        className="pointer"
                                        onClick={() => {
                                          if (loading) return;
                                          handleStatusChange(
                                            guest.member_id,
                                            "guest",
                                            StatusEnum.CHECKED_IN,
                                            statusTexts[
                                              StatusEnum.CHECKED_IN
                                            ] as string
                                          );
                                        }}
                                      >
                                        Checka in
                                      </a>
                                    </>
                                  );
                                }
                              } else {
                                return (
                                  <span className="price-for-member">
                                    {priceString} kr
                                  </span>
                                );
                              }
                            })()}
                          </div>
                        );
                      })}
                    </div>
                  </span>
                </div>
              )}

              {booking.coach_id.length > 0 && (
                <div className="general-popup-flex-between align-top">
                  <span className="text-m primary-text">Tränare</span>
                  <span className="text-m primary-text">
                    {bookingToLoop.coach_id.map((coach, index) => {
                      const coachString =
                        capitalizeWords(
                          coachData?.first_name + " " + coachData?.last_name
                        ) +
                        " (" +
                        coach +
                        ")";

                      const coachPrizeExists = coachPrize !== 0;
                      const status = bookingToLoop.member_status;
                      return (
                        <div className="checkin-text-wrapper" key={index}>
                          {coachString} {coachPrize} kr
                          {coachPrizeExists &&
                          status === StatusEnum.CHECKED_IN ? (
                            <>
                              <a
                                className="pointer"
                                onClick={() => {
                                  if (loading) return;
                                  handleStatusChange(
                                    coach,
                                    "member",
                                    StatusEnum.PAID,
                                    statusTexts[StatusEnum.PAID] as string
                                  );
                                }}
                              >
                                Betald
                              </a>
                            </>
                          ) : coachPrizeExists && status === StatusEnum.PAID ? (
                            <>
                              <span
                                className="checked-in pointer"
                                onClick={() => {
                                  if (loading) return;
                                  handleStatusChange(
                                    coach,
                                    "member",
                                    StatusEnum.CHECKED_IN,
                                    statusTexts[StatusEnum.CHECKED_IN] as string
                                  );
                                }}
                              >
                                Betald
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })}
                  </span>
                </div>
              )}
              <div className="general-popup-flex-between align-top">
                {/* <span className="text-m primary-text">Pris</span>
                <span className="text-m primary-text">
                  {accumulatePrices(bookingToLoop.final_price)} kr
                </span> */}
              </div>
              {!props.isSemiNoShow && (
                <div className="general-popup-flex-right text-m">
                  <span></span>
                  <a onClick={() => setShowEditCoPlayersPopup(true)}>
                    Redigera medspelare
                  </a>
                </div>
              )}

              <div className="general-popup-flex-right">
                {booking.status !== "NO_SHOW" ? (
                  <a
                    className="danger text-m pointer"
                    onClick={() => setShowMarkAsNoShowPopup(true)}
                  >
                    No show
                  </a>
                ) : (
                  <div className="text-danger text-m ">
                    Bokningen är angiven som "No show"
                  </div>
                )}
              </div>
              {!props.isSemiNoShow && (
                <div className="general-popup-flex-right">
                  <a
                    className="danger text-m pointer"
                    onClick={() => setShowDeleteBookingPopup(true)}
                  >
                    Avboka
                  </a>
                </div>
              )}
            </div>
          </div>

          <div className="general-popup-form-group mb-20">
            <div className="general-popup-form-field">
              <span className="text-m">Kommentar</span>
              <span className="text-m">{bookingToLoop.comment}</span>
              {!editCommentToggle && (
                <div className="general-popup-flex-right text-m">
                  <button
                    type="button"
                    onClick={() => setEditCommentToggle(true)}
                  >
                    {bookingToLoop.comment !== "" ? (
                      <span> Uppdatera kommentar</span>
                    ) : (
                      <span>Lägg till kommentar</span>
                    )}
                  </button>
                </div>
              )}
              {editCommentToggle && (
                <>
                  <input
                    className="text-m"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></input>
                  <div className="general-popup-flex-right text-m">
                    <button
                      type="button"
                      onClick={() => handleCommentBooking()}
                    >
                      Spara kommentar
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>

          {bookingToLoop.guests.length > 0 &&
            !props.isSemiNoShow &&
            bookingToLoop.guests.some(
              (guest) => guest.member_id === "guest"
            ) && (
              <div className="general-popup-form-group mb-20">
                <div className="general-popup-form-field">
                  <span className="text-m"> Lägg till gästspelare</span>
                  <div className="admin-handle-guest-selection-type text-m">
                    <button
                      className={`toggle-button ${
                        guestSelection === "existing" ? "active" : ""
                      }`}
                      onClick={(e) => handleToggleGuestSelection(e, "existing")}
                    >
                      Befintlig
                    </button>
                    <button
                      type="button"
                      className={`toggle-button ${
                        guestSelection === "new" ? "active" : ""
                      }`}
                      onClick={(e) => handleToggleGuestSelection(e, "new")}
                    >
                      Ny gäst
                    </button>
                  </div>
                  {guestSelection === "existing" ? (
                    <>
                      <div className="existing-guests-wrapper">
                        <div className="search-input-btn-wrapper">
                          <div className="search-input-wrapper">
                            <input
                              className="users-search-input text-m"
                              value={searchString}
                              onChange={(e) => setSearchString(e.target.value)}
                              placeholder="Sök på namn & medlemsnummer"
                              onKeyDown={handleKeyDown}
                            />
                            <i className="fa-regular fa-magnifying-glass search-icon"></i>
                          </div>
                        </div>
                      </div>
                      <div className="searched-guests-wrapper text-m">
                        {searchedGuests.length > 0 &&
                          searchedGuests.map((guest, index) => {
                            if (index > 3) return null;

                            const hasMaxPlayTimes = guest.times_played >= 10;
                            return (
                              <div
                                key={guest.member_id}
                                className="player-wrapper pointer"
                                onClick={() =>
                                  handleStatusChange(
                                    guest.member_id,
                                    "guest",
                                    StatusEnum.CHECKED_IN,
                                    statusTexts[StatusEnum.CHECKED_IN] as string
                                  )
                                }
                              >
                                <div
                                  className={`player-name ${
                                    hasMaxPlayTimes ? "frozen" : ""
                                  }`}
                                >
                                  <span>
                                    {capitalizeWords(guest.first_name)}{" "}
                                    {capitalizeWords(guest.last_name)}
                                  </span>
                                </div>
                                <div className="player-id">
                                  {guest.member_id}
                                </div>
                                <a className="choose-player">Välj gäst</a>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  ) : (
                    <div className="add-guest-wrapper">
                      <div className="add-guest-inputs-wrapper">
                        <input
                          className="text-m"
                          placeholder="Förnamn*"
                          type="text"
                          minLength={2}
                          required
                          pattern={firstNameRegex}
                          onKeyDown={handleAddGuestKeyDown}
                          value={addGuestData.first_name}
                          onChange={(e) =>
                            handleGuestInputChange(e, "first_name")
                          }
                        />
                        <input
                          className="text-m"
                          placeholder="Efternamn*"
                          type="text"
                          minLength={2}
                          pattern={lastNameRegex}
                          required
                          onKeyDown={handleAddGuestKeyDown}
                          value={addGuestData.last_name}
                          onChange={(e) =>
                            handleGuestInputChange(e, "last_name")
                          }
                        />
                        <input
                          className="text-m"
                          placeholder="yymmdd-xxxx*"
                          pattern={socialSecurityRegex}
                          type="text"
                          maxLength={11}
                          onKeyDown={handleAddGuestKeyDown}
                          required
                          value={addGuestData.social_security_number}
                          onChange={(e) =>
                            handleGuestInputChange(e, "social_security_number")
                          }
                        />
                        <input
                          className="text-m"
                          type="phone"
                          placeholder="+46 70-123 4567"
                          pattern={phoneRegex}
                          onKeyDown={handleAddGuestKeyDown}
                          value={addGuestData.phone_number}
                          onChange={(e) =>
                            handleGuestInputChange(e, "phone_number")
                          }
                        />
                        <input
                          className="text-m"
                          placeholder="E-post"
                          type="text"
                          pattern={emailRegex}
                          onKeyDown={handleAddGuestKeyDown}
                          value={addGuestData.email}
                          onChange={(e) => handleGuestInputChange(e, "email")}
                        />
                      </div>
                      <div className="add-guest-button-wrapper">
                        <button className="text-m" type="submit">
                          Lägg till gäst
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
        </form>
      </div>
      <HandleConfirmationPopup
        showPopup={showDeleteBookingPopup}
        onClose={() => {
          setCancelDueToRain(false);
          setShowDeleteBookingPopup(false);
        }}
        onConfirm={() => handleCancelBooking(booking)}
        questionText="Är du säker på att du vill avboka bokningen?"
        confirmText="Avboka"
        children={
          courtType === "OUTDOOR" && (
            <div className="child-flexer">
              <span className="text-m"> Avboka pga regn </span>
              <label className="toggle text-m">
                <input
                  className="text-m"
                  type="checkbox"
                  name="title"
                  checked={cancelDueToRain}
                  onChange={(e) => setCancelDueToRain(e.target.checked)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          )
        }
      />

      <HandleConfirmationPopup
        showPopup={showMarkAsNoShowPopup}
        onClose={() => setShowMarkAsNoShowPopup(false)}
        onConfirm={handleMarkAsNoShow}
        questionText="Är du säker på att du vill markera bokningen som No show?"
        confirmText="Markera som 'No show'"
        denyText="Avbryt"
        loading={loading}
      />
      {mainBookerData && (
        <EditCoPlayersPopup
          showPopup={showEditCoPlayersPopup}
          onClose={() => setShowEditCoPlayersPopup(false)}
          booking={bookingToLoop}
          onConfirm={() => {
            setShowEditCoPlayersPopup(false);
            props.onConfirm();
            handleClose();
          }}
          cognitoUser={null}
          user={mainBookerData}
          coachesList={slot.coach_available}
          latestCoPlayers={[]}
          coaches={props.coaches}
          courts={props.courts}
          prices={props.prices}
          permissions={props.permissions}
        />
      )}
    </Popup>
  );
}

export default AdminHandleBookingPopup;
