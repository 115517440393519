import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ROUTE, ROUTE_LABEL } from "../../shared/enums";
import "./Navbar.scss";
import useAuth from "../../shared/hooks/useAuth";
import MyProfilePopup from "../MyProfile/MyProfilePopup/MyProfilePopup";
import { useEffect, useState } from "react";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import KLTKVolleyLogo from "../../assets/images/KLTK-volley.svg";
import { getDailyComment } from "../../api/dailyComment/dailyComment";
import { formatDate } from "../../shared/dateUtils";
import HandleDailyCommentPopup from "../DailyComment/HandleDailyCommentPopup";
import { IDailyComment } from "../../types/api";
interface INavbarProps {
  isAdmin?: boolean;
  isCoach?: boolean;
}

function Navbar(props: INavbarProps) {
  const navigate = useNavigate();
  const { isAdmin, isCoach } = props;
  const [showMyPrifilePopup, setShowMyProfilePopup] = useState(false);
  const { userInfo, isLoading, signOut } = useAuth();
  const [dailyComment, setDailyComment] = useState<IDailyComment>();
  const RouteIsPreview = useLocation().pathname.includes("visual-art-preview");

  const [showHandleDailyCommentPopup, setShowHandleDailyCommentPopup] =
    useState<boolean>(false);

  let navItems = [];
  const navItemsAdmin = [
    {
      label: ROUTE_LABEL.Bookings,
      path: ROUTE.Bookings,
    },
    {
      label: ROUTE_LABEL.Activities,
      path: ROUTE.Activities,
    },
    {
      label: ROUTE_LABEL.Users,
      path: ROUTE.Users,
    },
    {
      label: ROUTE_LABEL.Coach,
      path: ROUTE.Coach,
    },
    {
      label: ROUTE_LABEL.Memberships,
      path: ROUTE.Memberships,
    },
    {
      label: ROUTE_LABEL.OpeningTimes,
      path: ROUTE.OpeningTimes,
    },
    {
      label: ROUTE_LABEL.Settings,
      path: ROUTE.Settings,
    },
  ];

  const navItemsUser = [
    {
      label: ROUTE_LABEL.Bookings,
      path: ROUTE.Bookings,
    },
    {
      label: ROUTE_LABEL.Users,
      path: ROUTE.Users,
    },
    {
      label: ROUTE_LABEL.MyProfile,
      path: ROUTE.MyProfile,
    },
  ];

  const navItemsCoach = [
    {
      label: ROUTE_LABEL.Coach,
      path: ROUTE.Coach,
    },
  ];
  navItems = isAdmin ? navItemsAdmin : isCoach ? navItemsCoach : navItemsUser;
  const location = useLocation();

  const [openingDropdownValue, setOpeningDropdownValue] = useState(
    location.pathname
  );
  const [settingsDropdownValue, setSettingsDropdownValue] = useState(
    location.pathname
  );

  useEffect(() => {
    if (
      location.pathname === ROUTE.Facility ||
      location.pathname === ROUTE.Tracks
    ) {
      setOpeningDropdownValue(location.pathname);
    }
    if (
      location.pathname === ROUTE.Admin ||
      location.pathname === ROUTE.Integrations ||
      location.pathname === ROUTE.Statistic
    ) {
      setSettingsDropdownValue(location.pathname);
    }
  }, [location.pathname]);

  const handleNavLinkClick = (path: any) => {
    if (path === "Kommentar") {
      setOpeningDropdownValue("");
      setShowHandleDailyCommentPopup(true);
      return;
    }
    if (path === ROUTE.Facility || path === ROUTE.Tracks) {
      setOpeningDropdownValue(path);
      navigate(path);
    } else {
      setOpeningDropdownValue("");
    }
    if (
      path === ROUTE.Admin ||
      path === ROUTE.Integrations ||
      path === ROUTE.Statistic
    ) {
      setSettingsDropdownValue(path);
      navigate(path);
    } else {
      setSettingsDropdownValue("");
    }
  };

  const fetchDailyComment = async () => {
    let swedishDate = formatDate(new Date(), "yyyy-MM-dd");
    const response = await getDailyComment(swedishDate);
    setDailyComment(response);
  };
  useEffect(() => {
    fetchDailyComment();
  }, []);
  if (RouteIsPreview) {
    return null;
  }
  return (
    <>
      {dailyComment !== undefined &&
        dailyComment.comment !== "" &&
        dailyComment.comment !== "No comment" && (
          <div className="daily-comment-banner text-m">
            {dailyComment.comment}
          </div>
        )}
      <nav className={`navbar`}>
        <div className="nav-items--wrapper">
          <img
            src={KLTKVolleyLogo}
            alt="KLTK volley logo"
            className="pointe kltk-logo-nav"
          />
          <div className="nav-items">
            {navItems.map((navItem) => {
              if (navItem.label === ROUTE_LABEL.MyProfile) {
                return (
                  <div
                    key={navItem.label}
                    className="nav-item text-m pointer my-profile-hover"
                    onClick={() => setShowMyProfilePopup(true)}
                  >
                    {navItem.label}
                  </div>
                );
              }
              if (navItem.label === ROUTE_LABEL.OpeningTimes) {
                return (
                  <Dropdown
                    key={navItem.label}
                    title={navItem.label}
                    classes="nav-dropdown text-m"
                    short
                    width={100}
                    options={[
                      {
                        value: ROUTE.Facility,
                        label: ROUTE_LABEL.Facility,
                      },
                      {
                        value: ROUTE.Tracks,
                        label: ROUTE_LABEL.Tracks,
                      },
                    ]}
                    onSelect={(value) => {
                      handleNavLinkClick(value);
                    }}
                    value={openingDropdownValue}
                  />
                );
              }
              if (navItem.label === ROUTE_LABEL.Settings) {
                return (
                  <Dropdown
                    key={navItem.label}
                    title={navItem.label}
                    classes={"nav-dropdown text-m"}
                    short
                    width={120}
                    options={[
                      {
                        value: ROUTE.Admin,
                        label: ROUTE_LABEL.Admin,
                      },
                      {
                        value: ROUTE.Integrations,
                        label: ROUTE_LABEL.Integrations,
                      },
                      { value: "Kommentar", label: "Kommentar" },
                      {
                        value: ROUTE.Statistic,
                        label: ROUTE_LABEL.Statistic,
                      },
                    ]}
                    onSelect={(value) => {
                      handleNavLinkClick(value);
                    }}
                    value={settingsDropdownValue}
                  />
                );
              }
              return (
                <NavLink
                  key={navItem.label}
                  to={navItem.path}
                  className="nav-item text-m"
                  onClick={() => handleNavLinkClick("test")}
                >
                  {navItem.label}
                </NavLink>
              );
            })}
            {(isAdmin || isCoach) && (
              <header className="App-header">
                <button className="slim" onClick={signOut}>
                  <i className="fa-regular fa-right-from-bracket"></i>
                </button>
              </header>
            )}
          </div>
        </div>
        {!isCoach && !isAdmin && (
          <MyProfilePopup
            showPopup={showMyPrifilePopup}
            onClose={() => {
              setShowMyProfilePopup(false);
            }}
            user={userInfo}
            onSignOut={signOut}
          />
        )}
        <HandleDailyCommentPopup
          showPopup={showHandleDailyCommentPopup}
          onClose={() => setShowHandleDailyCommentPopup(false)}
          onConfirm={() => window.location.reload()}
          comment={dailyComment?.comment || ""}
        />
      </nav>
    </>
  );
}

export default Navbar;
