import React, { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { ICourt, ISlotHistory, ISummaryItem } from "../../../types/api";
import { getSlotHistory } from "../../../api/slotHistory/slotHistory";
import { formatDate, formatToIsoDate } from "../../../shared/dateUtils";
import { formatDateToSwedish } from "../../../shared/utility";
import "./SlotHistoryPopup.scss";
import { toast } from "react-toastify";

interface ISlotHistoryPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  start_time?: string;
  court_id: string;
  filterDate: Date;
  courts: ICourt[];
}

function SlotHistoryPopup({
  showPopup,
  onClose,
  start_time,
  court_id,
  courts,
  filterDate,
}: ISlotHistoryPopupProps) {
  const [slotHistory, setSlotHistory] = useState<ISlotHistory>();
  const popupDateString = formatDate(filterDate, "yyyy-MM-dd");

  const handleClose = () => {
    setSlotHistory(undefined);
    onClose();
  };
  const fetchSlotHistory = async () => {
    if (!start_time || !court_id) {
      return;
    }
    const dateString = formatDate(filterDate, "yyyy-MM-dd") + "T" + start_time;

    try {
      const response = await getSlotHistory(court_id, dateString);
      setSlotHistory(response);
    } catch (error: any) {
      toast.error(`${error}`);
    }
  };

  useEffect(() => {
    if (showPopup) {
      fetchSlotHistory();
    }
  }, [showPopup]);

  // Function to group events by the 'created_at' timestamp
  const groupEventsByCreatedAt = (historyArray: ISlotHistory["history"]) => {
    return historyArray.reduce((acc: Record<string, any[]>, current) => {
      const dateString = current.created_at.replace(/(:\d{2})$/, "");
      if (!acc[dateString]) {
        acc[dateString] = [];
      }
      acc[dateString].push(current);
      return acc;
    }, {});
  };

  const groupedHistory = slotHistory
    ? groupEventsByCreatedAt(slotHistory.history)
    : {};
  return (
    <Popup
      showPopup={showPopup}
      onClose={handleClose}
      leftTopElement={
        <span className="text-m">
          Historik för tid: {start_time?.slice(0, 5)} på bana{" "}
          {courts.find((court) => court.court_id === court_id)?.name} {" - "}
          {popupDateString}
        </span>
      }
    >
      {filterDate < new Date("2024-10-15") ? (
        <div className="slot-history-wrapper text-m">
          Historik var inte implementerat innan den 15 oktober 2024
        </div>
      ) : (
        <div className="slot-history-wrapper text-m">
          {Object.keys(groupedHistory).length === 0 && (
            <div className="slot-history-item">Inga event har hänt</div>
          )}
          {Object.entries(groupedHistory).map(([createdAt, events], index) => {
            const { weekday, dateAndMonth, hour, seconds } =
              formatDateToSwedish(new Date(createdAt));
            const hourString = hour.split(".").join(":");

            return (
              <div key={index} className="slot-history-item">
                <div className="slot-history-blob"> </div>
                <div>
                  {weekday}, {dateAndMonth} - kl. {hourString}:{seconds}
                </div>
                <div className="event-split-wrapper">
                  {events.map((event, eventIndex) => {
                    const eventLines = event.event.split(".");
                    return (
                      <div key={eventIndex} className="event-split-item">
                        {eventLines.map((line: any, lineIndex: any) => {
                          return (
                            <div key={lineIndex} className="event-line">
                              {line}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                <div>Gjordes av: {events[0].created_by}</div>
              </div>
            );
          })}
        </div>
      )}
    </Popup>
  );
}

export default SlotHistoryPopup;
