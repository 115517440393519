import { useEffect, useState } from "react";
import "./AdminHandleFacility.scss";
import { combinedQuery } from "../../api/combinedQueries/combinedQueries";
import {
  IFacility,
  ISeasonalHoursFacility,
  ISpecialHoursFacility,
} from "../../types/facility";
import {
  formatDateToSwedish,
  getSwedishWeekday,
  getWeekdaySwedishName,
} from "../../shared/utility";
import Spinner from "../../components/UI/Spinner/Spinner";
import { toast } from "react-toastify";
import CreateDefaultHoursPopup from "../../containers/Facility/CreateDefaultHoursPopup/CreateDefaultHoursPopup";
import EditDefaultHoursPopup from "../../containers/Facility/EditDefaultHoursPopup/EditDefaultHoursPopup";
import { IWeekdayHoursFacility } from "../../types/facility";
import CreateSeasonalHoursPopup from "../../containers/Facility/CreateSeasonalHoursPopup/CreateSeasonalHoursPopup";
import { ICourtType } from "../../types/api";
import EditSeasonalHoursPopup from "../../containers/Facility/EditSeasonalHoursPopup/EditSeasonalHoursPopup";
import CreateSpecialHoursPopup from "../../containers/Facility/CreateSpecialHoursPopup/CreateSpecialHoursPopup";
import EditSpecialHoursPopup from "../../containers/Facility/EditSpecialHoursPopup/EditSpecialHoursPopup";

interface IAdminHandleFacilityProps {}

function AdminHandleFacility(props: IAdminHandleFacilityProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const [currentCourtType, setCurrentCourtType] =
    useState<ICourtType>("INDOOR");

  const [facility, setFacility] = useState<IFacility>();

  const [currentSelectedDefaultHour, setCurrentSelectedDefaultHour] =
    useState<IWeekdayHoursFacility>();
  const [currentSelectedSeasonalHour, setCurrentSelectedSeasonalHour] =
    useState<ISeasonalHoursFacility>();
  const [currentSelectedSpecialHour, setCurrentSelectedSpecialHour] =
    useState<ISpecialHoursFacility>();

  const [showCreateDefaultHoursPopup, setShowCreateDefaultHoursPopup] =
    useState(false);
  const [showEditDefaultHoursPopup, setShowEditDefaultHoursPopup] =
    useState(false);
  const [showCreateSeasonalHoursPopup, setShowCreateSeasonalHoursPopup] =
    useState(false);
  const [showEditSeasonalHoursPopup, setShowEditSeasonalHoursPopup] =
    useState(false);
  const [showCreateSpecialHoursPopup, setShowCreateSpecialHoursPopup] =
    useState(false);
  const [showUpdateSpecialHoursPopup, setShowUpdateSpecialHoursPopup] =
    useState(false);

  const fetchChosenItems = async ({ fetchFacility = false }) => {
    setLoading(true);
    try {
      const response = await combinedQuery({
        requestor: "admin",
        court_type: currentCourtType,
        fetchFacility: fetchFacility,
      });
      if (
        fetchFacility &&
        response.facility &&
        !("message" in response.facility)
      ) {
        setFacility(response.facility);
      }
      setLoading(false);
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };
  const handleToggleCourtType = (type: "INDOOR" | "OUTDOOR" | "REHAB") => {
    setCurrentCourtType(type);
  };
  useEffect(() => {
    fetchChosenItems({
      fetchFacility: true,
    });
  }, []);

  useEffect(() => {
    if (currentSelectedDefaultHour) {
      setShowEditDefaultHoursPopup(true);
    }
    if (currentSelectedSeasonalHour) {
      setShowEditSeasonalHoursPopup(true);
    }
    if (currentSelectedSpecialHour) {
      setShowUpdateSpecialHoursPopup(true);
    }
  }, [
    currentSelectedDefaultHour,
    currentSelectedSeasonalHour,
    currentSelectedSpecialHour,
  ]);

  useEffect(() => {
    fetchChosenItems({ fetchFacility: true });
  }, [currentCourtType]);
  if (!facility) return <Spinner />;
  return (
    <div className="admin-handle-facility-layout">
      <div className="handle-faclity-declerations-wrapper">
        <div className="handle-facility-oversight-info-wrapper">
          <h1 className="text-l primary-text">
            Banornas öppettider
          </h1>
        </div>  
      </div>

      <div className="handle-facility-actions-wrapper">
        <div className="admin-handle-facility-toggle-wrapper text-m">
          <button
            className={`toggle-button ${
              currentCourtType === "INDOOR" ? "active" : ""
            }`}
            onClick={() => handleToggleCourtType("INDOOR")}
          >
            Inne
          </button>
          <button
            className={`toggle-button ${
              currentCourtType === "OUTDOOR" ? "active" : ""
            }`}
            onClick={() => handleToggleCourtType("OUTDOOR")}
          >
            Ute
          </button>
          <button
            className={`toggle-button ${
              currentCourtType === "REHAB" ? "active" : ""
            }`}
            onClick={() => handleToggleCourtType("REHAB")}
          >
            Rehab
          </button>
        </div>
        <div className="handle-facility-buttons">
          <button
            className="text-m"
            onClick={() => setShowCreateDefaultHoursPopup(true)}
          >
            Skapa grundinställning
          </button>
          <button
            className="text-m"
            onClick={() => setShowCreateSeasonalHoursPopup(true)}
          >
            Skapa avvikande inställning
          </button>
          <button
            className="text-m"
            onClick={() => setShowCreateSpecialHoursPopup(true)}
          >
            Skapa dagsaktuell inställning
          </button>
        </div>
      </div>
      <h1 className="text-l primary-text">Ordinarie öppettider</h1>
      <div className="facility-times-wrapper text-m">
        {facility.default_hours.map((item, index) => {
          const timesString =
            item.opening_hours.slice(0, 5) +
            " - " +
            item.closing_hours.slice(0, 5);
          const day = getWeekdaySwedishName(item.weekday);
          const defaultHourData: IWeekdayHoursFacility = {
            weekday: item.weekday,
            opening_hours: item.opening_hours,
            closing_hours: item.closing_hours,
            court_type: currentCourtType,
          };
          return (
            <div
              key={index}
              className="facility-time-item pointer"
              onClick={() => setCurrentSelectedDefaultHour(defaultHourData)}
            >
              <div className="facility-time-day">{day}</div>
              <div className="facility-time-times">
                {timesString} <i className="fa-regular fa-pen-to-square"></i>
              </div>
            </div>
          );
        })}
      </div>
      <h2 className="text-l primary-text">Avvikande ordinarie öppettider</h2>
      <div className="facility-seasonal-batches-wrapper text-m">
        {facility.seasonal_hours.map((seasonInterval, index) => {
          if (seasonInterval.closed) {
            return (
              <div
                key={index}
                className="facility-seasonal-times-wrapper text-m"
              >
                <div
                  className="seasonal-times-header-icon-wrapper pointer"
                  onClick={() => setCurrentSelectedSeasonalHour(seasonInterval)}
                >
                  <h3 className="text-l secondary-text">
                    {seasonInterval.alias} ({seasonInterval.start_date} -{" "}
                    {seasonInterval.end_date})
                  </h3>
                  <i className="fa-regular fa-pen-to-square"></i>
                </div>
                <div className="facility-time-item">
                  <div className="facility-time-day">Stängt</div>
                </div>
              </div>
            );
          }
          return (
            <div key={index} className="facility-seasonal-times-wrapper text-m">
              <div
                className="seasonal-times-header-icon-wrapper pointer"
                onClick={() => setCurrentSelectedSeasonalHour(seasonInterval)}
              >
                <h3 className="text-l secondary-text">
                  {seasonInterval.alias} ({seasonInterval.start_date} -{" "}
                  {seasonInterval.end_date})
                </h3>
                <i className="fa-regular fa-pen-to-square"></i>
              </div>
              {seasonInterval.weekday_hours.map((item, index) => {
                const timesString =
                  item.opening_hours.slice(0, 5) +
                  " - " +
                  item.closing_hours.slice(0, 5);
                const day = getWeekdaySwedishName(item.weekday);
                return (
                  <div className="facility-time-item">
                    <div className="facility-time-day">{day}</div>
                    <div className="facility-time-times">{timesString}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="facility-special-days-wrapper text-m">
        <h2 className="text-l primary-text">Dagsaktuella öppettider</h2>
        {facility.special_days.map((day, index) => {
          if (day.closed)
            return (
              <div
                key={index}
                className="facility-special-days-times-wrapper text-m"
              >
                <div
                  className="facility-time-item  pointer"
                  onClick={() => setCurrentSelectedSpecialHour(day)}
                >
                  <div className="facility-time-day">
                    <span>{day.alias} </span>
                    <span>{day.date}</span>
                  </div>
                  <div className="facility-time-times">
                    Stängt <i className="fa-regular fa-pen-to-square"></i>
                  </div>
                </div>
              </div>
            );
          return (
            <div
              key={index}
              className="facility-special-days-times-wrapper text-m"
            >
              <div
                className="facility-time-item pointer"
                onClick={() => setCurrentSelectedSpecialHour(day)}
              >
                <div className="facility-time-day">
                  <span>{day.alias} </span>
                  <span>{day.date}</span>
                </div>
                <div className="facility-time-times">
                  <span>
                    {day.opening_hours.slice(0, 5)} -{" "}
                    {day.closing_hours.slice(0, 5)}
                  </span>
                  <i className="fa-regular fa-pen-to-square"></i>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <CreateDefaultHoursPopup
        showPopup={showCreateDefaultHoursPopup}
        onClose={() => {
          setShowCreateDefaultHoursPopup(false);
          setCurrentSelectedDefaultHour(undefined);
        }}
        onConfirm={() => {
          fetchChosenItems({ fetchFacility: true });
        }}
        courtType={currentCourtType}
      />
      <EditDefaultHoursPopup
        showPopup={showEditDefaultHoursPopup}
        onClose={() => {
          setShowEditDefaultHoursPopup(false);
          setCurrentSelectedDefaultHour(undefined);
        }}
        onConfirm={() => {
          fetchChosenItems({ fetchFacility: true });
        }}
        currentDefaultHours={currentSelectedDefaultHour}
      />
      <CreateSeasonalHoursPopup
        showPopup={showCreateSeasonalHoursPopup}
        onClose={() => {
          setShowCreateSeasonalHoursPopup(false);
          setCurrentSelectedSeasonalHour(undefined);
        }}
        onConfirm={() => {
          fetchChosenItems({ fetchFacility: true });
        }}
        courtType={currentCourtType}
      />
      <EditSeasonalHoursPopup
        showPopup={showEditSeasonalHoursPopup}
        onClose={() => {
          setShowEditSeasonalHoursPopup(false);
          setCurrentSelectedSeasonalHour(undefined);
        }}
        onConfirm={() => {
          fetchChosenItems({ fetchFacility: true });
        }}
        currentSeasonalHours={currentSelectedSeasonalHour}
        courtType={currentCourtType}
      />
      <CreateSpecialHoursPopup
        showPopup={showCreateSpecialHoursPopup}
        onClose={() => {
          setShowCreateSpecialHoursPopup(false);
          setCurrentSelectedSpecialHour(undefined);
        }}
        onConfirm={() => {
          fetchChosenItems({ fetchFacility: true });
        }}
        courtType={currentCourtType}
      />
      <EditSpecialHoursPopup
        showPopup={showUpdateSpecialHoursPopup}
        onClose={() => {
          setShowUpdateSpecialHoursPopup(false);
          setCurrentSelectedSpecialHour(undefined);
        }}
        onConfirm={() => {
          fetchChosenItems({ fetchFacility: true });
        }}
        currentSpecialHours={currentSelectedSpecialHour}
        courtType={currentCourtType}
      />
    </div>
  );
}

export default AdminHandleFacility;
