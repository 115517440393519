import "core-js/stable";
import "react-app-polyfill/stable";
import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/global.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "@aws-amplify/core";
import awsConfig from "./aws/aws-exports";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: process.env.REACT_APP_AWS_AUTHENTICATION_FLOW_TYPE,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  },
});
Amplify.configure(awsConfig);

const root = ReactDOM.createRoot( 
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
