import "./EditSeasonalHoursPopup.scss";
import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { toast } from "react-toastify";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import {
  ISeasonalHoursFacility,
  IWeekdayHoursFacility,
} from "../../../types/facility";
import WeekDaySelector from "../../../hoc/DatePicker/Datepicker";
import {
  deleteSeasonalHours,
  updateSeasonalHours,
} from "../../../api/facility/facilityOpeningTimes";

interface IEditSeasonalHoursPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  currentSeasonalHours?: ISeasonalHoursFacility;
  courtType: "INDOOR" | "OUTDOOR" | "GYM" | "REHAB";
}

function EditSeasonalHoursPopup(props: IEditSeasonalHoursPopupProps) {
  const { showPopup, onClose, onConfirm, currentSeasonalHours, courtType } =
    props;
  const [showHandleConfirmationPopup, setShowHandleConfirmationPopup] =
    useState(false);
  const [
    showHandleDeleteConfirmationPopup,
    setShowHandleDeleteConfirmationPopup,
  ] = useState(false);
  const [showDoubleConfirmationPopup, setShowDoubleConfirmationPopup] = useState(false)

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };
  const handleClose = () => {
    setSeasonalHourData(initialSeasonalData);

    setWeekdayHours(
      Object.entries(weekdays).map(([swedish, english]) => ({
        weekday: english,
        opening_hours: "07:00:00",
        closing_hours: "23:00:00",
        court_type: courtType,
      }))
    );
    onClose();
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [startTime, setStartTime] = useState("07:00:00");
  const [endTime, setEndTime] = useState("23:00:00");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const initialSeasonalData: ISeasonalHoursFacility = {
    alias: "",
    closed: false,
    end_date: "",
    start_date: "",
    court_type: courtType,
    weekday_hours: [],
  };

  const [seasonalHourData, setSeasonalHourData] =
    useState<ISeasonalHoursFacility>(initialSeasonalData);

  const weekdays: Record<
    "Måndag" | "Tisdag" | "Onsdag" | "Torsdag" | "Fredag" | "Lördag" | "Söndag",
    string
  > = {
    Måndag: "monday",
    Tisdag: "tuesday",
    Onsdag: "wednesday",
    Torsdag: "thursday",
    Fredag: "friday",
    Lördag: "saturday",
    Söndag: "sunday",
  };

  const reverseWeekdays: Record<string, string> = {
    monday: "Måndag",
    tuesday: "Tisdag",
    wednesday: "Onsdag",
    thursday: "Torsdag",
    friday: "Fredag",
    saturday: "Lördag",
    sunday: "Söndag",
  };

  const [weekdayHours, setWeekdayHours] = useState<IWeekdayHoursFacility[]>(
    Object.entries(weekdays).map(([swedish, english]) => ({
      weekday: english,
      opening_hours: "07:00:00",
      closing_hours: "23:00:00",
      court_type: courtType,
    }))
  );

  const handleWeekdayTimeChange = (
    index: number,
    timeType: "opening_hours" | "closing_hours",
    e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>
  ) => {
    let timeValue = e.target.value;

    // Handle blur logic
    if (e.type === "blur") {
      let parts = timeValue.split(":");

      if (parts.length === 2) {
        timeValue += ":00";
      } else if (parts.length === 1 && timeValue.includes(":")) {
        timeValue += "00:00";
      } else if (parts.length === 1) {
        timeValue = timeValue.padStart(2, "0") + ":00:00";
      }
    }

    // Update the relevant weekday's time
    const updatedHours = [...weekdayHours];
    updatedHours[index][timeType] = timeValue;
    setWeekdayHours(updatedHours);
  };

  const handleUpdateSeasonalHours = async () => {
    setLoading(true);
    for (const weekday of weekdayHours) {
      weekday.court_type = courtType;
      if (weekday.opening_hours > weekday.closing_hours) {
        toast.error(
          `Öppningstiden kan inte vara senare än stängningstiden  (${
            reverseWeekdays[weekday.weekday]
          })`
        );
        setLoading(false);
        return; // Exit the function
      }
    }
    if (startDate > endDate) {
      toast.error("Startdatum kan inte vara senare än slutdatum");
      setLoading(false);
      return;
    }

    const dataToUpdate = {
      ...seasonalHourData,
      start_date: startDate.toISOString().split("T")[0],
      end_date: endDate.toISOString().split("T")[0],
      weekday_hours: weekdayHours,
      court_type: courtType,
    };

    try {
      const response = await updateSeasonalHours(dataToUpdate);
      if (!("code" in response)) {
        toast.success("Avvikande inställning uppdaterad");
        handleConfirm();
      }
    } catch (error: any) {
      toast.error(`${error.errors?.[0].message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSeasonalHours = async () => {
    setLoading(true);
    try {
      const response = await deleteSeasonalHours(
        seasonalHourData.alias,
        courtType
      );
      if (!("code" in response)) {
        toast.success("Avvikande inställning raderad");
        handleConfirm();
      }
    } catch (error: any) {
      toast.error(`${error.errors?.[0].message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleClosedToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isClosed = e.target.checked;

    setSeasonalHourData((prev) => ({
      ...prev,
      closed: isClosed,
    }));
  };

  useEffect(() => {
    if (showPopup && currentSeasonalHours) {
      setSeasonalHourData(currentSeasonalHours);
      setStartDate(new Date(currentSeasonalHours.start_date));
      setEndDate(new Date(currentSeasonalHours.end_date));
      setWeekdayHours(currentSeasonalHours.weekday_hours);
    }
  }, [showPopup]);
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={handleClose}
      leftTopElement={
        <span className="text-m">
          Redigera avvikande inställning {seasonalHourData.alias}
        </span>
      }
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            setShowHandleConfirmationPopup(true);
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="memberType" className="text-s">
                Start datum
              </label>
              <WeekDaySelector
                textM
                includeWeekdays={false}
                value={startDate}
                onChange={(date) => setStartDate(date)}
              />

              <label htmlFor="memberType" className="text-s">
                Slut datum
              </label>
              <WeekDaySelector
                textM
                includeWeekdays={false}
                value={endDate}
                onChange={(date) => setEndDate(date)}
              />

              <div className="general-popup-flex-between">
                <label htmlFor="courtType" className="text-s">
                  Typ av bana
                </label>
                <select
                  className="text-m"
                  name="court_type"
                  id="courtType"
                  value={seasonalHourData.court_type}
                  disabled
                >
                  <option value="INDOOR">Inomhus</option>
                  <option value="OUTDOOR">Utomhus</option>
                  <option value="REHAB">Rehab</option>
                </select>
              </div>
              <label htmlFor="closed" className="text-m">
                Ska det vara stängt under detta interval ?
              </label>
              <div className="general-popup-flex-between">
                <span className="text-m secondary-text">Stängt</span>
                <label className="toggle text-m">
                  <input
                    type="checkbox"
                    name="closed"
                    checked={seasonalHourData.closed}
                    onChange={(e) => handleClosedToggle(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          {!seasonalHourData.closed &&
            weekdayHours.map((weekdayData, index) => (
              <div className="general-popup-form-group">
                <div className="general-popup-form-field">
                  <div key={weekdayData.weekday} className="weekday-group">
                    <label className="text-s">
                      {reverseWeekdays[weekdayData.weekday]}
                    </label>
                    <div className="general-popup-flex-between">
                      <label className="text-m"> Start </label>
                      <input
                        type="time"
                        value={weekdayData.opening_hours}
                        onChange={(e) =>
                          handleWeekdayTimeChange(index, "opening_hours", e)
                        }
                        onBlur={(e) =>
                          handleWeekdayTimeChange(index, "opening_hours", e)
                        }
                        className="text-m"
                      />
                    </div>
                    <div className="general-popup-flex-between">
                      <label className="text-m">Slut </label>
                      <input
                        type="time"
                        value={weekdayData.closing_hours}
                        onChange={(e) =>
                          handleWeekdayTimeChange(index, "closing_hours", e)
                        }
                        onBlur={(e) =>
                          handleWeekdayTimeChange(index, "closing_hours", e)
                        }
                        className="text-m"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}

          <div className="general-popup-bottom-buttons-wrapper flex-between">
            <button
              type="button"
              className="deleteButton text-m"
              onClick={() => setShowHandleDeleteConfirmationPopup(true)}
            >
              Radera inställning
            </button>
            <button type="submit" className="text-m">
              Uppdatera avvikande inställning
            </button>
          </div>
        </form>
      </div>

      <HandleConfirmationPopup
        showPopup={showHandleDeleteConfirmationPopup}
        onClose={() => setShowHandleDeleteConfirmationPopup(false)}
        onConfirm={() => {
          handleDeleteSeasonalHours();
          setShowHandleDeleteConfirmationPopup(false);
        }}
        confirmText="Ja"
        questionText="Är du säker på att du vill radera denna avvikande inställning?"
        denyText="Avbryt"
      />
      <HandleConfirmationPopup
        showPopup={showHandleConfirmationPopup}
        onClose={() => setShowHandleConfirmationPopup(false)}
        onConfirm={() => {

          setShowHandleConfirmationPopup(false);
          setShowDoubleConfirmationPopup(true);

        }}
        confirmText="Ja"
        questionText="Är du säker på att du vill redigera denna avvikande inställning?"
        denyText="Avbryt"
      />
      <HandleConfirmationPopup
        showPopup={showDoubleConfirmationPopup}
        onClose={() => setShowDoubleConfirmationPopup(false)}
        onConfirm={() => {
          handleUpdateSeasonalHours();
          setShowDoubleConfirmationPopup(false);
        }}
        confirmText="Ja"
        questionText="Om bokningar ligger utanför uppdaterade öppettider kommer dessa inte avbokas. Vill du genomföra uppdateringen ändå?"
        denyText="Avbryt"
      />
    </Popup>
  );
}

export default EditSeasonalHoursPopup;
