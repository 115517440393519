import { StatusEnum } from "../../shared/enums";
import { IActivityInput, IUpdateActivityInSlotInput } from "../../types/api";

export const createActivityQueryFn = (
  activityToCreate: IActivityInput,
  save_as_template: boolean
) => {
  const query = `createActivity(input: $input, save_as_template: $save_as_template) {
        ... on Activity {
        name
        }
        ... on Error {
        code
        message
        }
  }`;
  const inputs = `$input: ActivityInput, $save_as_template: Boolean`;
  const variables = {
    input: activityToCreate,
    save_as_template: save_as_template,
  };

  return {
    query,
    inputs,
    variables,
  };
};

export const listUppcommingActivitiesQueryFn = () => {
  const query = `
    listUpcomingActivities {
        ... on Activity {
        name
        datetime_start
        coach_id
        color
        comment
        created_at
        court_id
        datetime_end
        hidden
        price
        members_with_access
        participants {
          member_id
          status
        }
        participants_limit
        ranking_min
        ranking_max
        }
        ... on Error {
        code
        message
        }
  }
  `;
  return {
    query,
  };
};

export const listActivitiesQueryFn = () => {
  const query = `
        listActivities {
            ... on Activity {
            name
            datetime_start
            coach_id
            color
            comment
            created_at
            court_id
            datetime_end
            hidden
            price
            members_with_access
            participants {
              member_id
              status
            }
            participants_limit
            ranking_min
            ranking_max
            }
            ... on Error {
            code
            message
            }
        }
    `;
  return {
    query,
  };
};

export const cancelActivityQueryFn = (activity: IActivityInput) => {
  const query = `
        cancelActivity(input: $input) {
             ... on Activity {
                name
                datetime_start
            }
            ... on Error {
                code
                message
            }
        }
    `;
  const inputs = `$input: ActivityInput`;
  const variables = { input: activity };
  return {
    query,
    variables,
    inputs,
  };
};

export const bookActivityQueryFn = (
  member_id: string,
  court_id: string,
  datetime_start: string,
  requestor: "admin" | "user"
) => {
  let admin = false;
  if (requestor === "admin") {
    admin = true;
  }

  const query = `
        bookActivity(member_id: $member_id, court_id: $court_id, datetime_start: $datetime_start, admin: $admin) {
            ... on Activity {
                name
                datetime_start
                coach_id
                color
                comment
                created_at
                court_id
                datetime_end
                hidden
                price
                members_with_access
                participants {
                  member_id
                  status
                }
                participants_limit
                ranking_min
                ranking_max
            }
            ... on Error {
                code
                message
            }
        }
    `;
  const inputs = `$member_id: String!, $court_id: String!, $datetime_start: String!, $admin: Boolean!`;
  const variables = {
    member_id: member_id,
    court_id: court_id,
    datetime_start: datetime_start,
    admin: admin,
  };
  return {
    query,
    variables,
    inputs,
  };
};

export const getActiveActivitiesByMemberQueryFn = (
  member_id: string,
  active: boolean
) => {
  const query = `
    getActiveActivitiesByMember(member_id: $member_id, active: $active) {
      ... on Activity {
        name
        datetime_start
        coach_id
        color
        court_id
        comment
        created_at
        datetime_end
        hidden
        price
        members_with_access
        participants_limit
        participants {
          member_id
          status
        }
        ranking_max
        ranking_min
      }
      ... on Error {
        code
        message
      }
    }
  `;
  const inputs = `$member_id: String!, $active: Boolean!`;
  const variables = { member_id: member_id, active: active };
  return {
    query,
    inputs,
    variables,
  };
};

export const unbookActivityQueryFn = (
  court_id: string,
  datetime_start: string,
  member_id: string,
  requestor: "admin" | "user"
) => {
  let admin = false;
  if (requestor === "admin") {
    admin = true;
  }
  const query = `
    unbookActivity(court_id: $court_id, datetime_start: $datetime_start, member_id: $member_id, admin: $admin) {
    ... on Activity {
          name
          datetime_start
          coach_id
          color
          court_id
          comment
          created_at
          datetime_end
          hidden
          price
          members_with_access
          participants_limit
          participants {
            member_id
            status
          }
          ranking_max
          ranking_min
        }
    ... on Error {
      code
      message
    }
  }
  `;
  const inputs = `$court_id: String!, $datetime_start: String!, $member_id: String!, $admin: Boolean!`;
  const variables = { court_id, datetime_start, member_id, admin };
  return {
    query,
    inputs,
    variables,
  };
};

export const adminCheckInActivityBookingQueryFn = (
  member_id: string,
  datetime_start: string,
  court_id: string,
  status: StatusEnum
) => {
  const query = `
    AdminCheckInActivityBooking(member_id: $member_id, datetime_start: $datetime_start, court_id: $court_id, status: $status) {
      ... on ActivityObject {
        PK
        datetime_start
        activity {
          name
          datetime_start
          coach_id
          color
          court_id
          comment
          created_at
          datetime_end
          hidden
          price
          members_with_access
          participants_limit
          participants {
            member_id
            status
          }
          ranking_max
          ranking_min
        }
        status
      }
      ... on Error {
        code
        message
      }
    }
  `;
  const inputs = `$member_id: String!, $datetime_start: String!, $court_id: String!, $status: StatusEnum!`;
  const variables = { member_id, datetime_start, court_id, status };
  return {
    query,
    inputs,
    variables,
  };
};

export const adminReportActivityNoShowQueryFn = (
  member_id: string,
  datetime_start: string,
  court_id: string
) => {
  const query = `
    AdminReportActivityNoshow(member_id: $member_id, datetime_start: $datetime_start, court_id: $court_id) {
      ... on ActivityObject {
        PK
        datetime_start
         activity {
          name
          datetime_start
          coach_id
          color
          court_id
          comment
          created_at
          datetime_end
          hidden
          price
          members_with_access
          participants_limit
          participants {
            member_id
            status
          }
          ranking_max
          ranking_min
        }
        status
      }
      ... on Error {
        code
        message
      }
    }
  `;
  const inputs = `$member_id: String!, $datetime_start: String!, $court_id: String!`;
  const variables = { member_id, datetime_start, court_id };
  return {
    query,
    inputs,
    variables,
  };
};

export const cancelOneActivitySlotQueryFn = (
  input: IActivityInput,
  datetime_to_remove: string
) => {
  const query = `
    cancelOneActivitySlot(input: $input, datetime_to_remove: $datetime_to_remove) {
      ... on Activity {
        name
        datetime_start
      }
      ... on Error {
        code
        message
      }
    }
  `;
  const inputs = `$input: ActivityInput!, $datetime_to_remove: String!`;
  const variables = { input, datetime_to_remove };
  return {
    query,
    inputs,
    variables,
  };
};

export const getActivityByNameAndTimeQueryFn = (
  name: string,
  start_time: string
) => {
  const query = `
    getActivity(name: $name, start_time: $start_time) {
     ... on Activity {
           name
          datetime_start
          coach_id
          color
          court_id
          comment
          created_at
          datetime_end
          hidden
          price
          members_with_access
          participants_limit
          participants {
            member_id
            status
          }
          ranking_max
          ranking_min
    }
    ... on Error {
      code
      message
    }
  }`;
  const inputs = `$name: String!, $start_time: String!`;
  const variables = { name, start_time };
  return {
    query,
    inputs,
    variables,
  };
};

export const removeOneCourtFromActivityQueryFn = (
  input: IActivityInput,
  court_id: string
) => {
  const query = `
    removeOneCourtFromActivity(input: $input, court_to_remove: $court_to_remove) {
      ... on Activity {
        name
      }
      ... on Error {
        code
        message
      }
    }
  `;
  const inputs = `$input: ActivityInput!, $court_to_remove: String!`;
  const variables = { input, court_to_remove: court_id };
  return {
    query,
    inputs,
    variables,
  };
};

export const deleteActivityTemplateQueryFn = (id: string) => {
  const query = `
    deleteActivityTemplate(id: $id) {
      ... on Activity {
        name
      }
      ... on Error {
        code
        message
      }
    }
  `;
  const inputs = `$id: String!`;
  const variables = { id };
  return {
    query,
    inputs,
    variables,
  };
};

export const updateActivityInSlotQueryFn = (
  court_id: string,
  datetime: string,
  input_data: IUpdateActivityInSlotInput
) => {
  const query = `
    updateActivity(court_id: $court_id, datetime: $datetime, input_data: $input_data) {
      ... on Activity {
            name
            court_id
            datetime_start
            datetime_end
            members_with_access
            ranking_min
            ranking_max
            participants_limit
            color
            price
            hidden
            coach_id
            comment
            created_at
            participants {
                member_id
                status
            }
        }
        ... on Error {
            message
            code
        }
    }
  `;
  const inputs = ` $court_id: String! $datetime: String! $input_data: UpdateActivityInput! `;
  const variables = { input_data, court_id, datetime };

  return {
    query,
    inputs,
    variables,
  };
};
