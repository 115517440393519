import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { ICoach } from "../../../types/api";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import { capitalizeWords } from "../../../shared/utility";
import { deleteCoach, updateCoachPrice } from "../../../api/coaches/coaches";
import { toast } from "react-toastify";

interface IEditCoachPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  coach?: ICoach;
}

function EditCoachPopup(props: IEditCoachPopupProps) {
  const { coach, showPopup } = props;

  const [coachData, setCoachData] = useState<ICoach>();
  const [initialCoachData, setInitialCoachData] = useState<ICoach>();
  const [showHandleDeleteCoachPopup, setShowHandleDeleteCoachPopup] =
    useState(false);
  const [showHandleUpdateCoachPopup, setShowHandleUpdateCoachPopup] =
    useState(false);
  const handleClose = () => {
    props.onClose();
  };

  const handleEditCoach = async () => {
    if (coachData === undefined) return;
    try {
      const response = await updateCoachPrice(
        coachData.coach_id,
        coachData.coach_price
      );
      if (response) {
        toast.success(
          "Tränarens pris uppdaterat till " + coachData.coach_price
        );
        props.onConfirm();
        handleClose();
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  const handleDeleteCoach = async () => {
    if (coach === undefined) return;

    try {
      const response = await deleteCoach(coach.coach_id);
      if (response && "message" in response === false) {
        props.onConfirm();
        handleClose();
      }
    } catch (error: any) {
      toast.error(error);
    }
  };
  useEffect(() => {
    if (coach && showPopup) {
      setInitialCoachData(coach);
      setCoachData({ ...coach }); // Initialize `coachData` with the current coach
    }
  }, [showPopup, coach]);
  if (!coach) return null;
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={
        <span className="text-m">
          Redigera tränare {capitalizeWords(coach.first_name)}{" "}
          {capitalizeWords(coach.last_name)} {" (" + coach.coach_id + ")"}
        </span>
      }
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            setShowHandleUpdateCoachPopup(true);
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="memberType" className="text-s">
                Pris
              </label>
              <input
                className="text-m"
                type="number"
                value={coachData?.coach_price || ""}
                onChange={(e) =>
                  setCoachData({
                    ...coachData!,
                    coach_price: parseFloat(e.target.value) || 0,
                  })
                }
              />
            </div>
          </div>
          <div className="general-popup-bottom-buttons-wrapper">
            <button
              className="deleteButton text-m"
              type="button"
              onClick={() => setShowHandleDeleteCoachPopup(true)}
            >
              Radera tränare permanent
            </button>
            <button
              className="text-m"
              type="submit"
              disabled={
                coachData &&
                coachData.coach_price === initialCoachData?.coach_price
              }
            >
              Uppdatera
            </button>
          </div>
        </form>
      </div>

      <HandleConfirmationPopup
        showPopup={showHandleDeleteCoachPopup}
        onClose={() => setShowHandleDeleteCoachPopup(false)}
        onConfirm={() => {
          handleDeleteCoach();
          setShowHandleDeleteCoachPopup(false);
        }}
        questionText={`Är du säker på att du vill radera ${coach.first_name} (${coach.coach_id})?`}
        confirmText="Radera tränare permanent"
        denyText="Nej"
      />
      <HandleConfirmationPopup
        showPopup={showHandleUpdateCoachPopup}
        onClose={() => setShowHandleUpdateCoachPopup(false)}
        onConfirm={() => {
          handleEditCoach();
          setShowHandleUpdateCoachPopup(false);
        }}
        questionText={`Är du säker på att du vill uppdatera priset på ${coach.first_name} (${coach.coach_id})?`}
        confirmText="Uppdatera tränare"
        denyText="Nej"
      />
    </Popup>
  );
}

export default EditCoachPopup;
