import { useEffect, useState } from "react";
import "./AdminHandleIntegrations.scss";
import { listCourts } from "../../api/courts/courts";
import { toast } from "react-toastify";
import { ICourt, IVisualArtBooking } from "../../types/api";
import Spinner from "../../components/UI/Spinner/Spinner";
import {
  listVisualArtBookings,
  updateVisualArtBooking,
} from "../../api/visualArt/visualArt";
import LocalRecomendationsPopup from "../../containers/Recomendations/LocalRecomendationsPopup";
import { ROUTE } from "../../shared/enums";
import screen from "../../assets/images/screen.svg";

interface IAdminHandleIntegrationsProps {}

interface IBookingState {
  active: boolean;
  info: string;
  isDouble: boolean;
  player_one_first_team: string;
  player_one_second_team: string;
  player_two_first_team: string;
  player_two_second_team: string;
}

function AdminHandleIntegrations(props: IAdminHandleIntegrationsProps) {
  const {} = props;
  const [courts, setCourts] = useState<ICourt[]>([]);
  const [visualArtBookings, setVisualArtBookings] = useState<
    IVisualArtBooking[]
  >([]);
  const [bookingStates, setBookingStates] = useState<{
    [key: string]: IBookingState;
  }>({});
  const [originalBookingStates, setOriginalBookingStates] = useState<{
    [key: string]: IBookingState;
  }>({});

  const [recommendations, setRecommendations] = useState<string[]>([]);
  const [showEditRecomedationsPopup, setShowEditRecomedationsPopup] =
    useState<boolean>(false);
  const fetchCourts = async () => {
    try {
      const courtsResponse = await listCourts();
      if (courtsResponse !== null && "message" in courtsResponse) {
        return;
      }
      setCourts(courtsResponse);
    } catch (error: any) {
      toast.error(`${error}`);
    }
  };

  const fetchVisualArtBookings = async () => {
    try {
      const response = await listVisualArtBookings();
      if (response !== null && "message" in response) {
        console.error(response.message);
        return;
      }
      setVisualArtBookings(response);
    } catch (error: any) {
      toast.error(`${error}`);
    }
  };

  const loadRecommendations = () => {
    const storedRecommendations = localStorage.getItem("adminRecommendations");
    if (storedRecommendations) {
      setRecommendations(JSON.parse(storedRecommendations));
    }
  };

  useEffect(() => {
    fetchCourts();
    fetchVisualArtBookings();
    loadRecommendations();
  }, []);

  const hasChanges = (court_id: string) => {
    const original = originalBookingStates[court_id];
    const current = bookingStates[court_id];
    return (
      original.active !== current.active ||
      original.info !== current.info ||
      original.isDouble !== current.isDouble ||
      original.player_one_first_team !== current.player_one_first_team ||
      original.player_one_second_team !== current.player_one_second_team ||
      original.player_two_first_team !== current.player_two_first_team ||
      original.player_two_second_team !== current.player_two_second_team
    );
  };
  useEffect(() => {
    const initialBookingStates = visualArtBookings.reduce((acc, booking) => {
      const isDouble =
        !!booking.player_two_first_team || !!booking.player_two_second_team;

      return {
        ...acc,
        [booking.court_id]: {
          active: booking.active,
          info: booking.info || "",
          isDouble: isDouble,
          player_one_first_team: booking.player_one_first_team,
          player_one_second_team: booking.player_one_second_team,
          player_two_first_team: booking.player_two_first_team || "",
          player_two_second_team: booking.player_two_second_team || "",
        },
      };
    }, {});

    setBookingStates(initialBookingStates);
    setOriginalBookingStates(initialBookingStates);
  }, [visualArtBookings]);

  const handleInputChange = (
    court_id: string,
    field: string,
    value: string | boolean
  ) => {
    setBookingStates((prevStates) => {
      const updatedBookingState = {
        ...prevStates[court_id],
        [field]: value,
      };
      if (field === "isDouble" && value === false) {
        updatedBookingState.player_two_first_team = "";
        updatedBookingState.player_two_second_team = "";
      }

      return {
        ...prevStates,
        [court_id]: updatedBookingState,
      };
    });
  };

  const handleUpdateVisualArtBooking = async (
    court_id: string,
    onlyUpdate: boolean
  ) => {
    try {
      const bookingState = bookingStates[court_id];
      if (!bookingState) {
        return;
      }
      let updatedActiveStatus = false;
      if (onlyUpdate) {
        updatedActiveStatus = bookingState.active;
      } else {
        updatedActiveStatus = !bookingState.active;
      }

      const repsonse = await updateVisualArtBooking(
        updatedActiveStatus,
        court_id,
        bookingState.info,
        bookingState.player_one_first_team,
        bookingState.player_one_second_team,
        bookingState.player_two_first_team,
        bookingState.player_two_second_team
      );

      if (repsonse !== null && "message" in repsonse) {
        toast.error(repsonse.message);
        return;
      }

      setBookingStates((prevStates) => ({
        ...prevStates,
        [court_id]: {
          ...prevStates[court_id],
          active: updatedActiveStatus,
        },
      }));

      setOriginalBookingStates((prevStates) => ({
        ...prevStates,
        [court_id]: {
          active: updatedActiveStatus,
          info: bookingState.info,
          isDouble: bookingState.isDouble,
          player_one_first_team: bookingState.player_one_first_team,
          player_one_second_team: bookingState.player_one_second_team,
          player_two_first_team: bookingState.player_two_first_team,
          player_two_second_team: bookingState.player_two_second_team,
        },
      }));

      toast.success(
        `Booking ${
          updatedActiveStatus ? "activated" : "deactivated"
        } successfully!`
      );
    } catch (error: any) {
      toast.error(`${error}`);
    }
  };

  if (
    courts.length === 0 ||
    visualArtBookings.length === 0 ||
    Object.keys(bookingStates).length === 0
  ) {
    return <Spinner />;
  }

  return (
    <div className="admin-handle-integrations-layout">
      <h1 className="text-l primary-text">Integrationer</h1>
      <div className="integrations-actions-wrapper">
        <div className="integrations-actions-left">
          <button className={`toggle-button active text-m`}>Namnskyltar</button>{" "}
        </div>

        <div className="integrations-actions-right text-m">
          <div>
            <button onClick={() => setShowEditRecomedationsPopup(true)}>
              Redigera sparade namn
            </button>
          </div>
        </div>
      </div>
      <div className="admin-handle-integrations-overflow-wrapper">
        <table className="visual-art-integrations-table text-m">
          <thead>
            <tr className="text-m secondary-text">
              <th>Digital skärm</th>
              <th>Bana</th>
              <th>Inställning</th>
              <th>
                <span className="text-color-blue">Team 1 </span> - spelare 1
              </th>
              <th>
                <span className="text-color-blue">Team 1 </span> - spelare 2
              </th>
              <th>
                <span className="text-color-red">Team 2 </span> - spelare 1
              </th>
              <th>
                <span className="text-color-red">Team 2 </span> - spelare 2
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {visualArtBookings.map((booking) => {
              const courtName = courts.find(
                (court) => court.court_id === booking.court_id
              )?.name;
              const bookingState = bookingStates[booking.court_id] || {
                active: false,
                isDouble: false,
                player_one_first_team: "",
                player_one_second_team: "",
                player_two_first_team: "",
                player_two_second_team: "",
              };
              const namesAreNotEmpty = bookingState.isDouble
                ? bookingState.player_one_first_team !== "" &&
                  bookingState.player_one_second_team !== "" &&
                  bookingState.player_two_first_team !== "" &&
                  bookingState.player_two_second_team !== ""
                : bookingState.player_one_first_team !== "" &&
                  bookingState.player_one_second_team !== "";

              return (
                <tr
                  key={booking.court_id}
                  className={bookingState.active ? "active-row" : ""}
                >
                  <td>
                    <a
                      href={
                        ROUTE.VisualArtPreview + `?court_id=${booking.court_id}`
                      }
                      target="_blank"
                    >
                      <img src={screen} />
                    </a>
                  </td>
                  <td>{courtName}</td>
                  <td>
                    <div className="setting-toggle-wrapper">
                      <span className="text-m">
                        {bookingState.isDouble ? "Dubbel" : "Singel"}
                      </span>
                      <label className="toggle text-m">
                        <input
                          type="checkbox"
                          name="setting-toggle"
                          checked={bookingState.isDouble}
                          onChange={(e) =>
                            handleInputChange(
                              booking.court_id,
                              "isDouble",
                              e.target.checked
                            )
                          }
                        />

                        <span className="slider round"></span>
                      </label>
                    </div>
                  </td>
                  <td>
                    <input
                      className="player-input"
                      placeholder="Namn"
                      value={bookingState.player_one_first_team}
                      list="recommendationList"
                      onChange={(e) =>
                        handleInputChange(
                          booking.court_id,
                          "player_one_first_team",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={`player-input ${
                        bookingState.isDouble ? "" : "disabled-input"
                      }`}
                      placeholder={bookingState.isDouble ? "Namn" : "Namn"}
                      disabled={!bookingState.isDouble}
                      value={bookingState.player_two_first_team}
                      onChange={(e) =>
                        handleInputChange(
                          booking.court_id,
                          "player_two_first_team",
                          e.target.value
                        )
                      }
                      list="recommendationList"
                    />
                  </td>
                  <td>
                    <input
                      className="player-input"
                      placeholder="Namn"
                      value={bookingState.player_one_second_team}
                      onChange={(e) =>
                        handleInputChange(
                          booking.court_id,
                          "player_one_second_team",
                          e.target.value
                        )
                      }
                      list="recommendationList"
                    />
                  </td>

                  <td>
                    <input
                      className={`player-input ${
                        bookingState.isDouble ? "" : "disabled-input"
                      }`}
                      placeholder={bookingState.isDouble ? "Namn" : "Namn"}
                      disabled={!bookingState.isDouble}
                      value={bookingState.player_two_second_team}
                      onChange={(e) =>
                        handleInputChange(
                          booking.court_id,
                          "player_two_second_team",
                          e.target.value
                        )
                      }
                      list="recommendationList"
                    />
                  </td>
                  <td>
                    <button
                      className={`toggle-button ${
                        bookingState.active ? "active-disable" : "inactive"
                      } text-m`}
                      onClick={() =>
                        handleUpdateVisualArtBooking(booking.court_id, false)
                      }
                      disabled={!namesAreNotEmpty && !bookingState.active}
                    >
                      {bookingState.active ? "Avaktivera" : "Aktivera"}
                    </button>
                  </td>
                  <td>
                    <button
                      className={`toggle-button ${"inactive"} text-m`}
                      onClick={() =>
                        handleUpdateVisualArtBooking(booking.court_id, true)
                      }
                      disabled={!hasChanges(booking.court_id)}
                    >
                      Uppdatera
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <datalist id="recommendationList">
          {recommendations.map((item, index) => (
            <option key={index} value={item} />
          ))}
        </datalist>
      </div>
      <LocalRecomendationsPopup
        showPopup={showEditRecomedationsPopup}
        onClose={() => setShowEditRecomedationsPopup(false)}
        onConfirm={(updatedRecommendations) =>
          setRecommendations(updatedRecommendations)
        }
        localRecommendations={recommendations}
        localStorageKey="adminRecommendations"
      />
    </div>
  );
}

export default AdminHandleIntegrations;
