import { useEffect, useState } from "react";
import {
  ICoach,
  ICoachAvailability,
  ICoachBooking,
  ICoachIntervalItem,
  ICourt,
} from "../../types/api";
import "./AdminHandleCoaches.scss";
import WeekDaySelector from "../../hoc/DatePicker/Datepicker";
import CreateCoachPopup from "../../containers/Coaches/CreateCoachPopup/CreateCoachPopup";
import {
  listCoaches,
  listCoachesAvailability,
} from "../../api/coaches/coaches";
import Spinner from "../../components/UI/Spinner/Spinner";
import { toast } from "react-toastify";
import EditCoachPopup from "../../containers/Coaches/EditCoachPopup/EditCoachPopup";
import CoachShowBookedInfoPopup from "../../containers/Coaches/ CoachShowBookedInfoPopup/ CoachShowBookedInfoPopup";
import { CognitoUser } from "../../types/cognito";
import { Auth } from "aws-amplify";
import { combinedQuery } from "../../api/combinedQueries/combinedQueries";
import { formatToIsoDate } from "../../shared/dateUtils";

function AdminHandleCoaches() {
  const [coaches, setCoaches] = useState<ICoach[]>([]);
  const [courts, setCourts] = useState<ICourt[]>([]);
  const [currentEditCoachId, setCurrentEditCoachId] = useState<string>("");
  const [showEditCoachPopup, setShowEditCoachPopup] = useState<boolean>(false);
  const [coachesAvailability, setCoachesAvailability] = useState<
    ICoachAvailability[]
  >([]);
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showBookedInfoPopup, setShowBookedInfoPopup] =
    useState<boolean>(false);
  const [bookingToShow, setBookingToShow] = useState<ICoachBooking>();
  const [showCreateCoachPopup, setShowCreateCoachPopup] =
    useState<boolean>(false);
  const [filterDate, setFilterDate] = useState(() => {
    const storedDate = localStorage.getItem("filterDate");
    return storedDate ? new Date(storedDate) : new Date();
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        setUser(userData);
      })
      .catch((error: any) => {
        toast.error(error);
      });
  }, []);

  const fetchCoachesAvailability = async (date: Date) => {
    try {
      const coachesAvailabilityResponse = await listCoachesAvailability(
        formatToIsoDate(date)
      );
      if (
        coachesAvailabilityResponse &&
        coachesAvailabilityResponse.length > 0 &&
        "message" in coachesAvailabilityResponse[0] === false
      ) {
        setCoachesAvailability(coachesAvailabilityResponse);
      }
    } catch (error) {
      toast.error("Error fetching coaches availability");
    }
  };

  // const fetchCoaches = async () => {
  //   try {
  //     const coachesResponse = await listCoaches("admin");
  //     if (
  //       coachesResponse &&
  //       coachesResponse.length > 0 &&
  //       "message" in coachesResponse[0] === false
  //     ) {
  //       setCoaches(coachesResponse);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching coaches:", error);
  //   }
  // };

  const fetchChosenItems = async ({
    fetchCourts = false,
    fetchCoaches = false,
  }) => {
    setLoading(true);
    if (!user) return;
    try {
      const response = await combinedQuery({
        active: true,
        requestor: "admin",
        date: formatToIsoDate(filterDate),
        fetchCourts: fetchCourts,
        fetchCoaches: fetchCoaches,
      });

      if (
        fetchCoaches &&
        response.coaches &&
        !("message" in response.coaches)
      ) {
        setCoaches(response.coaches);
      }
      if (fetchCourts && response.courts && !("message" in response.courts)) {
        setCourts(response.courts);
      }

      setLoading(false);
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChosenItems({
      fetchCourts: true,
      fetchCoaches: true,
    });
  }, [user]);

  useEffect(() => {
    localStorage.setItem("filterDate", formatToIsoDate(filterDate));
    fetchCoachesAvailability(filterDate);
  }, [filterDate]);

  const openEditCoach = (coachId: string) => {
    setCurrentEditCoachId(coachId);
    setShowEditCoachPopup(true);
  };

  const openCreateCoach = () => {
    setShowCreateCoachPopup(true);
  };

  const handleShowBookingInfoPopup = (booking: ICoachBooking) => {
    setBookingToShow(booking);
    setShowBookedInfoPopup(true);
  };
  const generateSlotsForInterval = (interval: ICoachIntervalItem) => {
    const start = interval.start_time;
    const end = interval.end_time;
    const slots = [];
    slots.push(`${start.slice(0, 5)} - ${end.slice(0, 5)}`);

    const booker = interval.booking?.member_name;
    if (interval.booking) {
      return (
        <tr>
          <td
            className="booked pointer"
            onClick={() =>
              handleShowBookingInfoPopup(interval.booking as ICoachBooking)
            }
          >
            {slots.map((slot, index) => (
              <div key={index} className="time-item-wrapper slot-booked ">
                <div className="time-content-wrapper">
                  <div>{slot}</div>
                  <div>{booker}</div>
                </div>
              </div>
            ))}
          </td>
        </tr>
      );
    }

    return (
      <tr>
        <td>
          {slots.map((slot, index) => (
            <div key={index} className="time-item-wrapper">
              <div className="time-content-wrapper">
                <div key={index}>{slot}</div>
              </div>
            </div>
          ))}
        </td>
      </tr>
    );
  };

  if (!coaches && !courts && !coachesAvailability && !loading)
    return <Spinner />;
  return (
    <div className="admin-handle-coaches-layout">
      <h1 className="text-l primary-text">Tränare</h1>
      <div className="handle-coaches-actions-wrapper">
        <div className="coaches-weekday-selector-wrapper">
          <WeekDaySelector
            value={filterDate}
            onChange={setFilterDate}
            includeWeekdays
            textM
          />
          {filterDate.getDay() !== new Date().getDay() && (
            <a onClick={() => setFilterDate(new Date())}>Till idag</a>
          )}
        </div>
        <div className="coaches-add-wrapper">
          <button
            className="coaches-add-button text-m"
            onClick={() => openCreateCoach()}
          >
            Lägg till ny tränare
          </button>
        </div>
      </div>
      <div className="coaches-table-overflow-wrapper">
        <table className="coaches-table">
          <thead>
            <tr>
              {coaches.map((coach, index) => (
                <th
                  key={index}
                  className="text-m secondary-text pointer"
                  onClick={() => openEditCoach(coach.coach_id)}
                >
                  {coach.first_name} {coach.last_name} ({coach.coach_id})
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {coaches.map((coach) => {
                const coachAvailability = coachesAvailability.find(
                  (availability) => availability.coach_id === coach.coach_id
                );
                return (
                  <td key={coach.coach_id}>
                    {coachAvailability
                      ? coachAvailability.intervals.map((interval, index) => (
                          <table
                            className="nested-admin-coach-times-table text-s secondary-text"
                            key={index}
                          >
                            <tbody>{generateSlotsForInterval(interval)}</tbody>
                          </table>
                        ))
                      : null}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>

      <CreateCoachPopup
        showPopup={showCreateCoachPopup}
        onClose={() => setShowCreateCoachPopup(false)}
        onConfirm={() =>
          fetchChosenItems({
            fetchCoaches: true,
          })
        }
      />
      <EditCoachPopup
        showPopup={showEditCoachPopup}
        onClose={() => setShowEditCoachPopup(false)}
        onConfirm={() =>
          fetchChosenItems({
            fetchCoaches: true,
          })
        }
        coach={coaches.find((coach) => coach.coach_id === currentEditCoachId)}
      />
      <CoachShowBookedInfoPopup
        showPopup={showBookedInfoPopup}
        onClose={() => setShowBookedInfoPopup(false)}
        onConfirm={() => {}}
        booking={bookingToShow}
        courts={courts}
      />
    </div>
  );
}

export default AdminHandleCoaches;
