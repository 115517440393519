import {
  createDefaultHoursQueryFn,
  createSeasonalHoursQueryFn,
  createSpecialHoursQueryFn,
  deleteSeasonalHoursQueryFn,
  deleteSpecialHoursQueryFn,
  getFacilityOpeningHoursQueryFn,
  updateDefaultHoursQueryFn,
  updateSeasonalHoursQueryFn,
  updateSpecialHoursQueryFn,
} from "./facilityOpeningTimesQueries";

import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import {
  ISeasonalHoursFacility,
  ISpecialHoursFacility,
  IWeekdayHoursFacility,
} from "../../types/facility";

// export const getFacilityOpeningTimes = async () => {
//   const { query } = getFacilityOpeningTimesQueryFn();

//   const myQuery = `
//         query myQuery {
//         ${query}
//         }
//     `;
//   try {
//     await Auth.currentSession();
//     const getFacilityOpeningTimesResponse = (await API.graphql({
//       query: myQuery,
//       authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
//     })) as GraphQLResult<any>;

//     const response = getFacilityOpeningTimesResponse.data.getFacility;
//     if ("message" in response) {
//       toast.error(response.message);
//     }
//     return response;
//   } catch (error: any) {
//     error.errors ? toast.error(error.errors[0].message) : toast.error(error);
//     throw error;
//   }
// };

export const getFacilityOpeningHours = async (court_type: string) => {
  const { query, inputs, variables } =
    getFacilityOpeningHoursQueryFn(court_type);

  const myQuery = `query MyQuery(${inputs}) {
        ${query}
    }`;
  try {
    await Auth.currentSession();
    const getFacilityOpeningHoursResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response =
      getFacilityOpeningHoursResponse.data.getFacilityOpeningHours;
    if ("message" in response) {
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const createDefaultHours = async (input: IWeekdayHoursFacility) => {
  const { query, inputs, variables } = createDefaultHoursQueryFn(input);
  const myQuery = `mutation MyMutation(${inputs}) {
        ${query}
    }`;
  try {
    await Auth.currentSession();
    const createDefaultHoursResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = createDefaultHoursResponse.data.createDefaultHours;

    if ("code" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const updateDefaultHours = async (input: IWeekdayHoursFacility) => {
  const { query, inputs, variables } = updateDefaultHoursQueryFn(input);
  const myQuery = `mutation MyMutation(${inputs}) {
        ${query}
    }`;
  try {
    await Auth.currentSession();
    const updateDefaultHoursResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = updateDefaultHoursResponse.data.updateDefaultHours;

    if ("code" in response) {
      toast.error(response.message);
    }

    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const createSeasonalHours = async (input: ISeasonalHoursFacility) => {
  const { query, inputs, variables } = createSeasonalHoursQueryFn(input);
  const myQuery = `mutation MyMutation(${inputs}) {
        ${query}
    }`;

  try {
    await Auth.currentSession();
    const createSeasonalHoursResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = createSeasonalHoursResponse.data.createSeasonalHours;

    if ("code" in response) {
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const updateSeasonalHours = async (input: ISeasonalHoursFacility) => {
  const { query, inputs, variables } = updateSeasonalHoursQueryFn(input);
  const myQuery = `mutation MyMutation(${inputs}) {
        ${query}
    }`;
  try {
    await Auth.currentSession();
    const updateSeasonalHoursResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = updateSeasonalHoursResponse.data.updateSeasonalHours;

    if ("code" in response) {
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const createSpecialHours = async (input: ISpecialHoursFacility) => {
  const { query, inputs, variables } = createSpecialHoursQueryFn(input);
  const myQuery = `mutation MyMutation(${inputs}) {
        ${query}
    }`;

  try {
    await Auth.currentSession();
    const createSpecialDayResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = createSpecialDayResponse.data.createSpecialHours;

    if ("code" in response) {
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const updateSpecialHours = async (input: ISpecialHoursFacility) => {
  const { query, inputs, variables } = updateSpecialHoursQueryFn(input);
  const myQuery = `mutation MyMutation(${inputs}) {
        ${query}
    }`;

  try {
    await Auth.currentSession();
    const updateSpecialDayResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = updateSpecialDayResponse.data.updateSpecialHours;

    if ("code" in response) {
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const deleteSeasonalHours = async (
  alias: string,
  court_type: string
) => {
  const { query, inputs, variables } = deleteSeasonalHoursQueryFn(
    alias,
    court_type
  );
  const myQuery = `mutation MyMutation(${inputs}) {
    ${query}
  }`;

  try {
    await Auth.currentSession();
    const deleteSeasonalHoursResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = deleteSeasonalHoursResponse.data.deleteSeasonalHours;

    if ("code" in response) {
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const deleteSpecialHours = async (alias: string, court_type: string) => {
  const { query, inputs, variables } = deleteSpecialHoursQueryFn(
    alias,
    court_type
  );
  const myQuery = `mutation MyMutation(${inputs}) {
    ${query}
  }`;

  try {
    await Auth.currentSession();
    const deleteSpecialHoursResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = deleteSpecialHoursResponse.data.deleteSpecialHours;

    if ("code" in response) {
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};
