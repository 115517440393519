import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Login/Login";
import AdminHandleUsers from "./pages/AdminHandleUsers/AdminHandleUsers";
import ForceChangePassword from "./pages/ForceChangePassword/ForceChangePassword";
import Layout from "./hoc/Layout/Layout";
import useAuth from "./shared/hooks/useAuth";
import { ROUTE } from "./shared/enums";
import NonAuthLayout from "./hoc/NonAuthLayout/NonAuthLayout";
import AdminHandlePermissions from "./pages/AdminHandlePermissions/AdminHandlePermissions";
import AdminHandleCourts from "./pages/AdminHandleCourts/AdminHandleCourts";
import AdminHandleBookings from "./pages/AdminHandleBookings/AdminHandleBookings";
import UserBookings from "./pages/UserBookings/UserBookings";
import UserHandleMembers from "./pages/UserMembers/UserMembers";
import TrainerHandleTimes from "./pages/CoachHandleTimes/CoachHandleTimes";
import AdminHandleCoaches from "./pages/AdminHandleCoaches/AdminHandleCoaches";
import AdminHandleFacility from "./pages/AdminHandleFacility/AdminHandleFacility";
import AdminHandleAdmins from "./pages/AdminHandleAdmins/AdminHandleAdmins";
import PasswordChange from "./pages/PasswordChange/PasswordChange";
import RequestPasswordChangeCode from "./pages/RequestChangePasswordCode/RequestPasswordChangeCode";
import AdminHandleIntegrations from "./pages/AdminHandleIntegrations/AdminHandleIntegrations";
import VisualArtUrl from "./pages/VisualArtUrl/VisualArtUrl";
import AdminSpecificUser from "./pages/AdminSpecificUser/AdminSpecificUser";
import AdminHandleActivities from "./pages/AdminHandleActivities/AdminHandleActivities";
import Spinner from "./components/UI/Spinner/Spinner";
import AdminVisualArtPreview from "./pages/AdminVisualArtPreview/AdminVisualArtPreview";
import AdminStatistic from "./pages/AdminStatistic/AdminStatistic";
import ApiLogin from "./pages/Login/ApiLogin/ApiLogin";
import Maintanance from "./pages/Maintanance/Maintanance";

function App() {
  const {
    isAuthenticated,
    isLoading,
    isAdmin,
    isCoach,
    recheckAuthentication,
  } = useAuth();

  const AuthenticatedRoutes = () => (
    <>
      <Routes>
        {isAdmin && (
          <>
            <Route path="/" element={<Navigate to={"/bokningar"} />} />
            <Route path={ROUTE.Users} element={<AdminHandleUsers />} />
            <Route
              path={`${ROUTE.Users}/:memberId`}
              element={<AdminSpecificUser />}
            />
            <Route
              path={ROUTE.Memberships}
              element={<AdminHandlePermissions />}
            />
            <Route path={ROUTE.Admin} element={<AdminHandleAdmins />} />
            <Route path={ROUTE.Tracks} element={<AdminHandleCourts />} />
            <Route path={ROUTE.Bookings} element={<AdminHandleBookings />} />
            <Route path={ROUTE.Coach} element={<AdminHandleCoaches />} />
            <Route path={ROUTE.Facility} element={<AdminHandleFacility />} />
            <Route
              path={ROUTE.Activities}
              element={<AdminHandleActivities />}
            />
            <Route
              path={ROUTE.Integrations}
              element={<AdminHandleIntegrations />}
            />
            <Route
              path={ROUTE.VisualArtPreview}
              element={<AdminVisualArtPreview />}
            />
            <Route path={ROUTE.Statistic} element={<AdminStatistic />} />

            <Route path="*" element={<Navigate to={"/bokningar"} />} />
          </>
        )}
        {isCoach && (
          <>
            <Route path="/" element={<Navigate to={ROUTE.Coach} />} />
            <Route path="*" element={<TrainerHandleTimes />} />
          </>
        )}

        {!isAdmin && (
          <>
          
            {/* <Route path="/" element={<Navigate to={"/maintanance"} />} />
            <Route path="*" element={<Navigate to={"/maintanance"} />} />
            <Route path={"/maintanance"} element={<Maintanance />} />
            <Route
              path={"/admin-test-bookings-for-user"}
              element={<UserBookings />}
            />
            <Route path={ROUTE.Users} element={<UserHandleMembers />} /> */}

            <Route path="/" element={<Navigate to={"/bokningar"} />} />
            <Route path="*" element={<Navigate to={"/bokningar"} />} />
            <Route path={ROUTE.Bookings} element={<UserBookings />} />
            <Route path={ROUTE.Users} element={<UserHandleMembers />} />
            <Route
              path={ROUTE.PasswordChange}
              element={
                <PasswordChange
                  authenticate={recheckAuthentication}
                  isLoading={isLoading}
                />
              }
            />
          </>
        )}
      </Routes>
    </>
  );

  // Route for unauthenticated users
  const UnauthenticatedRoutes = () => (
    <Routes>
      <Route
        path={ROUTE.Login}
        element={
          <Login authenticate={recheckAuthentication} isLoading={isLoading} />
        }
      />
      {/* <Route path={"/api-login"} element={<ApiLogin />} /> */}
      <Route
        path={ROUTE.ForceChangePassword}
        element={
          <ForceChangePassword
            authenticate={recheckAuthentication}
            isLoading={isLoading}
          />
        }
      />
      <Route
        path={ROUTE.PasswordChange}
        element={
          <PasswordChange
            authenticate={recheckAuthentication}
            isLoading={isLoading}
          />
        }
      />
      <Route path="/reset-password" element={<RequestPasswordChangeCode />} />
      <Route path="*" element={<Navigate to={ROUTE.Login} />} />

      <Route path="/visualArt" element={<VisualArtUrl />} />
    </Routes>
  );
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Router>
      {/* <Routes>
        <Route path="/visualArt" element={<VisualArtUrl />} />
      </Routes> */}
      {isAuthenticated ? (
        <Layout isAdmin={isAdmin} isCoach={isCoach}>
          <div className="App">
            <AuthenticatedRoutes />
          </div>
        </Layout>
      ) : (
        <NonAuthLayout>
          <div className="App">
            <UnauthenticatedRoutes />
          </div>
        </NonAuthLayout>
      )}

      <ToastContainer position="bottom-right" style={{ zIndex: 1000000 }} />
    </Router>
  );
}

export default App;
