import "./EditSpecialHoursPopup.scss";
import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { toast } from "react-toastify";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import { ISpecialHoursFacility } from "../../../types/facility";
import {
  createSpecialHours,
  deleteSpecialHours,
  updateSpecialHours,
} from "../../../api/facility/facilityOpeningTimes";
import WeekDaySelector from "../../../hoc/DatePicker/Datepicker";

interface IEditSpecialHoursPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  courtType: "INDOOR" | "OUTDOOR" | "GYM" | "REHAB";
  currentSpecialHours?: ISpecialHoursFacility;
}

function EditSpecialHoursPopup(props: IEditSpecialHoursPopupProps) {
  const { showPopup, onClose, onConfirm, courtType, currentSpecialHours } =
    props;
  const [showHandleConfirmationPopup, setShowHandleConfirmationPopup] =
    useState(false);
  const [
    showHandleDeleteConfirmationPopup,
    setShowHandleDeleteConfirmationPopup,
  ] = useState(false);
  const [showDoubleConfirmationPopup, setShowDoubleConfirmationPopup] =
    useState(false);

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };
  const handleClose = () => {
    setSpecialHoursData(initialSpecialData);
    setStartTime("07:00:00");
    setEndTime("23:00:00");
    onClose();
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState("07:00:00");
  const [endTime, setEndTime] = useState("23:00:00");
  const initialSpecialData: ISpecialHoursFacility = {
    date: new Date().toISOString(),
    opening_hours: "07:00:00",
    closing_hours: "23:00:00",
    court_type: courtType,
    alias: "",
    closed: false,
  };

  const [specialHoursData, setSpecialHoursData] =
    useState<ISpecialHoursFacility>(initialSpecialData);

  const weekdays = {
    Måndag: "monday",
    Tisdag: "tuesday",
    Onsdag: "wednesday",
    Torsdag: "thursday",
    Fredag: "friday",
    Lördag: "saturday",
    Söndag: "sunday",
  };
  const handleTimeChange = (
    timeType: "start" | "end",
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const timeValue = e.target.value;
    if (timeType === "start") {
      setStartTime(timeValue);
    } else {
      setEndTime(timeValue);
    }
  };

  const handleTimeBlur = (
    timeType: "start" | "end",
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    let time = e.target.value;
    let parts = time.split(":");

    if (parts.length === 2) {
      time += ":00";
    } else if (parts.length === 1 && time.includes(":")) {
      time += "00:00";
    } else if (parts.length === 1) {
      time = time.padStart(2, "0") + ":00:00";
    }

    if (timeType === "start") {
      setStartTime(time);
    } else {
      setEndTime(time);
    }
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSpecialHoursData({
      ...specialHoursData,
      [e.target.name]: e.target.value,
    });
  };
  const handleClosedToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isClosed = e.target.checked;

    setSpecialHoursData((prev) => ({
      ...prev,
      closed: isClosed,
    }));
  };
  const handleUpdateSpecialHours = async () => {
    setLoading(true);
    specialHoursData.opening_hours = startTime;
    specialHoursData.closing_hours = endTime;
    specialHoursData.date = date.toISOString();
    specialHoursData.court_type = courtType;
    if (specialHoursData.closing_hours < specialHoursData.opening_hours) {
      toast.error("Stängningstiden kan inte vara tidigare än öppningstiden");
      setLoading(false);
      return;
    }
    if (specialHoursData.alias === "") {
      toast.error("Namn är obligatoriskt");
      setLoading(false);
      return;
    }
    try {
      const response = await updateSpecialHours(specialHoursData);
      if (!("code" in response)) {
        toast.success("Dagsaktuell inställning uppdaterad");
        handleConfirm();
      }
    } catch (error: any) {
      toast.error(`${error.errors?.[0].message}`);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteSpecialHours = async () => {
    try {
      const response = await deleteSpecialHours(
        specialHoursData.alias,
        courtType
      );
      if (!("code" in response)) {
        toast.success("Dagsaktuell inställning raderad");
        handleConfirm();
      }
    } catch (error: any) {
      toast.error(`${error.errors?.[0].message}`);
    }
  };

  useEffect(() => {
    if (showPopup && currentSpecialHours) {
      setSpecialHoursData(currentSpecialHours);
      setStartTime(currentSpecialHours.opening_hours);
      setEndTime(currentSpecialHours.closing_hours);
      setDate(new Date(currentSpecialHours.date));
    }
  }, [showPopup]);
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={handleClose}
      leftTopElement={
        <span className="text-m">
          Redigera dagsaktuell inställning {currentSpecialHours?.alias}
        </span>
      }
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            setShowHandleConfirmationPopup(true);
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="date" className="text-s">
                Datum
              </label>
              <WeekDaySelector
                textM
                includeWeekdays={false}
                value={date}
                onChange={(date) => setDate(date)}
              />
              <div className="general-popup-flex-between">
                <label htmlFor="courtType" className="text-s">
                  Typ av bana
                </label>
                <select
                  className="text-m"
                  name="court_type"
                  id="courtType"
                  value={specialHoursData.court_type}
                  disabled
                >
                  <option value="INDOOR">Inomhus</option>
                  <option value="OUTDOOR">Utomhus</option>
                  <option value="REHAB">Rehab</option>
                </select>
              </div>
              {!specialHoursData.closed && (
                <>
                  <div className="general-popup-flex-between">
                    <label htmlFor="memberType" className="text-s">
                      Start
                    </label>
                    <input
                      type="time"
                      id="startTime"
                      value={startTime}
                      className="text-m"
                      onBlur={(e) => handleTimeBlur("start", e)}
                      onChange={(e) => handleTimeChange("start", e)}
                    />
                  </div>

                  <div className="general-popup-flex-between">
                    <label htmlFor="memberType" className="text-s">
                      Slut
                    </label>
                    <input
                      type="time"
                      id="endTime"
                      value={endTime}
                      className="text-m"
                      onBlur={(e) => handleTimeBlur("end", e)}
                      onChange={(e) => handleTimeChange("end", e)}
                    />
                  </div>
                </>
              )}
              <label htmlFor="closed" className="text-m">
                Ska det vara stängt under detta interval ?
              </label>
              <div className="general-popup-flex-between">
                <span className="text-m secondary-text">Stängt</span>
                <label className="toggle text-m">
                  <input
                    type="checkbox"
                    name="closed"
                    checked={specialHoursData.closed}
                    onChange={(e) => handleClosedToggle(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="general-popup-bottom-buttons-wrapper flex-between">
            <button
              type="button"
              className="deleteButton text-m"
              onClick={() => setShowHandleDeleteConfirmationPopup(true)}
            >
              Radera inställning
            </button>
            <button type="submit" className="text-m">
              Redigera dagsaktuella inställning
            </button>
          </div>
        </form>
      </div>
      <HandleConfirmationPopup
        showPopup={showHandleDeleteConfirmationPopup}
        onClose={() => setShowHandleDeleteConfirmationPopup(false)}
        onConfirm={() => {
          handleDeleteSpecialHours();
          setShowHandleDeleteConfirmationPopup(false);
        }}
        confirmText="Ja"
        questionText="Är du säker på att du vill radera denna dagsaktuella inställning?"
        denyText="Avbryt"
      />
      <HandleConfirmationPopup
        showPopup={showHandleConfirmationPopup}
        onClose={() => setShowHandleConfirmationPopup(false)}
        onConfirm={() => {
          setShowHandleConfirmationPopup(false);
          setShowDoubleConfirmationPopup(true);
        }}
        confirmText="Ja"
        questionText="Är du säker på att du vill redigera denna dagsaktuella inställning?"
        denyText="Avbryt"
      />
      <HandleConfirmationPopup
        showPopup={showDoubleConfirmationPopup}
        onClose={() => setShowDoubleConfirmationPopup(false)}
        onConfirm={() => {
          handleUpdateSpecialHours();
          setShowDoubleConfirmationPopup(false);
        }}
        confirmText="Ja"
        questionText="Om bokningar ligger utanför uppdaterade öppettider kommer dessa inte avbokas. Vill du genomföra uppdateringen ändå?"
        denyText="Avbryt"
      />
    </Popup>
  );
}

export default EditSpecialHoursPopup;
