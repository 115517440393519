import { useEffect, useRef, useState } from "react";
import "./VisualArtUrl.scss";
import visualArt from "../../assets/images/visualart.png";
import audi from "../../assets/sponsors/audi.png";
import eurocard from "../../assets/sponsors/eurocard.png";
import bnpParibas from "../../assets/sponsors/bnp-paribas.png";
import gwFondkommission from "../../assets/sponsors/gw-fondkommission.png";
import tempest from "../../assets/sponsors/tempest.png";
import { getVisualArtByCourt } from "../../api/visualArt/visualArt";
import { toast } from "react-toastify";
import { IVisualArtBooking } from "../../types/api";
import { formatToIsoDate } from "../../shared/dateUtils";

interface IVisualArtUrlProps {}

interface IVisualArtUrlState {
  isMinuteBefore: boolean;
  isActiveMatch: boolean;
  isSponsorActive: boolean;
}

const getSwedishTime = () => {
  const now = new Date();

  // Convert to Stockholm time by adjusting the UTC offset
  const stockholmTime = new Date(
    now.toLocaleString("en-US", { timeZone: "Europe/Stockholm" })
  );

  const hour = String(stockholmTime.getHours()).padStart(2, "0");
  const minute = String(stockholmTime.getMinutes()).padStart(2, "0");
  const day = String(stockholmTime.getDate()).padStart(2, "0");
  const month = String(stockholmTime.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript

  return {
    hour,
    minute,
    day,
    month,
  };
};

function VisualArtUrl(props: IVisualArtUrlProps) {
  const isMounted = useRef(false);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const logoRotationIntervalId = useRef<NodeJS.Timeout | null>(null);

  const [dateTime, setDateTime] = useState(getSwedishTime());
  const court_id = new URLSearchParams(window.location.search).get("court_id");
  const [visualArtBooking, setVisualArtBooking] = useState<IVisualArtBooking>();
  const logosList = [audi, eurocard, bnpParibas, gwFondkommission, tempest];
  const [urlStatus, setUrlStatus] = useState<IVisualArtUrlState>({
    isActiveMatch: false,
    isMinuteBefore: false,
    isSponsorActive: false,
  });

  const [currentActiveLogo, setCurrentActiveLogo] = useState(
    Math.floor(Math.random() * logosList.length)
  );
  const [countDown, setCountDown] = useState(60);
  const [activeLogoClass, setActiveLogoClass] = useState<
    "activate" | "deactivate"
  >("deactivate");
  const [activeMatchClass, setActiveMatchClass] = useState<
    "activate" | "deactivate"
  >("deactivate");
  const [activeMinuteClass, setActiveMinuteClass] = useState<
    "activate" | "deactivate"
  >("deactivate");

  const fetchVisualArt = async (court_id: string, date: string) => {
    try {
      const response = await getVisualArtByCourt(court_id, date);
      if (!response || "message" in response) {
        toast.error(response.message || "Failed to fetch match data.");
        setUrlStatus({
          isActiveMatch: false,
          isMinuteBefore: false,
          isSponsorActive: true,
        });
        rotateSponsorLogos();
        return;
      }

      setVisualArtBooking(response);
      if (response.active === true) {
        setUrlStatus({
          isActiveMatch: true,
          isMinuteBefore: false,
          isSponsorActive: false,
        });
      } else if (response.countdown === true) {
        setUrlStatus({
          isActiveMatch: false,
          isMinuteBefore: true,
          isSponsorActive: false,
        });
      } else if (
        (response.player_one_first_team !== "" &&
          response.player_one_second_team !== "") ||
        (response.player_one_first_team !== "" && response.info !== "")
      ) {
        setUrlStatus({
          isActiveMatch: true,
          isMinuteBefore: false,
          isSponsorActive: false,
        });
      } else {
        setUrlStatus({
          isActiveMatch: false,
          isMinuteBefore: false,
          isSponsorActive: true,
        });
      }
    } catch (error: any) {
      toast.error(`${error}`);
    }
  };

  const rotateSponsorLogos = () => {
    setActiveLogoClass("deactivate");

    setTimeout(() => {
      let newLogo;
      do {
        newLogo = Math.floor(Math.random() * logosList.length);
      } while (newLogo === currentActiveLogo);

      setCurrentActiveLogo(newLogo);
    }, 500);
  };

  const startInterval = () => {
    intervalId.current = setInterval(() => {
      const date = new Date();
      const swedishTimeString = formatToIsoDate(date);
      if (court_id) {
        fetchVisualArt(court_id, swedishTimeString);
      }
    }, 60000);
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;

      const now = new Date();
      const timeUntilNextMinute =
        (60 - now.getSeconds()) * 1000 - now.getMilliseconds();

      if (timeUntilNextMinute > 0) {
        timeoutId.current = setTimeout(() => {
          const date = new Date();
          const swedishTimeString = formatToIsoDate(date);

          if (court_id) {
            fetchVisualArt(court_id, swedishTimeString);
          }
          startInterval();
        }, timeUntilNextMinute);
      }

      if (court_id) {
        const date = new Date();
        const swedishTimeString = formatToIsoDate(date);

        fetchVisualArt(court_id, swedishTimeString);
      }
    }
    if (process.env.NODE_ENV === "development" && court_id) {
      console.log("Started from dev");
      startInterval();
    }
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }

      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!urlStatus.isMinuteBefore) return;
    const interval = setInterval(() => {
      setCountDown((prev) => {
        if (prev === 1) {
          setUrlStatus((prevStatus) =>
            Object.assign({}, prevStatus, {
              isActiveMatch: true,
              isMinuteBefore: false,
            })
          );
          clearInterval(interval);
          return 60;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [urlStatus.isMinuteBefore]);

  useEffect(() => {
    if (urlStatus.isActiveMatch) {
      setTimeout(() => setActiveMatchClass("activate"), 500);
    } else {
      setActiveMatchClass("deactivate");
    }
    if (urlStatus.isSponsorActive) {
      rotateSponsorLogos();
      setTimeout(() => setActiveLogoClass("activate"), 500);
    } else {
      setActiveLogoClass("deactivate");
    }
    if (urlStatus.isMinuteBefore) {
      setTimeout(() => setActiveMinuteClass("activate"), 500);
    } else {
      setActiveMinuteClass("deactivate");
    }
  }, [urlStatus]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(getSwedishTime());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (urlStatus.isSponsorActive) {
      rotateSponsorLogos();
    }

    return () => {
      if (logoRotationIntervalId.current) {
        clearInterval(logoRotationIntervalId.current);
        logoRotationIntervalId.current = null;
      }
    };
  }, [urlStatus.isSponsorActive]);

  //first letter should be caps, and everything after the first name should be caps
  const formatPlayerNames = (playerName: string) => {
    playerName = playerName.replace(/---/g, "");
    const splitName = playerName.split(" ");

    const formattedName = splitName
      .map((name, index) => {
        if (index === splitName.length - 1) {
          return name.toUpperCase();
        } else if (name.includes("-")) {
          return name
            .split("-")
            .map(
              (part) =>
                part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
            )
            .join("-");
        } else if (name.includes("´")) {
          // Handle names with ´
          const parts = name.split("´");
          return (
            parts[0].charAt(0).toUpperCase() +
            parts[0].slice(1).toLowerCase() +
            "´" +
            parts[1].charAt(0).toUpperCase() +
            parts[1].slice(1).toLowerCase()
          );
        } else {
          // Capitalize first letter of other names
          return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
        }
      })
      .join(" ");

    return formattedName;
  };

  return (
    <div className="visual-art-match-layout">
      <div className={`sponsor-logos-wrapper ${activeLogoClass}`}>
        <img src={logosList[currentActiveLogo]} alt="sponsorLogo" />
      </div>
      <div className={`minute-countdown-wrapper ${activeMinuteClass}`}>
        <span className="countdown-text">{countDown}</span>
      </div>

      <div className={`visual-art-match-wrapper ${activeMatchClass}`}>
        <div className="result-content">
          <div className="result-top-wrapper">
            <div className="current-time">
              {dateTime.hour}.{dateTime.minute}
            </div>
            <img
              className="visual-art-logo"
              src={visualArt}
              alt="visual art logo"
            />
            <div className="current-date">
              {dateTime.day}/{dateTime.month}
            </div>
          </div>

          <div className="result-bottom-wrapper">
            <div className="top-players-wrapper">
              <div className="top-player">
                <div className="player-name">
                  {formatPlayerNames(
                    visualArtBooking?.player_one_first_team || ""
                  )}
                </div>
              </div>
              {visualArtBooking?.player_two_first_team && (
                <div className="top-player">
                  <div className="player-name">
                    {formatPlayerNames(visualArtBooking.player_two_first_team)}
                  </div>
                </div>
              )}
            </div>

            <div className="center-vs-wrapper">v</div>

            <div className="bottom-players-wrapper">
              {visualArtBooking?.player_one_second_team && (
                <div className="bottom-player">
                  <div className="player-name">
                    {formatPlayerNames(
                      visualArtBooking?.player_one_second_team || ""
                    )}
                  </div>
                </div>
              )}
              {visualArtBooking?.player_two_second_team && (
                <div className="bottom-player">
                  <div className="player-name">
                    {formatPlayerNames(
                      visualArtBooking?.player_two_second_team || ""
                    )}
                  </div>
                </div>
              )}
            </div>

            {visualArtBooking?.info && (
              <div className="coach-wrapper">
                <div className="coach-player">
                  <div className="player-name">
                    {formatPlayerNames(visualArtBooking.info)} (T)
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisualArtUrl;
