import { useState, useEffect, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '../../types/cognito';


const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userInfo, setUserInfo] = useState<CognitoUser | null> (null);
  const [isCoach , setIsCoach] = useState(false); 
  
  const checkAuthState = useCallback(async () => {
    setIsLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
      
      setUserInfo(user)
      const userGroups = user.signInUserSession.idToken.payload['cognito:groups'] || [];
      if (userGroups.includes('admin')) {
        setIsAdmin(true); 
      } else if(userGroups.includes('coach')) {
        setIsCoach(true); 
      }
    } catch (error) {
      setIsAuthenticated(false);
      setIsAdmin(false); 
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    checkAuthState();
  }, [checkAuthState]);

  const signOut = useCallback(async () => {
    try {
      await Auth.signOut();
      setIsAuthenticated(false);
      setIsAdmin(false);
      localStorage.clear();
      window.location.reload();
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  }, []);

  const recheckAuthentication = useCallback(() => {
    checkAuthState();
  }, [checkAuthState]);

  return { isAuthenticated, isLoading, isAdmin, isCoach, signOut, recheckAuthentication, userInfo };
};

export default useAuth;
