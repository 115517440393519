export const getLatestCoPlayersQueryFn = (member_id: string) => {
  const query = `
    getLatestCoPlayers(member_id: $member_id) {
      ... on User {
          account_type
          ranking
          member_type
          member_id
          last_name
          free_guest_play_count
          first_name
          frozen
        }
        ... on Error {
          code
          message
        }
    }
  `;
  const inputs = `$member_id: String!`;
  const variables = { member_id: member_id };
  return {
    variables,  
    inputs,
    query,
  };
};
