import { ICreateUserSpec, IEditUser, IUser } from "../../types/api";
import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import {
  createUserQuery,
  updateUserQuery,
  deleteUserQuery,
  listUsersQueryFn,
  getUserByMemberIdQueryFn,
  serachUsersQueryFn,
  exportUserDataQueryFn,
  freezeMembershipQueryFn,
  createUserQueryFn,
  getUserHistoryQueryFn,
} from "./userQueries";

export const createUser = async (
  userToCreate: ICreateUserSpec,
  set_id: boolean
) => {
  const { query, inputs, variables } = createUserQueryFn(userToCreate, set_id);

  const myQuery = `
        mutation createUser(${inputs}) {
            ${query}
        }
    `;
  try {
    const getCreateResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getCreateResponse.data.createUser;
    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
      return false;
    }
    return true;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const editUser = async (
  member_id: string,
  userToEdit: Partial<IEditUser>,
  old_email: string
): Promise<boolean> => {
  try {
    const getEditResponse = (await API.graphql({
      query: updateUserQuery,
      variables: {
        member_id: member_id,
        input_data: userToEdit,
        old_email: old_email,
      },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getEditResponse.data.updateUser;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
      return false;
    }
    return true;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const listUsers = async (
  requestor: "admin" | "user",
  limit: number,
  start_key: string,
  member_type?: string
) => {
  const { query, inputs, variables } = listUsersQueryFn(
    requestor,
    limit,
    start_key,
    member_type
  );
  const myQuery = `query MyQuery(${inputs}) {
    ${query}
  }`;

  try {
    await Auth.currentSession();
    const getUserResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: variables,
    })) as GraphQLResult<any>;
    const response = getUserResponse.data.listUsers;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const deleteUser = async (userId: string) => {
  const deleteUserResponse = (await API.graphql({
    query: deleteUserQuery,
    variables: { member_id: userId },
    authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
  })) as GraphQLResult<any>;

  return deleteUserResponse;
};

export const getUserByMemberId = async (member_id: string): Promise<IUser> => {
  const { query, variables, inputs } = getUserByMemberIdQueryFn(member_id);
  const myQuery = `query MyQuery(${inputs}) {
    ${query}
  }`;

  try {
    await Auth.currentSession();
    const getUserResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getUserResponse.data.getUserByID;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const searchUsers = async (
  searchString: string,
  requestor: "admin" | "user",
  serachable: boolean
) => {
  const { query, variables, inputs } = serachUsersQueryFn(
    searchString,
    requestor,
    serachable
  );
  const myQuery = `query MyQuery(${inputs}) {
    ${query}
  }`;
  try {
    await Auth.currentSession();
    const getUserResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getUserResponse.data.searchUser;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const exportUserData = async (member_types: string[]) => {
  const { query, inputs, variables } = exportUserDataQueryFn(member_types);
  const myQuery = `query MyQuery(${inputs}) {
        ${query}
    }`;

  try {
    await Auth.currentSession();
    const getUserResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getUserResponse.data.exportUserData;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const freezeMembership = async (
  member_id: string,
  startDate: string,
  endDate: string,
  member_type: string
) => {
  const { query, inputs, variables } = freezeMembershipQueryFn(
    member_id,
    startDate,
    endDate,
    member_type
  );
  const myQuery = `mutation MyMutation(${inputs}) {
        ${query}
    }`;

  try {
    const getFreezeResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getFreezeResponse.data.freezeMembership;
    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const getUserHistory = async (member_id: string) => {
  const { query, inputs, variables } = getUserHistoryQueryFn(member_id);
  const myQuery = `query MyQuery(${inputs}) {
    ${query}
  }`;

  try {
    await Auth.currentSession();
    const getUserResponse = (await API.graphql({
      query: myQuery,
      variables: variables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;
    const response = getUserResponse.data.getUserHistory;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
}